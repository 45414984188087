import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { MatchSummary } from "../../network/articles";

// simple selectors
// build up simple selectors for use in complex selectos
const selectArticleListData = (state: RootState) =>
  state.articles?.articleListData;
const selectFeaturedArticle = (state: RootState) => state.featuredArticle?.data;
const selectSelectedGeneMatch = (state: RootState) =>
  state.featuredArticle?.selectedGeneMatch;
const selectSelectedVariantMatch = (state: RootState) =>
  state.featuredArticle?.selectedVariantMatch;

// complex selectors
export const selectSupplementalMatches = createSelector(
  selectFeaturedArticle,
  (article) => {
    if (article) {
      const matches = article.matchSummaries ?? [];

      return matches?.filter((match: MatchSummary) => {
        return match.found_in === "supplemental";
      });
    }
    return [];
  }
);

export const selectGeneCount = createSelector(
  selectFeaturedArticle,
  selectArticleListData,
  selectSelectedGeneMatch,
  (article, articleList, gene) => {
    if (article && articleList) {
      const listDataForFeaturedArticle = articleList.articles.find(
        (a) => a.pmid === article.article.pmid
      );

      if (gene?.id && gene.id !== "all") {
        const count =
          listDataForFeaturedArticle?.gene_summaries?.[gene.id]?.count;
        return count ?? 0;
      } else {
        const count = listDataForFeaturedArticle?.gene_mention_count;
        return count ?? 0;
      }
    }
    return 0;
  }
);

export const selectVariantCount = createSelector(
  selectFeaturedArticle,
  selectArticleListData,
  selectSelectedVariantMatch,
  selectSelectedGeneMatch,
  (article, articleList, variant, gene) => {
    if (article && articleList && variant && gene) {
      const listDataForFeaturedArticle = articleList.articles.find(
        (a) => a.pmid === article.article.pmid
      );

      if (variant.id === "all_variants") {
        const count = listDataForFeaturedArticle?.mutation_mention_count;
        return count ?? 0;
      } else if (variant.id.match(/^all_\w+_variants$/)) {
        const count =
          listDataForFeaturedArticle?.gene_summaries?.[gene.id]?.mutation_count;
        return count ?? 0;
      } else {
        const count = listDataForFeaturedArticle?.mutation_counts?.[variant.id];
        return count ?? 0;
      }
    }
    return 0;
  }
);
