import React from "react";
import { Typography } from "@mui/material";
import { useAppSelector } from "../../store/hooks";
import { isProUser } from "../../libs/utils/user";

export default function ProWarningHeader() {
  const { user } = useAppSelector((state) => state.user);

  if (isProUser(user)) {
    return <></>;
  } else {
    return (
      <React.Fragment>
        <Typography
          align={"center"}
          paragraph={true}
          aria-label={"pro-only-notice"}
        >
          Keyword filters are available only in professional version.
        </Typography>
      </React.Fragment>
    );
  }
}
