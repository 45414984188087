import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export enum Modals {
  login = "login",
  otp = "otp",
  geneSummary = "geneSummary",
  alertWithLogin = "alertWithLogin",
  alertWithoutLogin = "alertWithoutLogin",
  contactUs = "contactUs",
}

export type ErrorData = {
  message: string;
  redirect?: string;
  redirectText?: string;
  limitType?: string;
  error?: string;
};

export interface ModalState {
  currentModal: Modals;
  modalOpen: boolean;
  error: ErrorData | null;
  canCloseModal: boolean;
}

export const initialModalState: ModalState = {
  currentModal: Modals.login,
  modalOpen: false,
  error: null,
  canCloseModal: true,
};

export const modalSlice = createSlice({
  name: "modal",
  initialState: initialModalState,
  reducers: {
    closeModal: (state: ModalState) => {
      state.modalOpen = false;
    },
    openModal: (state: ModalState, action: PayloadAction<boolean>) => {
      state.modalOpen = true;
      state.canCloseModal = action.payload;
    },
    setCurrentModal: (state: ModalState, action: PayloadAction<Modals>) => {
      state.currentModal = action.payload;
    },
    setError: (state: ModalState, action: PayloadAction<ErrorData>) => {
      state.error = action.payload;
    },
  },
});

export const { closeModal, openModal, setCurrentModal, setError } =
  modalSlice.actions;

export default modalSlice.reducer;
