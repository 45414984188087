import React from "react";
import { Category } from "../../store/slices/categoryLayoutSlice";
import { Box, Typography } from "@mui/material";
import KeywordContainer from "./KeywordContainer/KeywordContainer";

export default function ParentCategory(props: { category: Category }) {
  const { category } = props;

  return (
    <Box sx={{ padding: "2rem" }}>
      <Typography variant={"h4"}>{category.displayName}</Typography>
      {category.children.map((child) => (
        <KeywordContainer
          key={`keyword-container-${child.displayName}`}
          keyword={child}
        />
      ))}
    </Box>
  );
}
