import React from "react";
import { Box, Link, Typography } from "@mui/material";
import {
  CondensedContentMatches,
  HighlightTypes,
} from "../../../../../../../network/articles";
import { useAppSelector } from "../../../../../../../store/hooks";
import { isRSID } from "../../../../../../../libs/utils/strings/regex";
import { OpenInNew } from "@mui/icons-material";

const generateDbSNPLink = (snpid: string) => {
  return `https://www.ncbi.nlm.nih.gov/snp/${snpid}`;
};

const displayType = (type: string) => {
  switch (type) {
    case HighlightTypes.Therapy:
      return "Therapy";
    case HighlightTypes.Phenotype:
      return "Phenotype";
    default:
      return type;
  }
};

const TextMatchTooltip = (props: { sentence: CondensedContentMatches }) => {
  const { sentence } = props;
  const { data: featuredArticle } = useAppSelector(
    (state) => state.featuredArticle
  );

  const dbsnpMatches = () => (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      width="fit-content"
    >
      {featuredArticle?.dbsnpCDNALookup[sentence.matched]?.map(
        (match, index) => (
          <Typography
            data-testid={`dbsnp-match-${match}-${index}`}
            key={index}
            fontWeight="fontWeightBold"
            variant="text11"
          >
            {match}
          </Typography>
        )
      )}
    </Box>
  );

  return (
    <Box display="flex" gap="20px" alignItems="flex-start" margin="5px 0 5px 0">
      <Box display="flex" flexDirection="column" gap="5px">
        <Box display="flex" alignItems="center" gap="20px">
          <Box display="flex" alignItems="flex-start" width="80px">
            <Typography textTransform="uppercase" variant="text11">
              {displayType(sentence.type)}
            </Typography>
          </Box>
          <Box display="flex" alignItems="flex-start">
            <Typography fontWeight="fontWeightBold" variant="text11">
              {sentence.entity}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" gap="20px">
          <Box display="flex" alignItems="flex-start" width="80px">
            <Typography textTransform="uppercase" variant="text11">
              Matched
            </Typography>
          </Box>
          <Box display="flex" alignItems="flex-start">
            {isRSID([sentence.matched]) ? (
              <Link
                href={generateDbSNPLink(sentence.matched)}
                target={"_blank"}
                underline="none"
                data-testid={`dbsnp-link-${sentence.matched}`}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  width="fit-content"
                  sx={{ cursor: "pointer" }}
                >
                  <Typography fontWeight="fontWeightBold" variant="text11">
                    {sentence.matched}
                  </Typography>
                  &nbsp;
                  <OpenInNew style={{ fontSize: "11px" }} />
                </Box>
              </Link>
            ) : (
              <Typography
                data-testid={`text-match-${sentence.matched}`}
                fontWeight="fontWeightBold"
                variant="text11"
              >
                {sentence.matched}
              </Typography>
            )}
          </Box>
        </Box>
        {featuredArticle?.dbsnpCDNALookup[sentence.matched] ? (
          <Box display="flex" alignItems="flex-start" gap="20px">
            <Typography textTransform="uppercase" variant="text11">
              CDNA Effects
            </Typography>
            {dbsnpMatches()}
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default TextMatchTooltip;
