import * as React from "react";
import { Chip } from "@mui/material";
import { primaryPalette } from "../../../../libs/styles/theme";
import { UNWEIGHTED_CRITERIA_LABEL } from "../../../article/constants";
import { acmgModifier } from "../../../../libs/utils/acmg";

interface ACMGChipProps {
  category: string;
  bgcolor: string;
  strength: string;
}

const getChipLabel = (text: string, strength: string) => {
  if (text === UNWEIGHTED_CRITERIA_LABEL) return text;
  const splitText = text.split("_");
  const formattedText = splitText.map((t, i) => {
    if (i === 0) {
      return t.toUpperCase();
    }
    let capitalized = t.toLowerCase();
    capitalized = capitalized[0].toUpperCase() + capitalized.slice(1);
    return capitalized;
  });

  const acmgLabel = formattedText.join(" ");
  const strengthModifier =
    strength &&
    acmgModifier[strength.toLowerCase() as keyof typeof acmgModifier];
  return strengthModifier
    ? `${acmgLabel}(${strengthModifier.toUpperCase()})`
    : acmgLabel;
};

const ACMGChip = ({ category, bgcolor, strength }: ACMGChipProps) => {
  const label = getChipLabel(category, strength);

  return (
    <Chip
      data-testid={`acmg-chip-${category}`}
      label={label}
      sx={{
        height: "1.0625rem",
        borderRadius: "0.125rem",
        fontSize: "0.675rem",
        color: primaryPalette.gray.variant_01,
        backgroundColor: primaryPalette.gray.variant_06,
        "& .MuiChip-label": {
          padding: "0 0.375rem",
        },
        bgcolor: bgcolor,
      }}
    />
  );
};

export default ACMGChip;
