import React from "react";
import { MenuItem } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { NavBarDropdownItemProps } from ".";

const NavBarDropdownItem = ({
  label,
  id,
  route,
  isExternalLink = false,
}: NavBarDropdownItemProps) => {
  return (
    <MenuItem
      data-testid={id}
      tabIndex={0}
      component="a"
      href={route}
      target={isExternalLink ? "_blank" : "_parent"}
    >
      {label}{" "}
      {isExternalLink ? (
        <OpenInNewIcon fontSize="inherit" style={{ marginLeft: 4 }} />
      ) : null}
    </MenuItem>
  );
};

export default NavBarDropdownItem;
