"use client";

import * as React from "react";
import { styled } from "@mui/material/styles";

import { Stack, Typography } from "@mui/material";
import { primaryPalette } from "../../../../../libs/styles/theme";
import { DiseaseResponse } from "../../../../../network/disease-relationships";
import { HeadWithPlus } from "../../../../common/icons/HeadWithPlus";

const CustomStack = styled(Stack)({
  flexWrap: "wrap",
  alignItems: "center",
  overflowX: "clip",
  "&:last-child": {
    marginBottom: "0",
  },
  "& > span": {
    backgroundColor: primaryPalette.dataVis.slate,
    padding: "0px 6px",
  },
});

const TypographyChip = styled(Typography)({
  borderRadius: "2px",
  padding: "0px 6px",
});

type GeneGDRProps = {
  geneDiseases: DiseaseResponse[];
};

const GeneGDR = ({ geneDiseases }: GeneGDRProps) => {
  if (geneDiseases.length === 0) {
    return <></>;
  }

  return (
    <Stack direction="row" columnGap={1} sx={{ alignSelf: "start" }}>
      <HeadWithPlus sx={{ fontSize: 12 }} />
      <CustomStack direction="column">
        <Typography
          variant="h5"
          sx={{
            color: primaryPalette.grey.neutral_grey_g9,
            marginBottom: "3px",
            alignSelf: "start",
          }}
        >
          Gene-Disease Relationships
        </Typography>
        <CustomStack direction="row" rowGap={0.5} columnGap={0.5}>
          {geneDiseases.map((gdr) => (
            <TypographyChip
              key={`disease-chip-${gdr.disease}`}
              variant="text11"
              color={primaryPalette.grey.neutral_grey_g8}
            >
              {gdr.disease}
            </TypographyChip>
          ))}
        </CustomStack>
      </CustomStack>
    </Stack>
  );
};

export default GeneGDR;
