import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  BooleanSearch,
  Categories,
  SearchBarTermsType,
  SearchedTerms,
  SignificantTerms,
} from "./searchSlice";
import { BooleanSearchOptions, Suggestion } from "../../network/suggestions";

export type UrlState = {
  urlTermSuggestions: SearchedTerms;
  urlTermBooleans: BooleanSearch;
  urlVariantsWithAltNotationsSuggestions: { variant: Suggestion[] };
  urlCats: string[];
  urlSigTerms: string[];
};

export type URLPayload = {
  terms: SuggestionPayload;
  suggTerms: SuggestionPayload;
  bools: BooleanPayload;
  categories: CategoryPayload;
};

type SuggestionPayload = Record<keyof SearchBarTermsType, Suggestion[]>;

type BooleanPayload = Record<keyof SearchBarTermsType, BooleanSearchOptions>;

type CategoryPayload = {
  cats: Categories;
  sig_terms: SignificantTerms;
};

export const initialUrlState: UrlState = {
  urlTermSuggestions: {
    gene: [],
    disease: [],
    variant: [],
    keyword: [],
    hpo: [],
    unii: [],
    cnv: [],
  },
  urlTermBooleans: {
    gene: BooleanSearchOptions.AND,
    disease: BooleanSearchOptions.OR,
    variant: BooleanSearchOptions.AND,
    keyword: BooleanSearchOptions.AND,
    hpo: BooleanSearchOptions.OR,
    unii: BooleanSearchOptions.OR,
    cnv: BooleanSearchOptions.AND,
  },
  urlCats: [],
  urlSigTerms: [],
  urlVariantsWithAltNotationsSuggestions: {
    variant: [],
  },
};

export const urlSlice = createSlice({
  name: "url",
  initialState: initialUrlState,
  reducers: {
    setPageLoadUrlState: (state, action: PayloadAction<URLPayload>) => {
      // load up suggestions
      for (const [k, val] of Object.entries(action.payload.terms)) {
        state.urlTermSuggestions[k as keyof SearchBarTermsType] = val;
      }
      // load up booleans
      for (const [k, v] of Object.entries(action.payload.bools)) {
        state.urlTermBooleans[k as keyof SearchBarTermsType] = v;
      }
      // load up all the suggestions
      for (const [k, v] of Object.entries(action.payload.suggTerms)) {
        if (k === "variant") {
          state.urlVariantsWithAltNotationsSuggestions.variant = v;
        }
      }
      state.urlCats = Object.keys(action.payload.categories.cats);
      state.urlSigTerms = Object.keys(action.payload.categories.sig_terms);
    },
  },
});

export const { setPageLoadUrlState } = urlSlice.actions;

export default urlSlice.reducer;
