import React from "react";
import { ScaleLinear } from "d3";
import { BottomAxisProps } from "./BottomAxis";
import { primaryPalette } from "../../../libs/styles/theme";
import { GraphVariant, InteractionDataType } from "./VariantHistogram";
import { updateVariantFilter } from "../../../store/slices/variantSlice";
import { useAppDispatch } from "../../../store/hooks";
import { MARGIN } from "./VariantHistogramContainer";

interface BarsProps {
  data: Map<number, GraphVariant>;
  height: number;
  scaleX: BottomAxisProps["scale"];
  scaleY: ScaleLinear<number, number, never>;
  barWidth?: number;
  stickHeight: number;
  setInteractionData: React.Dispatch<
    React.SetStateAction<InteractionDataType | undefined>
  >;
  setTooltipAnchorEl: React.Dispatch<
    React.SetStateAction<SVGRectElement | null>
  >;
}

const BORDER_RADIUS = 6;

const Bars = ({
  data,
  height,
  scaleX,
  scaleY,
  barWidth,
  stickHeight,
  setInteractionData,
  setTooltipAnchorEl,
}: BarsProps) => {
  const children: React.JSX.Element[] = [];
  const dispatch = useAppDispatch();

  const handleMouseEnter = (
    e: React.MouseEvent<SVGRectElement, MouseEvent>,
    k: number
  ) => {
    setTooltipAnchorEl(e.currentTarget);

    const interactionData = data.get(k)?.variants || [];
    interactionData.sort((a, b) => {
      if (a.without_mut_hits > b.without_mut_hits) return -1;
      else if (a.without_mut_hits < b.without_mut_hits) return 1;
      else return 0;
    });
    setInteractionData({ data: interactionData });
  };

  const handleMouseLeave = () => {
    setInteractionData(undefined);
    setTooltipAnchorEl(null);
  };

  const onBarClick = (position: number) => {
    dispatch(updateVariantFilter(position));
  };

  data.forEach((v, k) => {
    const bw = barWidth ?? 0;
    const bh = barWidth ? height - stickHeight - scaleY(v.count) : 0;
    if (v.count > 0) {
      children.push(
        <React.Fragment key={`bar-${k}`}>
          <rect
            data-testid={`variant-histogram-bar-${k}`}
            style={{ cursor: "pointer" }}
            x={scaleX(k)}
            y={scaleY(v.count)}
            height={bh}
            width={bw}
            transform={`translate(-${MARGIN.left}, 0)`}
            rx={BORDER_RADIUS}
            fill={primaryPalette.teal.variant_05}
            onClick={() => onBarClick(k)}
            onMouseEnter={(e) => handleMouseEnter(e, k)}
            onMouseLeave={handleMouseLeave}
            aria-haspopup="true"
          />
          <line
            transform={`translate(-${MARGIN.left - bw / 2}, ${
              height - stickHeight
            })`}
            x1={scaleX(k)}
            y1={0}
            x2={scaleX(k)}
            y2={stickHeight}
            stroke={primaryPalette.dataVis.slate}
            strokeWidth={1}
          />
        </React.Fragment>
      );
    }
  });

  return <>{children}</>;
};

export default Bars;
