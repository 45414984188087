import React from "react";
import { useOpenSelectedModal } from "../../../modals/modalHooks";
import { VariantResult } from "../../../../libs/models/variant-result";
import { useAppSelector } from "../../../../store/hooks";
import { Modals } from "../../../../store/slices/modalSlice";
import { ErrorCard } from "../../../common/ErrorCard";

const EvidenceViewerErrorState = () => {
  const openSelectedModal = useOpenSelectedModal();
  const { selectedVariant } = useAppSelector((state) => state.curatedEvidence);

  let bodyVariantText = "";
  if (selectedVariant) {
    bodyVariantText = ` for ${VariantResult.variantCasing(selectedVariant.id)}`;
  }
  return (
    <ErrorCard
      bodyText={`Sorry, we couldn't retrieve the curated evidence${bodyVariantText}. If this error persists, please use the button below to reach out to Mastermind support.`}
      buttonText="Contact Mastermind Support"
      buttonFunction={() => openSelectedModal(Modals.contactUs)}
      testid="curated-evidence-viewer-error-card"
    />
  );
};

export default EvidenceViewerErrorState;
