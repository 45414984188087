import * as React from "react";
import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { Box, CssBaseline, Skeleton } from "@mui/material";

import { useGetFeatures } from "./libs/hooks/useGetFeatures";
import { getCurrentUser } from "./network/user";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import { loadUser } from "./store/slices/userSlice";

import Navbar from "./components/navbar";
import GlobalModal from "./components/modals/GlobalModal";
import SearchBar from "./components/searchbar";
import ArticlePageContainer from "./components/article";
import Gene from "./components/gene/Gene";
import Home from "./components/home/Home";
import { useHandleError } from "./libs/hooks/errorHandlerHook";
import { splitFeatureFlags } from "./libs/utils/splitFeatures";
import MastermindBanner from "./components/mastermind-banner";
import {
  AcmgDescriptionResponse,
  useLazyGetAcmgClassesQuery,
} from "./network/acmg-descriptions";
import { updateACMGClasses } from "./store/slices/acmgClassesSlice";
import ScrollAdjustment from "./components/common/scroll-adjustment";

declare global {
  interface Window {
    userGuiding?: {
      identify: (id: string, options: { user_type: string }) => void;
    };
  }
}

function App() {
  const dispatch = useAppDispatch();
  const handleError = useHandleError();
  const { isLoggedIn, user, loadingUser } = useAppSelector(
    (state) => state.user
  );
  const { featuresLoaded } = useAppSelector((state) => state.features);

  useGetFeatures();

  const [triggerGetAcmgClassesQuery] = useLazyGetAcmgClassesQuery();

  useEffect(() => {
    void triggerGetAcmgClassesQuery(undefined)
      .then(({ data }) => {
        dispatch(updateACMGClasses(data as AcmgDescriptionResponse));
      })
      .catch((err) => handleError(err));
  }, []);

  // Redirect to Eula page if user is logged in but not yet accepted the Eula
  useEffect(() => {
    if (isLoggedIn && !user?.eula_accepted) {
      const redirectParams = encodeURIComponent(window.location.search);
      window.location.href = `/eula?limit=eula&last_path=${window.location.pathname}${redirectParams}`;
    }
  }, [isLoggedIn, user?.eula_accepted]);

  // Check if we have a current user; if so, login said user, otherwise erase any user data
  useEffect(() => {
    getCurrentUser()
      .then(async (res) => {
        if (res.data?.id) {
          dispatch(loadUser(res.data));
          // eslint-disable-next-line
          window.userGuiding?.identify(res.data.id.toString(), {
            user_type: res.data.user_type,
          });
        } else {
          dispatch(loadUser());
        }

        // Has to happen after we get the user
        await splitFeatureFlags(dispatch, handleError);
      })
      .catch((err) => {
        // TODO - handle with error handler
        // eslint-disable-next-line
        console.log("err", err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // we want this to only happen on app load

  if (loadingUser || !featuresLoaded) {
    return (
      <Skeleton
        data-testid={"app-loader"}
        variant="rectangular"
        width={"100vw"}
        height={"100vh"}
      />
    );
  } else {
    return (
      <>
        <ScrollAdjustment />
        <CssBaseline />
        <Box width="100%" display="flex" flexDirection="column">
          <Navbar />
          <SearchBar />
          <GlobalModal />
          <MastermindBanner />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/articles" element={<ArticlePageContainer />} />
            <Route path="/gene" element={<Gene />} />
          </Routes>
        </Box>
      </>
    );
  }
}

export default App;
