import React from "react";
import { ErrorCard } from "../../../common/ErrorCard";
import { Modals } from "../../../../store/slices/modalSlice";
import { useOpenSelectedModal } from "../../../modals/modalHooks";

const ArticleViewerErrorState = () => {
  const openSelectedModal = useOpenSelectedModal();

  return (
    <ErrorCard
      bodyText="Sorry, we couldn't retrieve the article listing. If this error persists, please use the button below to reach out to Mastermind support."
      buttonText="Contact Mastermind Support"
      buttonFunction={() => openSelectedModal(Modals.contactUs)}
    />
  );
};

export default ArticleViewerErrorState;
