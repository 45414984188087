import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface KeywordCountState {
  [key: string]: number | undefined;
}

interface CategoryCountState {
  keywordCounts: Record<string, KeywordCountState>;
}

type AddKeywordCountPayload = {
  category: string;
  counts: KeywordCountState;
};

const initialState: CategoryCountState = {
  keywordCounts: {},
};

export const keywordCountSlice = createSlice({
  name: "keywordCount",
  initialState,
  reducers: {
    addKeywordCount: (
      state: CategoryCountState,
      action: PayloadAction<AddKeywordCountPayload>
    ) => {
      state.keywordCounts[action.payload.category] = action.payload.counts;
    },
    clearKeywordCounts: (state: CategoryCountState) => {
      state.keywordCounts = {};
    },
  },
});

export const { addKeywordCount, clearKeywordCounts } =
  keywordCountSlice.actions;

export default keywordCountSlice.reducer;
