import { SvgIcon } from "@mui/material";
import React from "react";

export function InheritancePattern() {
  return (
    <SvgIcon fontSize="small">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="13"
        height="13"
        viewBox="0 0 13 13"
        fill="none"
      >
        <path
          d="M9.875 12C8.83906 12 8 11.1609 8 10.125C8 9.58828 8.225 9.10312 8.60703 8.76328L6.81641 5.22422C6.71328 5.24062 6.60781 5.25 6.5 5.25C6.39219 5.25 6.28672 5.24062 6.18359 5.22422L4.41406 8.76328C4.775 9.10312 5 9.58828 5 10.125C5 11.1609 4.16094 12 3.125 12C2.08906 12 1.25 11.1609 1.25 10.125C1.25 9.08906 2.08906 8.25 3.125 8.25C3.35234 8.25 3.59141 8.28984 3.77422 8.36484L5.48047 4.95C4.96484 4.61484 4.625 4.03594 4.625 3.375C4.625 2.33953 5.46406 1.5 6.5 1.5C7.53594 1.5 8.375 2.33953 8.375 3.375C8.375 4.03594 8.03516 4.61484 7.51953 4.95L9.22578 8.36484C9.42969 8.28984 9.64766 8.25 9.875 8.25C10.9105 8.25 11.75 9.08906 11.75 10.125C11.75 11.1609 10.9105 12 9.875 12ZM5.375 3.35391C5.375 3.99609 5.87891 4.47891 6.5 4.47891C7.12109 4.47891 7.625 3.99609 7.625 3.35391C7.625 2.75367 7.12109 2.22891 6.5 2.22891C5.87891 2.22891 5.375 2.75367 5.375 3.35391ZM11 10.125C11 9.50391 10.4963 9 9.875 9C9.25391 9 8.75 9.50391 8.75 10.125C8.75 10.7461 9.25391 11.25 9.875 11.25C10.4963 11.25 11 10.7461 11 10.125ZM2 10.125C2 10.7461 2.50391 11.25 3.125 11.25C3.74609 11.25 4.25 10.7461 4.25 10.125C4.25 9.50391 3.74609 9 3.125 9C2.50391 9 2 9.50391 2 10.125Z"
          fill="#678E95"
        />
      </svg>
    </SvgIcon>
  );
}
