import { GraphVariant } from "./VariantHistogram";
import React from "react";
import { scaleLinear } from "d3";
import { MARGIN } from "./VariantHistogramContainer";
import { primaryPalette } from "../../../libs/styles/theme";

interface VariantMinimapProps {
  data: Map<number, GraphVariant>;
  maxAmino: number;
  height: number;
  width: number;
  maxBarHeight: number;
  zoomedDomain: Array<number>;
}

const BOUNDING_BAR_WIDTH = 3;

const VariantMinimap = ({
  data,
  maxAmino,
  height,
  width,
  maxBarHeight,
  zoomedDomain,
}: VariantMinimapProps) => {
  // wait until we have a width based on container width before rendering to avoid -width rect errors
  if (width < 0) {
    return <></>;
  }

  const children: React.JSX.Element[] = [];
  const miniMapWidth = width / 4;

  const scaleY = scaleLinear().domain([0, maxBarHeight]).range([height, 0]);
  const scaleX = scaleLinear().domain([0, maxAmino]).range([0, miniMapWidth]);

  const zoomedVarRange =
    scaleX(zoomedDomain[1]) - scaleX(zoomedDomain[0]) - BOUNDING_BAR_WIDTH;

  data.forEach((v, k) => {
    const bw = miniMapWidth / maxAmino;
    const bh = height - scaleY(v.count);

    if (v.count > 0) {
      children.push(
        <React.Fragment key={`minimap-bar-${k}`}>
          <rect
            data-testid={`variant-histogram-minimap-bar-${k}`}
            x={scaleX(k)}
            y={scaleY(v.count)}
            height={bh}
            width={bw}
            fill={primaryPalette.teal.neutral_teal_t3}
          />
        </React.Fragment>
      );
    }
  });

  return (
    <g transform={`translate(${MARGIN.left + width - miniMapWidth}, 0)`}>
      <g
        style={{
          width: miniMapWidth,
          height: height,
        }}
      >
        <rect
          x={scaleX(zoomedDomain[0])}
          width={BOUNDING_BAR_WIDTH}
          fill={"rgba(17, 65, 69, 0.60)"}
          height={height}
        />
        <rect
          x={scaleX(zoomedDomain[1])}
          width={BOUNDING_BAR_WIDTH}
          fill={"rgba(17, 65, 69, 0.60)"}
          height={height}
        />
        {children}
        <rect
          x={scaleX(zoomedDomain[0]) + BOUNDING_BAR_WIDTH}
          data-testid={"current-zoomed-variant-range"}
          width={zoomedVarRange < 0 ? 0 : zoomedVarRange}
          height={height}
          fill={"#D2F0F3"}
          opacity={0.5}
        />
      </g>
    </g>
  );
};

export default VariantMinimap;
