import { httpClient } from "./http-client";

export interface CheckForProteinCodingResponse {
  coding_gene: boolean;
}

export async function checkForProteinCoding(
  geneSymbol: string
): Promise<CheckForProteinCodingResponse> {
  const { data } = await httpClient.get<CheckForProteinCodingResponse>(
    `/protein_coding/${geneSymbol}`
  );
  return data;
}
