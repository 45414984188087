import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import pluralize from "pluralize";
import { Instance } from "@popperjs/core";
import { CondensedArticleSentence } from "../../../../../network/articles";
import ArticleSentence from "./ArticleSentence/ArticleSentence";
import AccordionButton from "../../../../common/AccordionButton";
import { primaryPalette } from "../../../../../libs/styles/theme";
import SeeMoreButton from "../../../../common/buttons/SeeMoreButton";
import TooltipComponent from "../../../../common/tooltip/TooltipComponent";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import KeywordSelector from "./KeywordSelector";
import { getUniqueSentences, groupSentences, processHighlights } from "./utils";

const TextMatchViewer = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);
  const isPro = user.is_pro;

  const viewSettings = useAppSelector((state) => state.textMatchFilter);
  const { data: articleData } = useAppSelector(
    (state) => state.featuredArticle
  );

  const theme = useTheme();
  const isXLDesktop = useMediaQuery(theme.breakpoints.up("xl"));
  const minVisibleCount = 6;
  const [visibleItemCount, setVisibleItemCount] = useState(
    articleData?.condensedSentences?.length ?? 0
  );
  const [expanded, setExpanded] = useState(true);
  const popperRef = React.useRef<Instance>(null);

  useEffect(() => {
    setVisibleItemCount(articleData?.condensedSentences?.length ?? 0);
  }, [articleData?.condensedSentences]);

  // Process sentences with highlights
  const processedSentences: CondensedArticleSentence[] = (
    articleData?.condensedSentences ?? []
  ).map((sentence) => ({
    ...sentence,
    content: processHighlights(sentence.content, isPro, viewSettings),
  }));

  // Group and filter sentences
  const sentenceGroups = groupSentences(processedSentences, viewSettings);
  const allUniqueSentences = getUniqueSentences(sentenceGroups);

  return (
    <>
      <Box
        display="flex"
        flexDirection={isXLDesktop ? "row" : "column"}
        alignItems={isXLDesktop ? "center" : "flex-start"}
        justifyContent="space-between"
        marginBottom={2}
      >
        <Typography
          variant="text12"
          fontWeight="fontWeightBold"
          textTransform="uppercase"
          color={primaryPalette.teal.neutral_teal_t4}
        >
          Filter Matches:
        </Typography>

        <Box display="flex" alignItems="center" flexWrap="wrap" gap="2px">
          {sentenceGroups.map((group) => {
            if (group.isProOnly && !isPro) {
              return (
                <TooltipComponent
                  key={group.type}
                  content="Upgrade to Pro to use all filter matches"
                  placement="bottom-start"
                  popperProps={{
                    popperRef,
                  }}
                  minWidth="none"
                  slotProps={{
                    popper: {
                      modifiers: [
                        {
                          name: "offset",
                          options: {
                            offset: [0, -8],
                          },
                        },
                      ],
                    },
                  }}
                >
                  <KeywordSelector
                    selectorType={group.type}
                    formLabel={`${group.label} ${group.sentences.length}`}
                    checked={false}
                    disabled={true}
                  />
                </TooltipComponent>
              );
            }

            return (
              <KeywordSelector
                key={group.type}
                selectorType={group.type}
                formLabel={`${group.label} ${group.sentences.length}`}
                checked={group.sentences.length === 0 ? false : group.selected}
                disabled={group.sentences.length === 0}
                onChange={() => {
                  dispatch(group.action(!group.selected));
                }}
              />
            );
          })}
        </Box>
      </Box>

      <Box marginBottom={2}>
        <AccordionButton
          handleClick={() => setExpanded((prev) => !prev)}
          expanded={expanded}
          label={`${allUniqueSentences.length || 0} Text ${pluralize(
            "Matches",
            allUniqueSentences.length
          )}`}
        />
      </Box>

      {expanded && (
        <Box>
          <Grid
            container
            id="sentence-matches"
            spacing={3}
            sx={{
              overflowX: "hidden",
              marginBottom: "5px",
              borderBottom: `1px solid ${primaryPalette.brown.neutral_brown_b3}`,
            }}
          >
            {allUniqueSentences
              .slice(0, visibleItemCount)
              .map((sentence, index) => {
                return (
                  <ArticleSentence
                    key={index}
                    sentence={sentence}
                  ></ArticleSentence>
                );
              })}
          </Grid>
          {allUniqueSentences.length > minVisibleCount && (
            <Box
              sx={{
                display: "flex",
                paddingTop: "10px",
                paddingBottom: "20px",
                justifyContent: "center",
                alignItems: "center",
                gap: "4px",
                alignSelf: "stretch",
              }}
            >
              <SeeMoreButton
                callback={() =>
                  setVisibleItemCount((prev) =>
                    prev === minVisibleCount
                      ? allUniqueSentences.length
                      : minVisibleCount
                  )
                }
                hasMoreItems={allUniqueSentences.length > visibleItemCount}
                id="article-see-more"
              />
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default TextMatchViewer;
