import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AcmgDescriptionResponse } from "../../network/acmg-descriptions";

interface ACMGClassesState {
  acmgClasses?: AcmgDescriptionResponse;
}

const initialState: ACMGClassesState = {
  acmgClasses: undefined,
};

export const acmgClassesSlice = createSlice({
  name: "acmgClasses",
  initialState,
  reducers: {
    updateACMGClasses: (
      state: ACMGClassesState,
      action: PayloadAction<AcmgDescriptionResponse>
    ) => {
      state.acmgClasses = action.payload;
    },
  },
});

export const { updateACMGClasses } = acmgClassesSlice.actions;

export default acmgClassesSlice.reducer;
