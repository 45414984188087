import React from "react";
import { useAppSelector } from "../../../../../store/hooks";
import AssessmentBarSmall from "./assessment-bar-small";
import { useLazyGetEvidenceViewerRecordsQuery } from "../../../../../network/reporter/reporter";
import { selectCuratedVariantData } from "../../../../../store/selectors/curatedEvidenceSelectors";

const AssessmentBarContainer = () => {
  const curatedVariantData = useAppSelector((state) =>
    selectCuratedVariantData(state)
  );
  const { selectedVariant } = useAppSelector((state) => state.curatedEvidence);

  const [triggerEvidenceViewerData, { data: evidenceViewerData }] =
    useLazyGetEvidenceViewerRecordsQuery();

  React.useEffect(() => {
    if (selectedVariant && selectedVariant.gene) {
      void triggerEvidenceViewerData(selectedVariant, true);
    }
  }, [selectedVariant, triggerEvidenceViewerData]);

  return (
    <AssessmentBarSmall
      curatedVariantData={curatedVariantData}
      evidenceViewerData={evidenceViewerData}
    />
  );
};

export default AssessmentBarContainer;
