import React from "react";
import MUIPopper, { PopperProps } from "@mui/material/Popper";

type GenomenonPopperProps = {
  children: PopperProps["children"];
  anchorEl: PopperProps["anchorEl"];
  open: boolean;
  id: string;
  placement?: PopperProps["placement"];
};

const GenomenonPopper = ({
  children,
  anchorEl,
  placement,
  open,
  id,
}: GenomenonPopperProps) => {
  return (
    <MUIPopper
      id={id}
      sx={{
        pointerEvents: "none",
        borderRadius: 8,
        marginLeft: 1,
        background: "white",
      }}
      open={open}
      anchorEl={anchorEl}
      placement={placement ? placement : "bottom"}
    >
      {children}
    </MUIPopper>
  );
};

export default GenomenonPopper;
