import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Button, Divider, Grid, IconButton, Typography } from "@mui/material";
import { defaultValue } from "../Summary/Summary";
import { primaryPalette, theme } from "../../../../libs/styles/theme";
import { Popup } from "../../../common/icons/Popup";
import { GeneStatistics } from "../GeneStatistics/GeneStatistics";
import { useOpenSelectedModal } from "../../../modals/modalHooks";
import { Modals } from "../../../../store/slices/modalSlice";
import { useGetGeneInfoQuery } from "../../../../network/genes";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useAppSelector } from "../../../../store/hooks";
import { SearchBarTerms } from "../../../../store/slices/searchSlice";
import { isProUser } from "../../../../libs/utils/user";
import Paywall from "../../../common/paywalls";
import { selectUrlTermIds } from "../../../../store/selectors/urlSelectors";

export const summaryCharLimit = 280;
export const summaryHeader = "Summary";

const Header = styled(Typography)(() => ({
  fontSize: "1.25rem",
}));

const Text = styled(Typography)(() => ({
  fontSize: "0.8rem",
}));

interface SummaryTextProps {
  isModal?: boolean;
}

export function SummaryText({ isModal = false }: SummaryTextProps) {
  const urlTermIds = useAppSelector((state) => selectUrlTermIds(state));
  const { user } = useAppSelector((state) => state.user);
  const { data: geneData, isLoading } = useGetGeneInfoQuery(
    urlTermIds[SearchBarTerms.gene][0]
      ? {
          gene: urlTermIds[SearchBarTerms.gene][0],
        }
      : skipToken
  );
  const openSelectedModal = useOpenSelectedModal();
  const [showFullText, setShowFullText] = useState(false);
  const summary = geneData?.summary ?? "";
  const includeButton = () =>
    !!summary && summary !== summary.slice(0, summaryCharLimit);

  const extractSummaryReference = () => {
    const matches = summary.match(/\[provided by(.*?)\]/gi);
    return matches?.length ? matches[0] : "";
  };

  const formatSummaryReference = () => {
    const reference = extractSummaryReference();
    return reference.replace(/\[|\]/g, "");
  };

  const formatSummary = () => {
    if (!summary) return defaultValue;
    const reference = extractSummaryReference();
    const formattedSummary = summary.replace(reference, "");
    return showFullText
      ? formattedSummary
      : `${formattedSummary.slice(0, summaryCharLimit).trim()}...`;
  };

  const handleModalOpen = () => {
    openSelectedModal(Modals.geneSummary);
  };

  if (isLoading) return <></>;

  return (
    <>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1 }}
        data-testid={"gene-summaryText"}
      >
        {isModal && <GeneStatistics />}

        <Grid item xs={isModal ? 12 : 6} alignSelf={"flex-end"}>
          <Header>{summaryHeader}</Header>
        </Grid>

        {!isModal && (
          <>
            <Grid
              item
              xs={6}
              sx={{ textAlign: "right" }}
              alignSelf={"flex-end"}
            >
              <IconButton
                aria-label="open-modal"
                onClick={handleModalOpen}
                sx={{ padding: "0" }}
              >
                <Popup />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ bgcolor: primaryPalette.gray.variant_11 }} />
            </Grid>
          </>
        )}

        <Grid item xs={12} sx={{ inlineSize: "min-content" }}>
          {!isProUser(user) ? (
            <Paywall
              titleText="Pro Edition Feature"
              bodyText="Upgrade to professional to view calculated gene information"
            />
          ) : null}
          <Text>{formatSummary()}</Text>
        </Grid>

        {includeButton() && (
          <Grid item xs={12}>
            <Button
              onClick={() => setShowFullText(!showFullText)}
              variant="text"
              sx={{
                fontSize: "0.6rem",
                fontWeight: 600,
                padding: 0,
                justifyContent: "left",
                "&:hover": {
                  background: "none",
                },
              }}
            >
              {showFullText ? "LESS" : "MORE"}
            </Button>
          </Grid>
        )}

        {!!summary && (
          <Grid item xs={12}>
            <Text sx={{ color: theme.palette.primary.main }}>
              {formatSummaryReference()}
            </Text>
          </Grid>
        )}
      </Grid>
    </>
  );
}
