import React, { useState } from "react";
import { Category } from "../../../store/slices/categoryLayoutSlice";
import { Box, Modal, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Grid from "@mui/material/Unstable_Grid2";
import CategoryLabel from "../CategoryLabel/CategoryLabel";
import KeywordModal from "../KeywordModal";
import { useAppSelector } from "../../../store/hooks";
import {
  borderColor,
  hoverColor,
  modalStyle,
} from "../../../libs/styles/category-popup-modal";

enum AllowedClosing {
  CANCEL = "Canceled",
  SELECTKEYWORDS = "Select Keywords",
}

export default function KeywordContainer(props: { keyword: Category }) {
  const { keyword } = props;
  const selectedCategories = useAppSelector(
    (state) => state.filter.userSelectedCategories
  );
  const selectedCatBG = "#FAD9BE";
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = (_: object, reason: string) => {
    if (
      reason === AllowedClosing.CANCEL ||
      reason === AllowedClosing.SELECTKEYWORDS
    ) {
      setOpen(false);
    }
  };

  const handleCancel = () => {
    handleClose({}, AllowedClosing.CANCEL);
  };

  const handleSelect = () => {
    handleClose({}, AllowedClosing.SELECTKEYWORDS);
  };

  const keywordCount = () => {
    if (keyword.allChildKeys.length != keyword.childCount) {
      return keyword.allChildKeys.length - keyword.childCount;
    }

    return keyword.childCount;
  };

  const selectionText = () => {
    const selectedCount = selectedKeywordCount();
    if (selectedCount > 0) {
      return `${selectedCount}/${keywordCount()} filters selected`;
    } else {
      return `${keywordCount()} filters available`;
    }
  };

  const selectedKeywordCount = () => {
    const selectedParentKeywords = keyword.children.filter((a) =>
      Object.keys(selectedCategories).some((b) => a.key === b)
    );
    const keywordChildrenSelected = Object.keys(selectedCategories).filter(
      (a) => a === keyword.key
    );
    const selectedChildKeywords = Object.keys(selectedCategories).filter((a) =>
      keyword.allChildKeys.some((b) => a === b)
    );

    let count = 0;
    if (keywordChildrenSelected.length > 0) {
      for (const child of keyword.children) {
        if (child.children.length > 0) {
          count += child.children.length;
        } else {
          count += 1;
        }
      }
    } else if (selectedParentKeywords.length > 0) {
      for (const child of selectedParentKeywords) {
        if (child.children.length > 0) {
          count += child.children.length;
        } else {
          count += 1;
        }
      }
    }

    count += selectedChildKeywords.length - selectedParentKeywords.length;

    return count;
  };

  return (
    <div>
      <Box
        onClick={handleOpen}
        key={keyword.displayName}
        sx={{
          padding: ".5rem",
          margin: ".5rem",
          border: `1px solid ${borderColor}`,
          ":hover": {
            bgcolor: hoverColor,
          },
          background: `${
            selectedKeywordCount() > 0 ? selectedCatBG : "#FFFFFF"
          }`,
        }}
      >
        <Grid container columns={5} alignItems={"center"}>
          <Grid xs={3}>
            <CategoryLabel category={keyword} />
          </Grid>
          <Grid xs={2}>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
              align={"right"}
              variant={"body1"}
            >
              {selectionText()} <AddIcon fontSize={"small"} />
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Modal open={open} onClose={handleClose}>
        <Box sx={modalStyle}>
          <KeywordModal
            category={keyword}
            handleSelection={handleSelect}
            handleCancel={handleCancel}
          />
        </Box>
      </Modal>
    </div>
  );
}
