import React from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { DialogContent, Modal } from "@mui/material";
import { closeModal } from "../../store/slices/modalSlice";
import LoginModal from "./LoginModal/LoginModal";
import OTPModal from "./OTPModal/OTPModal";
import GeneSummaryModal from "./GeneSummaryModal/GeneSummaryModal";
import AlertModal from "./AlertModal/AlertModal";
import ContactUsModal from "./ContactUsModal/ContactUsModal";

export default function GlobalModal() {
  const { modalOpen, currentModal, canCloseModal } = useAppSelector(
    (state) => state.modal
  );
  const dispatch = useAppDispatch();

  const renderModal = (component: React.ReactNode) => {
    return (
      <Modal
        open={modalOpen}
        onClose={() => {
          if (canCloseModal) {
            dispatch(closeModal());
          }
        }}
      >
        <DialogContent>{component}</DialogContent>
      </Modal>
    );
  };

  const modals = {
    login: renderModal(<LoginModal />),
    otp: renderModal(<OTPModal />),
    geneSummary: renderModal(<GeneSummaryModal />),
    contactUs: renderModal(<ContactUsModal />),
    alertWithLogin: renderModal(<AlertModal shouldShowLogin={true} />),
    alertWithoutLogin: renderModal(<AlertModal shouldShowLogin={false} />),
  };

  return <>{modals[currentModal]}</>;
}
