import { createSelector } from "@reduxjs/toolkit";
import { FeatureFlags } from "../slices/featureSlice";
import { RootState } from "../store";

export const getSplitFeatureFlags = (state: RootState) =>
  state.features.splitIO;

export const featureFlagName = (_: RootState, flagName: FeatureFlags) =>
  flagName;

export const getFeatureFlagByName = createSelector(
  getSplitFeatureFlags,
  featureFlagName,
  (flags, flagName) => flags[flagName]
);

export const getFeatureFlagByNameConfig = createSelector(
  getSplitFeatureFlags,
  featureFlagName,
  (flags, flagName) => flags[flagName].config
);

export const getFreeGeneList = (state: RootState) =>
  state.features.splitIO.free_gene_list.config;
