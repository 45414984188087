import React from "react";
import { Skeleton, SkeletonProps } from "@mui/material";

export function ArticleSkeletonLoader(props: SkeletonProps) {
  return (
    <>
      <Skeleton
        variant="rectangular"
        height={"5em"}
        sx={{ marginBottom: "0.5em" }}
        {...props}
      />
    </>
  );
}
