import { useSearchParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { UrlParams } from "../types/url-params";
import { reorderQueryParams } from "../utils/search";

export const useUrlSearchParamState = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchParamState, setSearchParamState] = useState(searchParams);
  const previousState = useRef(searchParamState);

  useEffect(() => {
    previousState.current = searchParamState;
  }, [searchParamState]);

  const updateSearchParams = (
    params: Record<string, string>,
    replace?: boolean,
    callback?: () => void
  ) => {
    setSearchParams(
      (searchParams) => {
        Object.entries(params).map(([key, value]) => {
          searchParams.set(key, value);
        });
        const reorderedParams = reorderQueryParams(searchParams);

        setSearchParamState(reorderedParams);
        if (callback) {
          callback();
        }

        return reorderedParams;
      },
      { replace: replace }
    );
  };

  const deleteSearchParams = (
    keys: string[],
    replace?: boolean,
    callback?: () => void
  ) => {
    setSearchParams(
      (searchParams) => {
        keys.map((key) => {
          if (searchParams.has(key)) {
            searchParams.delete(key);
          }
        });

        if (callback) {
          callback();
        }

        return searchParams;
      },
      { replace: replace }
    );
  };

  const getPrevSearchParamStateValue = (key: UrlParams) =>
    previousState.current.get(key);

  return {
    updateSearchParams,
    getPrevSearchParamStateValue,
    deleteSearchParams,
  };
};
