import {
  ButtonGroup,
  InputAdornment,
  IconButton,
  TextField,
  Button,
  ClickAwayListener,
  debounce,
} from "@mui/material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { styled } from "styled-components";

import { useUrlSearchParamState } from "../../../../../libs/hooks/useSearchParamState";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import {
  defaultSortPro,
  filterItems,
  MenuItem,
  resetArticleFilter,
  sortItems,
  updateArticleSearchFilter,
  updateArticleSearchFilterItem,
  updateArticleSearchSortItem,
} from "../../../../../store/slices/articleSlice";
import { primaryPalette } from "../../../../../libs/styles/theme";
import { UrlParams } from "../../../../../libs/types/url-params";
import { isProUser } from "../../../../../libs/utils/user";

import FilterMenu from "./FilterMenu/FilterMenu";
import SortMenu from "./SortMenu/SortMenu";

const ArticleFilterButton = styled(Button)`
  background: ${primaryPalette.teal.neutral_teal_t2};
  color: ${primaryPalette.teal.neutral_teal_t6};
  text-transform: unset !important;
  display: flex;
  padding: 5px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  text-align: center;
  font-family: Rubik Regular;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  &:hover {
    background: unset;
  }
`;

export default function ArticleListActions() {
  const dispatch = useAppDispatch();
  const { articleSearchFilter, articleSortItem, articleFilterItem } =
    useAppSelector((state) => state.articles);
  const { user } = useAppSelector((state) => state.user);

  const { updateSearchParams, deleteSearchParams } = useUrlSearchParamState();
  const [searchParams] = useSearchParams();

  const [filterAnchorEl, setFilterAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const [sortAnchorEl, setSortAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const [isSearchFocused, setIsSearchFocused] = React.useState(false);
  const [filterOpen, setFilterOpen] = React.useState<boolean>(false);
  const [sortOpen, setSortOpen] = React.useState<boolean>(false);
  const [filterText, setFilterText] =
    React.useState<string>(articleSearchFilter);

  const isPro = isProUser(user);
  const defaultSortItem =
    isPro && defaultSortPro ? defaultSortPro : sortItems[0];

  const articleListSearchParamValue = searchParams.get(
    UrlParams.ARTICLE_LIST_SEARCH
  );
  const currentSearchParamArticleSort = searchParams.get(
    UrlParams.ARTICLE_SORT
  );
  const currentSearchParamArticleSortAsc = searchParams.get(
    UrlParams.ARTICLE_SORT_ASC
  );
  const currentSearchParamArticleListFilter = searchParams.get(
    UrlParams.ARTICLE_LIST_FILTER
  );

  // Set the filter to be equal to the url query param
  React.useEffect(() => {
    if (articleListSearchParamValue) {
      setFilterText(articleListSearchParamValue);
      dispatch(updateArticleSearchFilter(articleListSearchParamValue));
    }
  }, [articleListSearchParamValue]);

  // Since articleSearchFilter can be updated from outside this component (when clicking on an author from the
  // Article Viewer for instance), we need to make sure we are able to update the TextField value if they
  // get out of synce
  React.useEffect(() => {
    if (articleSearchFilter !== filterText) {
      setFilterText(articleSearchFilter);
      if (articleSearchFilter !== "")
        updateSearchParams(
          { [UrlParams.ARTICLE_LIST_SEARCH]: articleSearchFilter },
          false
        );
    }
    // we do not want to add filterText as a dependency here as it will cause issues with not capturing
    // all keystrokes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleSearchFilter]);

  React.useEffect(() => {
    const activeFilterItem =
      filterItems.find(
        (item) => item.id === currentSearchParamArticleListFilter
      ) || filterItems[0];
    const activeSortItem =
      sortItems.find((item) => item.id === currentSearchParamArticleSort) ||
      defaultSortItem;
    const activeItemAsc = activeSortItem.asc.toString();

    if (
      !currentSearchParamArticleSort ||
      !currentSearchParamArticleSortAsc ||
      !currentSearchParamArticleListFilter
    ) {
      updateSearchParams(
        {
          [UrlParams.ARTICLE_LIST_FILTER]: activeFilterItem.id,
          [UrlParams.ARTICLE_SORT]: activeSortItem.id,
          [UrlParams.ARTICLE_SORT_ASC]: activeItemAsc,
        },
        true
      );
      // reset non-pro users to default if they do not have access to sort or filter value
    } else if (!isPro && (activeSortItem.proOnly || activeFilterItem.proOnly)) {
      updateSearchParams(
        {
          [UrlParams.ARTICLE_LIST_FILTER]: activeFilterItem.proOnly
            ? filterItems[0].id
            : activeFilterItem.id,
          [UrlParams.ARTICLE_SORT]: activeSortItem.proOnly
            ? defaultSortItem.id
            : activeSortItem.id,
          [UrlParams.ARTICLE_SORT_ASC]: activeSortItem.proOnly
            ? defaultSortItem.asc.toString()
            : activeItemAsc,
        },
        true,
        () => {
          if (activeSortItem.proOnly) {
            dispatch(updateArticleSearchSortItem(defaultSortItem));
          }
          if (activeFilterItem.proOnly) {
            dispatch(updateArticleSearchFilterItem(filterItems[0]));
          }
        }
      );
    } else if (currentSearchParamArticleListFilter !== articleFilterItem.id) {
      dispatch(updateArticleSearchFilterItem(activeFilterItem));
    } else if (currentSearchParamArticleSort !== articleSortItem.id) {
      dispatch(updateArticleSearchSortItem(activeSortItem));
    } else if (
      currentSearchParamArticleSortAsc !== articleSortItem.asc.toString()
    ) {
      dispatch(
        updateArticleSearchSortItem({
          ...articleSortItem,
          asc: !articleSortItem.asc,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentSearchParamArticleSort,
    currentSearchParamArticleSortAsc,
    currentSearchParamArticleListFilter,
  ]);

  const handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setFilterAnchorEl(event.currentTarget);
    setFilterOpen(true);
  };

  const handleSortClick = (event: React.MouseEvent<HTMLElement>) => {
    setSortAnchorEl(event.currentTarget);
    setSortOpen(true);
  };

  const handleClose = () => {
    setFilterAnchorEl(null);
    setFilterOpen(false);
    setSortAnchorEl(null);
    setSortOpen(false);
  };

  const handleSortItemClick = (item: MenuItem) => {
    if (item.id !== articleSortItem.id) {
      updateSearchParams({
        [UrlParams.ARTICLE_SORT]: item.id,
        [UrlParams.ARTICLE_SORT_ASC]: item.asc.toString(),
      });
    } else if (item.biDirectional) {
      const asc = !articleSortItem.asc;
      updateSearchParams({
        [UrlParams.ARTICLE_SORT_ASC]: asc.toString(),
      });
    }

    handleClose();
  };

  const handleFilterItemClick = (item: MenuItem) => {
    if (item.id !== articleFilterItem.id) {
      updateSearchParams({ [UrlParams.ARTICLE_LIST_FILTER]: item.id });
    }

    handleClose();
  };

  const onChange = (filter: string) => {
    dispatch(updateArticleSearchFilter(filter));
  };

  const onUrlChange = (filter: string) => {
    updateSearchParams({ [UrlParams.ARTICLE_LIST_SEARCH]: filter }, false);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChangeHandler = React.useCallback(debounce(onChange, 200), []);

  const debouncedUrlChangeHandler = React.useCallback(
    debounce(onUrlChange, 1000),
    []
  );

  const handleSearchInputChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    setFilterText(target.value);
    debouncedChangeHandler(target.value);
    debouncedUrlChangeHandler(target.value);
  };

  const handleSearchInputReset = () => {
    dispatch(resetArticleFilter());
    deleteSearchParams([UrlParams.ARTICLE_LIST_SEARCH]);
  };

  return (
    <>
      {isSearchFocused ? (
        <ClickAwayListener onClickAway={() => setIsSearchFocused(false)}>
          <TextField
            id="article-list-search-input"
            data-testid="article-list-search-input"
            label="Title, journal, PMID, or author"
            size="small"
            sx={{
              width: "25ch",
              "& .MuiOutlinedInput-root": {
                paddingRight: "6px!important",
                borderRadius: "0!important",
              },
            }}
            style={{
              background: "white",
              borderRadius: "6px 0 0 6px",
            }}
            InputLabelProps={{ style: { fontSize: 12 } }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    data-testid="article-list-search-close"
                    onClick={() => handleSearchInputReset()}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
              style: { fontSize: 12 },
            }}
            onChange={handleSearchInputChange}
            value={filterText}
          />
        </ClickAwayListener>
      ) : (
        <ButtonGroup size="small">
          <ArticleFilterButton
            data-testid="article-list-filter-btn"
            variant="contained"
            onClick={handleFilterClick}
            endIcon={<KeyboardArrowDownIcon />}
          >
            {articleFilterItem.label}
          </ArticleFilterButton>
          <ArticleFilterButton
            data-testid="article-list-sort-btn"
            variant="contained"
            onClick={handleSortClick}
            endIcon={<KeyboardArrowDownIcon />}
          >
            {articleSortItem.label}
          </ArticleFilterButton>
          <Button
            data-testid="article-list-search-btn"
            variant="contained"
            onClick={() => setIsSearchFocused(true)}
            style={{
              background: primaryPalette.teal.neutral_teal_t2,
              color: primaryPalette.teal.neutral_teal_t6,
            }}
          >
            <SearchIcon style={{ width: "18px", height: "18px" }} />
            {filterText !== "" ? (
              <EditNoteIcon
                style={{
                  position: "absolute",
                  top: 3,
                  right: 10,
                  color: primaryPalette.gray.variant_01,
                  border: `1px solid ${primaryPalette.warning.variant_01}`,
                  borderRadius: "50%",
                  backgroundColor: primaryPalette.warning.variant_01,
                  width: 10,
                  height: 10,
                }}
              />
            ) : (
              <></>
            )}
          </Button>
        </ButtonGroup>
      )}
      <FilterMenu
        filterAnchorEl={filterAnchorEl}
        filterOpen={filterOpen}
        handleFilterItemClick={handleFilterItemClick}
        handleClose={handleClose}
      />
      <SortMenu
        sortAnchorEl={sortAnchorEl}
        sortOpen={sortOpen}
        handleSortItemClick={handleSortItemClick}
        handleClose={handleClose}
      />
    </>
  );
}
