import React, { CSSProperties } from "react";
import { Typography } from "@mui/material";
import { primaryPalette } from "../../../../../libs/styles/theme";

type ArticleHeaderProps = {
  children: string;
  customStyle?: CSSProperties;
};

const ArticleHeader = ({ children, customStyle }: ArticleHeaderProps) => {
  return (
    <Typography
      variant="h4"
      color={primaryPalette.teal.neutral_teal_t4}
      sx={{
        ...customStyle,
      }}
    >
      {children}
    </Typography>
  );
};

export default ArticleHeader;
