import { Card, CardContent, Stack, Typography } from "@mui/material";
import React from "react";
import { primaryPalette } from "../../../../libs/styles/theme";

interface TreatmentTrialCardProps {
  title: React.ReactNode;
  testid: string;
  children: React.ReactNode;
}

const TreatmentTrialCard = ({
  title,
  testid,
  children,
}: TreatmentTrialCardProps) => {
  return (
    <Card
      data-testid={`${testid}-treatment-card`}
      style={{ margin: "2px 0px 2px 0px" }}
      className="treatment-trial-card"
    >
      <CardContent sx={{ padding: "10px" }}>
        <Typography
          variant={"text11"}
          sx={{ color: primaryPalette.grey.neutral_grey_g7, fontWeight: 600 }}
        >
          {title}
        </Typography>
        <Stack direction={"column"} gap={"4px"}>
          {children}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default TreatmentTrialCard;
