import React from "react";
import { Stack } from "@mui/material";
import { primaryPalette, boxShadowStyles } from "../../../../libs/styles/theme";
import EvidenceViewerErrorState from "../EvidenceViewerErrorState";
import ArticleViewerLoadingState from "../../article-viewer/ArticleViewerLoadingState";

type EvidenceViewerProps = {
  isError: boolean;
  isLoading: boolean;
  children: React.ReactNode;
};

export default function EvidenceViewer({
  isError,
  isLoading,
  children,
}: EvidenceViewerProps) {
  if (isError) {
    return <EvidenceViewerErrorState />;
  }

  if (isLoading) {
    return (
      <ArticleViewerLoadingState
        animation="pulse"
        testId="evidence-viewer-loading-state"
      />
    );
  }

  return (
    <Stack
      data-testid="evidence-viewer-pane"
      spacing={2}
      sx={{
        backgroundColor: primaryPalette.gray.variant_01,
        borderRadius: "16px",
        boxShadow: boxShadowStyles.evidenceViewer,
      }}
    >
      {children}
    </Stack>
  );
}
