import React, { forwardRef } from "react";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import { CheckIcon } from "../../../../../common/icons/CheckIcon";
import { primaryPalette } from "../../../../../../libs/styles/theme";
import { HighlightClass } from "../utils";

type KeywordSelectorProps = {
  selectorType: HighlightClass;
  formLabel: string;
  checked: boolean;
  disabled: boolean;
  onChange?: () => void;
};

const typeToColorMap: Record<HighlightClass, string> = {
  gene: primaryPalette.dataVis.green,
  variant: primaryPalette.dataVis.c2,
  rsid: primaryPalette.dataVis.rsid,
  cnv: primaryPalette.dataVis.terracotta,
  keyword: primaryPalette.dataVis.keyword,
  hpo: primaryPalette.dataVis.kiwi,
  unii: primaryPalette.dataVis.strawberry,
};

const KeywordUncheckedIcon = () => {
  return (
    <Box
      component="span"
      height={12}
      width={12}
      sx={{
        backgroundColor: primaryPalette.gray.variant_01,
      }}
    />
  );
};

const KeywordSelector = forwardRef<HTMLDivElement, KeywordSelectorProps>(
  ({ selectorType, formLabel, checked, disabled, onChange, ...props }, ref) => {
    return (
      <FormControlLabel
        {...props}
        ref={ref}
        control={
          <Checkbox
            checkedIcon={<CheckIcon />}
            icon={<KeywordUncheckedIcon />}
            checked={checked}
            onChange={onChange}
            data-testid={`${selectorType}-filter-checkbox`}
            sx={{
              p: 0.5,
            }}
          />
        }
        disabled={disabled}
        label={formLabel}
        sx={{
          color: primaryPalette.gray.variant_01,
          ml: "-8px",
          mr: "8px",
          backgroundColor: typeToColorMap[selectorType],
          padding: "2px 8px 2px 4px",
          borderRadius: "2px",
          height: 26,
          display: "flex",
          alignItems: "center",
          span: {
            fontSize: "14px",
          },
        }}
      />
    );
  }
);

KeywordSelector.displayName = "KeywordSelector";

export default KeywordSelector;
