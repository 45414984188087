import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface VariantState {
  filter: string | number;
}

const initialState: VariantState = {
  filter: "",
};

export const variantSlice = createSlice({
  name: "variant",
  initialState,
  reducers: {
    updateVariantFilter: (
      state: VariantState,
      action: PayloadAction<number | string>
    ) => {
      state.filter = action.payload;
    },
    resetVariantFilter: (state: VariantState) => {
      state.filter = "";
    },
  },
});

export const { updateVariantFilter, resetVariantFilter } = variantSlice.actions;

export default variantSlice.reducer;
