import React from "react";
import { Box } from "@mui/material";

import { primaryPalette } from "../../../../libs/styles/theme";
import { useAppSelector } from "../../../../store/hooks";
import { isProUser } from "../../../../libs/utils/user";
import Paywall from "../../../common/paywalls/Paywall";
import SupplementalMatches from "./SupplementalMatches";
import TextMatchViewer from "./TextMatchViewer";

const ArticleKeywordsSection = () => {
  const { user } = useAppSelector((state) => state.user);

  return (
    <Box
      data-testid="article-text-matches"
      sx={{
        padding: "20px 30px",
        borderBottom: `1px solid ${primaryPalette.brown.neutral_brown_b3}`,
        background: primaryPalette.brown.neutral_brown_b1,
      }}
    >
      <TextMatchViewer />

      {isProUser(user) ? (
        <SupplementalMatches />
      ) : (
        <React.Fragment>
          <Box
            data-testid="supplemental-matches-basic"
            sx={{
              fontSize: "14px",
              fontWeight: "600",
              letterSpacing: "0.56px",
              color: primaryPalette.teal.neutral_teal_t4,
              marginTop: "16px",
            }}
          >
            Supplemental Data
          </Box>
          <Paywall
            titleText="PRO EDITION PREVIEW"
            bodyText="You are missing out! Upgrade to professional view to see if there are supplemental matches."
          />
        </React.Fragment>
      )}
    </Box>
  );
};

export default ArticleKeywordsSection;
