import React from "react";
import { Grid, Link, Stack, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useAppSelector } from "../../../../../../store/hooks";
import { selectEvidenceViewerGnomadData } from "../../../../../../store/selectors/curatedEvidenceSelectors";

const GnomadPopulationData = () => {
  const gnmoadData = useAppSelector((state) =>
    selectEvidenceViewerGnomadData(state)
  );
  const [open, setOpen] = React.useState(false);

  if (!gnmoadData || gnmoadData.length === 0) {
    return null;
  }

  return (
    <Stack gap={1}>
      <Link
        component="button"
        variant="text12"
        textAlign="left"
        onClick={() => setOpen((prev) => !prev)}
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 0.5,
        }}
      >
        {open ? "Hide gnomAD data" : "Show gnomAD data"}
        <KeyboardArrowDownIcon fontSize="inherit" />
      </Link>
      {open ? (
        <>
          {gnmoadData.map((data, index) => (
            <Stack key={`population-data-${index}`} gap={1}>
              <Typography variant="text11" fontWeight="bold">
                {data.label}
              </Typography>

              <Grid container columnSpacing={2} rowSpacing={0.5} mb={1}>
                {data.populations.map((pop) => (
                  <Grid
                    key={pop.name}
                    item
                    display="flex"
                    flexDirection="column"
                    xs={6}
                  >
                    <Typography variant="text11">{pop.name}</Typography>
                    <Typography variant="text11">{pop.value}</Typography>
                  </Grid>
                ))}
              </Grid>
            </Stack>
          ))}
        </>
      ) : null}
    </Stack>
  );
};

export default GnomadPopulationData;
