import { api } from "./api";

export type GetDiseaseRelationshipsResponse = {
  [key: string]: {
    total: number;
    diseases: DiseaseResponse[];
  };
};

export type DiseaseResponse = {
  disease: string;
  classification: string;
  inheritance: string;
  mondo: string;
  clinical_pmids: string[];
  functional_pmids: string[];
};

export const InheritanceNames: { [key: string]: string } = {
  AR: "Autosomal Recessive",
  AD: "Autosomal Dominant",
  XL: "X-linked",
  XLR: "X-linked Recessive",
  XLD: "X-linked Dominant",
  M: "Mitochondrial",
  MF: "Multifactorial",
  DD: "Digenic Inheritance",
};

export const AssociationNames: { [key: string]: string } = {
  DEFINITIVE: "Definitive",
  STRONG: "Strong",
  MODERATE: "Moderate",
  LIMITED: "Limited",
  UNKNOWN: "No Known Association",
};

export const DiseaseRelationshipOrder: { [key: string]: number } = {
  [AssociationNames.DEFINITIVE]: 0,
  [AssociationNames.STRONG]: 1,
  [AssociationNames.MODERATE]: 2,
  [AssociationNames.LIMITED]: 3,
  [AssociationNames.UNKNOWN]: 4,
};

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getDiseaseRelationships: builder.query<
      GetDiseaseRelationshipsResponse,
      string[]
    >({
      query: (genes) => ({
        url: "/gene_data/disease_relationships",
        body: {
          gene: genes,
        },
        method: "POST",
      }),
    }),
  }),
});

export const { useLazyGetDiseaseRelationshipsQuery } = extendedApi;
