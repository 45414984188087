"use client";

import * as React from "react";
import { styled } from "@mui/material/styles";
import { primaryPalette } from "../../../../../libs/styles/theme";

import { useAnchorEl } from "../../../../../libs/hooks/useAnchorEl";
import { HeaderVariantProps } from "../../../../../libs/types/card";
import { IconDrop, IconBack } from "../../../../common/icons/Generic";
import { Stack, Typography, Button, Menu, MenuItem, Link } from "@mui/material";
import { VariantResult } from "../../../../../libs/models/variant-result";
import { CurationRecordVariant } from "../../../../../libs/types/articles";
import { updateSelectedVariant } from "../../../../../store/slices/curatedEvidenceSlice";
import { useDispatch } from "react-redux";

const CustomStack = styled(Stack)({
  height: "3.3125rem",
  marginBottom: "0.9125rem",
  alignItems: "center",
  borderRadius: "0.25rem",
  boxShadow:
    "-2px -4px 4px rgba(255, 252, 240, 0.5), 2px 4px 4px rgba(5, 46, 43, 0.08)",
  "&:last-child": {
    marginBottom: "0",
  },
  "& > a": {
    height: "0.75rem",
    paddingLeft: "0.375rem",
    "& > svg": {
      maxWidth: "0.75rem",
      height: "auto",
    },
  },
  "& > button, & div > h2": {
    flexGrow: "1",
    justifyContent: "space-between",
    width: "100%",
    "& > svg": {
      maxWidth: "0.75rem",
      height: "auto",
      position: "absolute",
      right: "0.25rem",
    },
  },
});

const VariantText = styled(Typography)(() => ({
  display: "block",
  color: primaryPalette.gray.variant_08,
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  textTransform: "none",
  paddingRight: "4px",
  "&:hover": {
    textOverflow: "clip",
    overflowX: "scroll",
  },
}));

const VariantButton = styled(Button)({
  padding: 0,
  height: "100%",
  justifyContent: "space-between",
  width: "100%",
  "&:hover, &:focus": {
    background: "none",
  },
  "& .MuiButton-endIcon": {
    marginRight: "5px",
    "&>*:nth-of-type(1)": {
      fontSize: "12px",
      color: primaryPalette.grey.neutral_grey_g7,
    },
  },
});

const VariantMenu = styled(Menu)({
  "& .MuiPaper-root": {
    width: "183px",
    padding: "0 2px 0 16px",
    "& .MuiMenuItem-root": {
      padding: "4px 0",
    },
  },
});

const VariantMenuSubhead = styled(Typography)({
  color: primaryPalette.teal.variant_07,
  textTransform: "uppercase",
});

const HeaderVariant: React.FC<HeaderVariantProps> = ({ variant, variants }) => {
  const dispatch = useDispatch();
  const { anchorEl, handleClick, handleClose } = useAnchorEl();
  const open = Boolean(anchorEl);

  const renderVariantHeader = () => {
    // conditionally include variant menu (cross navigation)
    if (variants && variants.length > 1) {
      // Menu
      const handleNav = (selectedVariant: CurationRecordVariant) => {
        dispatch(updateSelectedVariant(selectedVariant));
        handleClose();
      };

      return (
        <div style={{ width: "100%" }}>
          <VariantButton
            id="basic-button"
            data-testid="multi-variant-select"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            endIcon={<IconDrop />}
          >
            <VariantText variant="h2" data-testid="multi-variant-card-text">
              {variant && VariantResult.variantCasing(variant.id)}
            </VariantText>
          </VariantButton>
          <VariantMenu
            id="basic-menu"
            data-testid="multi-variant-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              horizontal: 150,
              vertical: 30,
            }}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <VariantMenuSubhead>variants</VariantMenuSubhead>
            {variants.map((v) => (
              <MenuItem
                key={v.id}
                onClick={() => handleNav(v)}
                disabled={v.id === variant?.id}
              >
                {VariantResult.variantCasing(v.id)}
              </MenuItem>
            ))}
          </VariantMenu>
        </div>
      );
    } else {
      return (
        <VariantText variant="h2" data-testid="single-variant-card-text">
          {variant && VariantResult.variantCasing(variant.id)}
        </VariantText>
      );
    }
  };

  return (
    <CustomStack direction="row" spacing={1}>
      <Link
        href={`/gene?gene=${variant?.gene ?? ""}`}
        data-testid="variant-back-link"
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <IconBack />
      </Link>
      {renderVariantHeader()}
    </CustomStack>
  );
};

export default HeaderVariant;
