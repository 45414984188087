import React from "react";
import { KeyboardArrowRight } from "@mui/icons-material";
import { Stack, Card, Typography, Link } from "@mui/material";
import { VariantResult } from "../../../../libs/models/variant-result";
import { primaryPalette } from "../../../../libs/styles/theme";
import { getVariantTypeString } from "../../../../libs/utils/variant";
import { MergedRelatedVariant } from "../../../../network/variants/types";
import { FlexRowDiv } from "../../../common/Flex";
import { BenignIcon } from "../../../common/icons/BenignIcon";
import { BookIcon } from "../../../common/icons/BookIcon";
import { PathogenicIcon } from "../../../common/icons/PathogenicIcon";
import { VariantIcon } from "../../../common/icons/VariantIcon";
import { VUSIcon } from "../../../common/icons/VUSIcon";

const getCallIndicator = (call: string) => {
  let icon = <></>;
  let color = primaryPalette.teal.neutral_teal_t4;

  if (call === "pathogenic" || call === "likely_pathogenic") {
    icon = <PathogenicIcon width={"12px"} height={"12px"} />;
    color = primaryPalette.pathogenic.variant_01;
  }

  if (call === "benign" || call === "likely_benign") {
    icon = <BenignIcon width={"12px"} height={"12px"} />;
    color = primaryPalette.benign.variant_01;
  }

  if (call === "vus" || call === "conflict") {
    icon = <VUSIcon width={"12px"} height={"12px"} />;
    color = primaryPalette.vus.variant_01;
  }

  return (
    <FlexRowDiv
      style={{
        backgroundColor: color,
        width: "14px",
        alignItems: "flex-start",
        paddingTop: "10px",
      }}
    >
      {icon}
    </FlexRowDiv>
  );
};

const RelatedVariantsList = (props: { variants: MergedRelatedVariant[] }) => {
  const { variants } = props;

  return (
    <Stack data-testid="related-variant-list" direction={"column"} gap={"2px"}>
      {variants.map((variant) => {
        return (
          <Link
            data-testid={`related-variant-link-${variant.variant}`}
            target="_blank"
            href={`/articles?gene=${variant.gene}&mutation=${variant.variant}`}
            style={{ textDecoration: "none" }}
            key={variant.variant}
          >
            <Card style={{ margin: "2px 0px 2px 0px" }}>
              <Stack direction={"row"}>
                {getCallIndicator(variant.acmgCall)}
                <Stack direction={"column"} width={"100%"} margin={"8px 10px"}>
                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <Typography
                      variant="text12"
                      color={primaryPalette.grey.neutral_grey_g8}
                    >
                      {VariantResult.variantCasing(variant.variant)}
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        gap: "2px",
                        alignItems: "center",
                      }}
                    >
                      <BookIcon width={"12px"} height={"12px"} />
                      <Typography
                        variant="text12"
                        color={primaryPalette.grey.neutral_grey_g7}
                      >
                        {variant.totalHits.toLocaleString()}
                      </Typography>
                      <KeyboardArrowRight
                        style={{
                          height: "12px",
                          width: "12px",
                          color: primaryPalette.grey.neutral_grey_g6,
                        }}
                      />
                    </div>
                  </Stack>
                  <Stack direction={"row"} alignItems={"center"} gap={"2px"}>
                    <VariantIcon width={"12px"} height={"12px"} />
                    <Typography
                      variant="text12"
                      color={primaryPalette.grey.neutral_grey_g7}
                    >
                      {getVariantTypeString(variant.type)}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Card>
          </Link>
        );
      })}
    </Stack>
  );
};

export default RelatedVariantsList;
