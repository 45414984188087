export interface ValueLabel {
  [key: string]: string;
}

export const options: ValueLabel = {
  "database-grp": "Population Data: All Database Terms",
  "general-grp_3": "Population Data: All General Terms",
  "evolutionary conservation-sub": "Evolutionary Conservation: All Terms",
  "ethnicities-sub": "Ethnicities: All Terms",
  "population data-sub": "Population Data: All Terms",
  "functional-sub": "Functional: All Terms",
  "pedigrees and case studies-sub": "Pedigrees and Case Studies: All Terms",
  "inheritance pattern-sub": "Inheritance Pattern: All Terms",
  "de novo inheritance-sub": "De novo Inheritance: All Terms",
  "protein domains-sub": "Protein Domains: All Terms",
  "loss of function variants-sub": "Loss of Function Variants: All Terms",
  "length affecting variants-sub": "Length Affecting Variants: All Terms",
  "computational methods-sub": "Computational Methods: All Terms",
  "polymorphisms and benign variants-sub":
    "Polymorphisms and Benign Variants: All Terms",
  "sequencing-sub": "Sequencing: All Terms",
  "general-sub": "Classification Keywords: All Terms",
  "acronym-grp": "Ethnicities: All Acronym Terms",
  "ethnicities-grp": "Ethnicities: All Ethnicities Terms",
  "prevalence-grp": "Population data: All Prevalence Terms",
  "in vivo-grp": "Functional: All In Vivo Terms",
  "in vitro-grp": "Functional: All In Vitro Terms",
  "case-grp": "Pedigrees and case studies: All Case Terms",
  "general-grp": "Pedigrees and case studies: All General Terms",
  "genotype-grp": "Pedigrees and case studies: All Genotype Terms",
  "individual-grp": "Pedigrees and case studies: All Individual Terms",
  "inheritance-grp_1": "Pedigrees and case studies: All Inheritance Terms",
  "segregation-grp_1": "Pedigrees and case studies: All Segregation Terms",
  "allele-grp": "Inheritance pattern: All Allele Terms",
  "inheritance-grp": "Inheritance pattern: All Inheritance Terms",
  "mosaicism-grp": "Inheritance pattern: All Mosaicism Terms",
  "penetrance-grp": "Inheritance pattern: All Penetrance Terms",
  "segregation-grp": "Inheritance pattern: All Segregation Terms",
  "zygosity-grp": "Inheritance pattern: All Zygosity Terms",
  "general-lof-grp": "Loss of function variants: All General Terms",
  "coding-grp": "Loss of function variants: All Coding Terms",
  "non-coding-grp": "Loss of function variants: All Non Terms",
  "benign-grp": "Polymorphisms: All Benign Terms",
  "polymorphism-grp": "Polymorphisms: All Polymorphism Terms",
  "brand-grp": "Sequencing: All Brand Terms",
  "technique-grp": "Sequencing: All Technique Terms",
  "acmg-grp": "Classification Keywords: All Acmg Terms",
  "classifications-grp": "Classification Keywords: All Classifications Terms",
  "interpretation-grp": "Classification Keywords: All Interpretation Terms",
  "diagnosis-cat": "Diagnosis: All Terms",
  "prognosis-cat": "Prognosis: All Terms",
  "therapy-cat": "Therapy: All Terms",
  "clinical trials-cat": "Clinical Trials: All Terms",
  "variant-mechanism-cat": "Variant: All Terms",
  "copy number-cat": "Copy Number: All Terms",
  "fusion events-cat": "Fusion events: All Terms",
  "epigenetics-cat": "Epigenetics: All Terms",
  "general-grp_2": "Variant: All General Terms",
  "amino acid-grp": "Variant: All Acid Terms",
  "expression-sub": "Variant: All Expression Terms",
  "exon-grp": "Copy Number: All Exon Terms",
  "intron-grp": "Copy Number: All Intron Terms",
  "copy number - general-grp": "Copy Number: All General Terms",
};

// function to fetch the text based on the value
export const getValueLabel = (value: string): string => {
  return options[value] ?? value;
};
