import React, { useEffect, useState } from "react";
import { primaryPalette, theme } from "../../../../../libs/styles/theme";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { FlexRowDiv } from "../../../../common/Flex";
import LegendList from "../LegendList/LegendList";
import VariantTypeBox from "../../VariantTypeBox";
import {
  SubVariantDataType,
  VariantTypes,
  variantWedgeColors,
} from "../../../../../libs/types/variant";
import { useGetGeneInfoQuery } from "../../../../../network/genes";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useAppSelector } from "../../../../../store/hooks";
import { SearchBarTerms } from "../../../../../store/slices/searchSlice";
import { selectUrlTermIds } from "../../../../../store/selectors/urlSelectors";

const LegendLabels = {
  [VariantTypes.MISSENSE]: "Missense",
  [VariantTypes.SYNONYMOUS]: "Synonymous",
  [VariantTypes.TRUNCATING]: "Truncating",
  [VariantTypes.INFRAME]: "In-Frame",
  [VariantTypes.SPLICE]: "Splice",
  [VariantTypes.NONCODING]: "Non-Coding",
};

type LegendAccordionItemProps = {
  type: VariantTypes;
  count: number;
  subCount: number;
  subTypeSummary: Array<SubVariantDataType>;
  variantClassificationSource: string;
};

const LegendAccordionItem = ({
  type,
  count,
  subCount,
  subTypeSummary,
  variantClassificationSource,
}: LegendAccordionItemProps) => {
  const urlTermIds = useAppSelector((state) => selectUrlTermIds(state));
  const { data: geneData } = useGetGeneInfoQuery(
    urlTermIds[SearchBarTerms.gene][0]
      ? {
          gene: urlTermIds[SearchBarTerms.gene][0],
        }
      : skipToken
  );
  const geneSymbol = geneData?.gene_symbol ?? "";
  const getColor = (type: string) => {
    return (
      variantWedgeColors[type].backgroundColor || primaryPalette.gray.variant_01
    );
  };
  const [expanded, setExpanded] = useState(false);

  const isDisabled = () => {
    return subTypeSummary.length === 0 || subCount === 0;
  };

  const toggleExpanded = () => {
    if (!isDisabled()) {
      setExpanded(!expanded);
    }
  };

  // I would have thought the useState on 46 would have done the right thing, but without this it doesn't work
  // good enough for now, but we should come back and double check later
  useEffect(() => {
    setExpanded(false);
  }, [geneSymbol, variantClassificationSource]);

  return (
    <Accordion
      disabled={isDisabled()}
      expanded={expanded}
      onClick={toggleExpanded}
      data-testid={`accordion-item-${type}`}
      sx={{
        backgroundColor: primaryPalette.teal.neutral_teal_t1,
        boxShadow:
          "0px 1px 0px 0px rgba(0, 0, 0, 0.05), 0px -1px 0px 0px rgba(255, 255, 255, 0.75)",
        margin: "3px 0",
        "&:first-of-type": {
          marginTop: 0,
        },
        "&:last-of-type": {
          marginBottom: 0,
        },
        "&:before": {
          display: "none",
        },
        "& .MuiAccordionSummary-root.Mui-disabled": {
          opacity: "unset",
          backgroundColor: primaryPalette.teal.neutral_teal_t2,
        },
        "& .Mui-disabled": {
          opacity: "unset",
        },
        "&.MuiAccordion-root.Mui-expanded": {
          margin: 0,
        },
      }}
    >
      <AccordionSummary
        expandIcon={
          subTypeSummary.length > 0 && subCount > 0 ? (
            <ArrowForwardIosSharpIcon sx={{ fontSize: "8px" }} />
          ) : null
        }
        aria-controls={type}
        data-testid={`accordion-summary-${type}`}
        id={`${type}-header`}
        sx={{
          flexDirection: "row-reverse",
          minHeight: "unset",
          "&.MuiAccordion-root.Mui-expanded": {
            margin: 0,
          },
          "&.MuiAccordionSummary-root.Mui-expanded": {
            minHeight: "unset",
          },
          "& .MuiAccordionSummary-content": {
            margin: "8px 0px",
            marginLeft: theme.spacing(1),
          },
          "& .MuiAccordionSummary-content.Mui-expanded": {
            margin: `8px 0 8px ${theme.spacing(1)}`,
          },
        }}
      >
        <FlexRowDiv
          style={{
            marginLeft: subTypeSummary.length > 0 ? "0px" : "8px",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <FlexRowDiv style={{ gap: "5px" }}>
            <VariantTypeBox style={{ backgroundColor: getColor(type) }} />
            <Typography
              variant={"text16"}
              color={primaryPalette.grey.neutral_grey_g9}
            >
              {LegendLabels[type]}
            </Typography>
          </FlexRowDiv>
          <Typography
            variant={"text16"}
            color={primaryPalette.grey.neutral_grey_g9}
          >
            {count}
          </Typography>
        </FlexRowDiv>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          backgroundColor: primaryPalette.teal.neutral_teal_t1,
          padding: "0px 0px 0px 20px",
        }}
      >
        <LegendList subTypeSummary={subTypeSummary} />
      </AccordionDetails>
    </Accordion>
  );
};

export default LegendAccordionItem;
