import React, { FormEvent, useEffect, useState } from "react";
import { Button, TextField, Typography } from "@mui/material";
import ModalBox from "../../common/ModalBox";
import { contactUs } from "../../../network/user-actions";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { closeModal } from "../../../store/slices/modalSlice";
import { useHandleError } from "../../../libs/hooks/errorHandlerHook";
import { useLocation, Location } from "react-router-dom";
import { useSnackbar } from "notistack";
import { GENOMENON_EMAIL_SUPPORT } from "../../../libs/constants";

const appendUrlToBody = (body: string, location: Location) => {
  return `URL: ${location.pathname}${location.search}\n\nMessage: ${body}`;
};

const ContactUsModal = () => {
  const { user, isLoggedIn } = useAppSelector((state) => state.user);
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [body, setBody] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [bodyError, setBodyError] = useState(false);
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const handleError = useHandleError();

  // default email to the logged in user email, if present
  useEffect(() => {
    if (isLoggedIn) setEmail(user.email);
  }, [user.email, isLoggedIn]);

  const resetForm = () => {
    setEmailError(false);
    setBodyError(false);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setEmailError(false);
    setBodyError(false);

    if (email === "") {
      setEmailError(true);
    }
    if (body === "") {
      setBodyError(true);
    }

    if (email && body) {
      const fullBody = appendUrlToBody(body, location);
      resetForm();
      contactUs({ email: email, message: fullBody })
        .then(() => {
          dispatch(closeModal());
        })
        .catch((err) => {
          handleError(err);
          enqueueSnackbar(
            `Sorry we could not deliver your message. Please email us at ${GENOMENON_EMAIL_SUPPORT}`,
            { variant: "error" }
          );
        });
    }
  };

  return (
    <ModalBox>
      <form onSubmit={handleSubmit} noValidate>
        <Typography
          gutterBottom={true}
          id="modal-modal-title"
          variant="h4"
          component="h2"
        >
          Contact Us
        </Typography>
        <TextField
          inputProps={{ "data-testid": "contact-us-modal-email" }}
          label="Email"
          onChange={(e) => setEmail(e.target.value)}
          required
          variant="outlined"
          color="secondary"
          type="email"
          sx={{ mb: 3 }}
          fullWidth
          value={email}
          error={emailError}
          helperText={emailError ? "Email is required" : ""}
        />
        <TextField
          multiline
          rows={4}
          label="What would you like to say?"
          inputProps={{ "data-testid": "contact-us-modal-body" }}
          onChange={(e) => setBody(e.target.value)}
          required
          variant="outlined"
          color="secondary"
          type="text"
          value={body}
          error={bodyError}
          fullWidth
          sx={{ mb: 3 }}
          helperText={bodyError ? "Contact us body is required" : ""}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            type="submit"
            data-testid="contact-us-modal-submit"
          >
            Submit
          </Button>
        </div>
      </form>
    </ModalBox>
  );
};

export default ContactUsModal;
