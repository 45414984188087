import React, { useState, FormEvent } from "react";
import { Button, Typography, TextField } from "@mui/material";
import { userSignIn } from "../../../network/user";
import { loginUser } from "../../../store/slices/userSlice";
import { useAppDispatch } from "../../../store/hooks";
import ModalBox from "../../common/ModalBox";
import axios from "axios";
import { closeModal } from "../../../store/slices/modalSlice";
import { useSnackbar } from "notistack";

const OTPModal = () => {
  const [otpAttempt, setOtpAttempt] = useState("");
  const [otpError, setOtpError] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setOtpError(false);

    if (otpAttempt == "") {
      setOtpError(true);
    }

    if (otpAttempt) {
      userSignIn({ user: { otp_attempt: otpAttempt } })
        .then((res) => {
          dispatch(loginUser(res.data));
          dispatch(closeModal());
          enqueueSnackbar("Welcome to Mastermind!", { variant: "success" });
        })
        .catch((err) => {
          if (axios.isAxiosError(err)) {
            const errData = err?.response?.data as { error: string };
            if (errData?.error === "otp required") {
              window.location.href = "/otp/new";
            }
          } else {
            // eslint-disable-next-line
            console.error(err);
          }
        });
    }
  };

  return (
    <ModalBox>
      <form onSubmit={handleSubmit}>
        <Typography
          gutterBottom={true}
          id="modal-modal-title"
          variant="h4"
          component="h2"
        >
          Your account requires two-factor authentication. Please enter your
          six-digit one-time code.
        </Typography>
        <TextField
          label="OTP Code"
          onChange={(e) => setOtpAttempt(e.target.value)}
          required
          variant="outlined"
          color="secondary"
          type="number"
          sx={{ mb: 3 }}
          fullWidth
          value={otpAttempt}
          error={otpError}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button variant="contained" color="primary" type="submit">
            Login
          </Button>
        </div>
      </form>
    </ModalBox>
  );
};

export default OTPModal;
