import { Category } from "../../../store/slices/categoryLayoutSlice";
import Grid from "@mui/material/Unstable_Grid2";
import { Tooltip, Typography } from "@mui/material";
import React from "react";
import AcmgChip from "../AcmgChip/AcmgChip";

export default function CategoryLabel(props: { category: Category }) {
  const { category } = props;
  const acmgCols = 1;
  const textCols = category.acmgCategory ? 2 : 3;

  const titleTooltipElement = (cat: Category) => {
    return (
      <React.Fragment>
        <Typography variant={"body2"}>{cat.description}</Typography>
      </React.Fragment>
    );
  };

  const categoryTitle = (cat: Category) => {
    if (cat.description !== "") {
      return (
        <Tooltip
          disableFocusListener
          title={titleTooltipElement(cat)}
          placement={"top"}
        >
          <Typography align={"left"} variant={"body1"}>
            {cat.displayName}
          </Typography>
        </Tooltip>
      );
    }

    return (
      <Typography align={"left"} variant={"body1"}>
        {cat.displayName}
      </Typography>
    );
  };

  return (
    <Grid container columnSpacing={2} columns={3} alignItems={"center"}>
      {category.acmgCategory && (
        <Grid xs={acmgCols}>
          {category.acmgClasses.map((acmgClass) => {
            return (
              <AcmgChip
                key={`acmg-category-${category.displayName}-${acmgClass}`}
                acmgClass={acmgClass}
              />
            );
          })}
        </Grid>
      )}

      <Grid xs={textCols}>{categoryTitle(category)}</Grid>
    </Grid>
  );
}
