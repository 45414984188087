import { Typography } from "@mui/material";
import React from "react";
import { primaryPalette } from "../../../../../../../libs/styles/theme";
import { EvidenceViewerArticleCardFooterDiscreteDataItemProps } from "../types";

const AssociatedDiseases = ({
  discreteData,
}: EvidenceViewerArticleCardFooterDiscreteDataItemProps) => {
  if (!discreteData.evidence_tags) {
    return null;
  }

  const diseaseTags = discreteData.evidence_tags.filter((et) =>
    et.name.startsWith("DIS:")
  );

  if (diseaseTags.length === 0) {
    return null;
  }

  return (
    <span data-testid="disease-data-section">
      <Typography
        variant="text11Emphasis"
        color={primaryPalette.teal.neutral_teal_t4}
      >
        Disease:{" "}
      </Typography>
      <Typography variant="text11" color={primaryPalette.grey.neutral_grey_g8}>
        {diseaseTags.map((et) => et.description).join(", ")}
      </Typography>
    </span>
  );
};

export default AssociatedDiseases;
