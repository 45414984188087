import { SupplementalMatchType } from "../../../../../network/articles";

// determineFileName: Supplemental File data can include a file_name and download_link_text.
// We follow the below logic to determine what to show for the filename:
// - If download link and the file name are identical, only show the file name
// - If the download link and the file name are different, show the download link
// followed by the file name in parentheses
// - If we only have a download link, show that
export const determineFileName = (
  supp_match: SupplementalMatchType
): string => {
  const fileNameText = supp_match.supplementary_match_source.file_name ?? "";
  const downloadLinkText =
    supp_match.supplementary_match_source.download_link_text ?? "";
  const fileNameAndLinkDifferent =
    fileNameText.toLowerCase() !== downloadLinkText.toLowerCase();

  if (fileNameAndLinkDifferent && fileNameText && downloadLinkText) {
    return [downloadLinkText, `(${fileNameText})`].join(" ");
  }
  if (fileNameText) {
    return fileNameText;
  }
  return downloadLinkText;
};
