import React from "react";
import NavBarDropdownItem, {
  NavBarDropdownItemProps,
} from "../../NavBarDropdownItem";

const alertsMenuItems: NavBarDropdownItemProps[] = [
  {
    id: "about_alerts",
    label: "About Mastermind Alerts",
    route: "/alerts",
  },
  {
    id: "my_alerts",
    label: "My Alerts",
    route: "/alerts/list",
  },
];

const AlertsDropdown = () => {
  return alertsMenuItems.map((item) => (
    <NavBarDropdownItem key={item.id} {...item} />
  ));
};

export default AlertsDropdown;
