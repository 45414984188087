import IconButton from "@mui/material/IconButton";
import React from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useSnackbar } from "notistack";
import { Instance } from "@popperjs/core";
import TooltipComponent from "../../tooltip/TooltipComponent";

type CopyToClipboardButtonProps = {
  copyText: string;
  tooltipText?: string;
  successMessage?: string;
  fontSize?: number;
};

export const errorText =
  "There was an issue copying the information to your clipboard. Please try again.";

const CopyToClipboardButton = ({
  copyText,
  fontSize,
  tooltipText,
  successMessage,
}: CopyToClipboardButtonProps) => {
  const popperRef = React.useRef<Instance>(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = React.useCallback(
    async (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      event.stopPropagation();
      try {
        await navigator.clipboard.writeText(copyText.replace(/<[^>]+>/g, ""));
        if (successMessage) {
          enqueueSnackbar(successMessage, {
            variant: "success",
            preventDuplicate: true,
          });
        }
      } catch (err) {
        enqueueSnackbar(errorText, {
          variant: "error",
          preventDuplicate: true,
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [copyText]
  );

  return (
    <IconButton
      component="span"
      aria-label="copy-to-clipboard"
      onClick={(e) => handleClick(e)}
      onMouseDown={(e) => e.stopPropagation()}
      sx={{
        fontSize: fontSize ? `${fontSize}px` : "inherit",
        padding: 0,
      }}
    >
      {tooltipText ? (
        <TooltipComponent
          content={tooltipText}
          popperProps={{ popperRef }}
          placement="right"
          minWidth="none"
          testId="copy-to-clipboard-tooltip"
        >
          <ContentCopyIcon
            sx={{ fontSize: "inherit" }}
            data-testid="content-copy-icon"
          />
        </TooltipComponent>
      ) : (
        <ContentCopyIcon
          sx={{ fontSize: "inherit" }}
          data-testid="content-copy-icon"
        />
      )}
    </IconButton>
  );
};

export default CopyToClipboardButton;
