import { SvgIcon } from "@mui/material";
import React from "react";

export function ClinicalEvidenceIcon({
  height,
  width,
}: {
  height?: string;
  width?: string;
}) {
  return (
    <SvgIcon style={{ height: height, width: width }}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 12" fill="none">
        <path
          d="M11.8337 4C11.8337 3.26354 11.2368 2.68542 10.5003 2.68542C9.76387 2.68542 9.16699 3.28229 9.16699 4C9.16699 4.62062 9.59283 5.1375 10.167 5.28625V7.33333C10.167 8.80396 8.97074 9.98125 7.50033 9.98125C6.02991 9.98125 4.83366 8.78521 4.83366 7.33333V7.29965C6.51345 7.1296 7.83366 5.7236 7.83366 3.99965V1.0184C7.83366 0.892103 7.76237 0.776937 7.64941 0.72027L6.31608 0.0723535C6.15168 -0.0103341 5.95108 0.0573805 5.86878 0.221437C5.78574 0.349166 5.85241 0.548958 6.01699 0.631666L7.16699 1.20583V3.99958C7.16699 5.47021 5.97074 6.6475 4.50033 6.6475C3.02991 6.6475 1.83366 5.47083 1.83366 4V1.20583L2.98283 0.631666C3.14753 0.548978 3.21428 0.349166 3.13191 0.184374C3.05033 0.0203326 2.84824 -0.0473757 2.68366 0.0353118L1.35033 0.683228C1.23824 0.758541 1.16699 0.873749 1.16699 1.00021V3.98146C1.16699 5.70521 2.48741 7.11062 4.16699 7.28146V7.31515C4.16699 9.15306 5.66241 10.6297 7.50033 10.6297C9.33824 10.6297 10.8337 9.13431 10.8337 7.31515V5.28542C11.4087 5.1375 11.8337 4.62083 11.8337 4ZM10.5003 4.66667C10.1322 4.66667 9.83366 4.36812 9.83366 4.01875C9.83366 3.65062 10.1322 3.37083 10.5003 3.37083C10.8685 3.37083 11.167 3.66937 11.167 4.01875C11.167 4.36875 10.8691 4.66667 10.5003 4.66667Z"
          fill="#437179"
        />
      </svg>
    </SvgIcon>
  );
}
