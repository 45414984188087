import { Button, Link, Typography } from "@mui/material";
import React, { useState } from "react";
import TruncateMarkup from "react-truncate-markup";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { primaryPalette } from "../../../../../../libs/styles/theme";
import { pubmedLink } from "../../../../../../libs/utils/links";

interface PmidListProps {
  pmidList: string[];
}

interface PmidListToggleProps {
  callback(): void;
  label: string;
  testId: string;
}

const PmidListToggle = ({
  callback,
  label,
  testId,
}: PmidListToggleProps): React.ReactElement => {
  return (
    <Button
      size="small"
      variant="text"
      sx={{
        textTransform: "none",
        lineHeight: 1,
        fontSize: "12px",
        padding: "2px",
        marginTop: "-2px",
        minWidth: "auto",
      }}
      onClick={callback}
      data-testid={testId}
    >
      {label}
    </Button>
  );
};

const PmidList = ({ pmidList }: PmidListProps) => {
  const [showAll, setShowAll] = useState(false);

  return (
    <>
      <Typography
        lineHeight="1.3"
        color={primaryPalette.grey.neutral_grey_g6}
        variant="text11"
      >
        PMID
      </Typography>

      <TruncateMarkup
        lines={showAll ? 9999 : 3}
        lineHeight={12}
        ellipsis={
          <PmidListToggle
            callback={() => setShowAll((prev) => !prev)}
            label="... See all"
            testId="see-all-pmids-toggle"
          />
        }
      >
        <Typography
          color={primaryPalette.grey.neutral_grey_g6}
          variant="text11"
          maxWidth="80%"
          lineHeight="1.3"
        >
          {pmidList.map((pmid, i, arr) => {
            return (
              <TruncateMarkup.Atom key={`${pmid}-${i}`}>
                <span>
                  <Typography
                    key={`pmid-data-${pmid}`}
                    variant="text11"
                    color={primaryPalette.grey.neutral_grey_g6}
                  >
                    <Link
                      color={primaryPalette.teal.neutral_teal_t5}
                      underline="always"
                      target={"_blank"}
                      rel={"noopener"}
                      href={pubmedLink(pmid)}
                    >
                      {pmid}
                    </Link>
                  </Typography>
                  {arr.length - 1 !== i ? ", " : ""}
                </span>
              </TruncateMarkup.Atom>
            );
          })}
          {showAll ? (
            <TruncateMarkup.Atom>
              <PmidListToggle
                callback={() => setShowAll((prev) => !prev)}
                label="See less"
                testId="see-less-pmid-toggle"
              />
            </TruncateMarkup.Atom>
          ) : null}
        </Typography>
      </TruncateMarkup>
      <OpenInNewIcon
        style={{
          color: primaryPalette.teal.neutral_teal_t5,
          fontSize: "13px",
        }}
      />
    </>
  );
};

export default PmidList;
