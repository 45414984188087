import { CondensedArticleSentence } from "../../../../../network/articles";
import {
  setViewCnv,
  setViewGene,
  setViewKeyword,
  setViewPhenotype,
  setViewRsid,
  setViewTherapy,
  setViewVariant,
  TextMatchFilterState,
} from "../../../../../store/slices/textMatchFilterSlice";

export type HighlightClass =
  | "gene"
  | "variant"
  | "rsid"
  | "cnv"
  | "keyword"
  | "hpo"
  | "unii";

/**
 * Processes highlight classes in sentence text based on view settings
 */
export const processHighlights = (
  sentenceText: string,
  isPro: boolean,
  viewSettings: TextMatchFilterState
): string => {
  const {
    viewGene,
    viewVariant,
    viewRsid,
    viewCnv,
    viewKeyword,
    viewPhenotype,
    viewTherapy,
  } = viewSettings;
  let text = sentenceText;

  // For pro users, to enable filtering by rsid, convert both
  // variant and gene highlights that contain 'rs' to rsid class
  if (isPro) {
    // Convert variant highlights with rs
    text = text.replaceAll(
      /<highlight class='variant'>rs([^<]+)<\/highlight>/g,
      "<highlight class='rsid'>rs$1</highlight>"
    );

    // Convert gene highlights with rs
    text = text.replaceAll(
      /<highlight class='gene'>rs([^<]+)<\/highlight>/g,
      "<highlight class='rsid'>rs$1</highlight>"
    );
  }

  const highlightMap: Record<HighlightClass, boolean> = {
    gene: viewGene,
    variant: viewVariant,
    rsid: viewRsid,
    cnv: viewCnv,
    keyword: viewKeyword,
    hpo: viewPhenotype,
    unii: viewTherapy,
  };

  Object.entries(highlightMap).forEach(([className, isVisible]) => {
    if (!isVisible) {
      text = text.replaceAll(
        `class='${className}'`,
        `class='${className} no-highlight'`
      );
    }
  });

  return text;
};

/**
 * Filters sentences based on highlight class
 */
export const filterSentencesByHighlight = (
  sentences: CondensedArticleSentence[],
  highlightClass: HighlightClass
): CondensedArticleSentence[] => {
  return sentences.filter((sentence) =>
    sentence.content.includes(`class='${highlightClass}`)
  );
};

/**
 * Groups sentences by their highlight types and selection status
 */
export const groupSentences = (
  sentences: CondensedArticleSentence[],
  viewSettings: TextMatchFilterState
) => {
  return [
    {
      type: "gene" as HighlightClass,
      label: "Genes",
      sentences: filterSentencesByHighlight(sentences, "gene"),
      selected: viewSettings.viewGene,
      action: setViewGene,
      isProOnly: false,
    },
    {
      type: "variant" as HighlightClass,
      label: "Variants",
      sentences: filterSentencesByHighlight(sentences, "variant"),
      selected: viewSettings.viewVariant,
      action: setViewVariant,
      isProOnly: false,
    },
    {
      type: "rsid" as HighlightClass,
      label: "RSIDs",
      sentences: filterSentencesByHighlight(sentences, "rsid"),
      selected: viewSettings.viewRsid,
      action: setViewRsid,
      isProOnly: true,
    },
    {
      type: "cnv" as HighlightClass,
      label: "CNVs",
      sentences: filterSentencesByHighlight(sentences, "cnv"),
      selected: viewSettings.viewCnv,
      action: setViewCnv,
      isProOnly: true,
    },
    {
      type: "keyword" as HighlightClass,
      label: "Keywords",
      sentences: filterSentencesByHighlight(sentences, "keyword"),
      selected: viewSettings.viewKeyword,
      action: setViewKeyword,
      isProOnly: true,
    },
    {
      type: "hpo" as HighlightClass,
      label: "Phenotypes",
      sentences: filterSentencesByHighlight(sentences, "hpo"),
      selected: viewSettings.viewPhenotype,
      action: setViewPhenotype,
      isProOnly: true,
    },
    {
      type: "unii" as HighlightClass,
      label: "Therapies",
      sentences: filterSentencesByHighlight(sentences, "unii"),
      selected: viewSettings.viewTherapy,
      action: setViewTherapy,
      isProOnly: true,
    },
  ];
};

/**
 * Gets unique sentences from all selected sentence groups
 */
export const getUniqueSentences = (
  groupedSentences: ReturnType<typeof groupSentences>
): CondensedArticleSentence[] => {
  const selectedSentences = groupedSentences
    .filter((group) => group.selected)
    .flatMap((group) => group.sentences);

  return [...new Set(selectedSentences)];
};
