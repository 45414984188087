import React from "react";
import { Article } from "../../../../network/articles";
import { useAppSelector } from "../../../../store/hooks";
import { isProUser } from "../../../../libs/utils/user";
import ArticleCard from "../../../common/cards/article-card/ArticleCard";
import { ArticleCardBody } from "../../../common/cards/article-card/ArticleCardBody/ArticleCardBody";
import { ArticleCardHeader } from "./ArticleCardHeader/ArticleCardHeader";

interface ArticleCardProps {
  article: Article;
  showClinVarBadge: boolean;
  onArticleChange: () => void;
  selected: boolean;
}

export default function ArticleListCard({
  article,
  showClinVarBadge,
  onArticleChange,
  selected,
}: ArticleCardProps) {
  const { isLoggedIn, user } = useAppSelector((state) => state.user);
  const anonymous = !isLoggedIn || !user;

  const onCardClick = () => {
    if (!anonymous) {
      onArticleChange();
    }
  };

  return (
    <ArticleCard
      onCardClick={onCardClick}
      selected={selected}
      pmid={article.pmid}
    >
      <ArticleCard.Header>
        {isProUser(user) ? <ArticleCardHeader article={article} /> : null}
      </ArticleCard.Header>
      <ArticleCard.Body>
        <ArticleCardBody
          article={article}
          showClinVarBadge={showClinVarBadge}
        />
      </ArticleCard.Body>
    </ArticleCard>
  );
}
