import {
  FeatureState,
  initialFeatureState,
} from "../../store/slices/featureSlice";
import { httpClient } from "../http-client";
import { SplitIOFeature } from "./types";

export async function getFeatures(): Promise<FeatureState> {
  const { data } = await httpClient.get<FeatureState>("/features");

  return data;
}

export async function getSplitIOFeatures(): Promise<SplitIOFeature> {
  const { data, status } = await httpClient.get<SplitIOFeature>(
    "features/splitio"
  );

  if (status !== 200) {
    return initialFeatureState.splitIO;
  }

  return data;
}
