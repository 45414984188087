import { useState, useRef } from "react";

interface UseExpandCollapseOptions {
  initialItemCount?: number;
  totalItemCount?: number;
  initialHeight?: number;
}

function useExpandCollapse({
  initialItemCount = 6,
  totalItemCount,
  initialHeight = 500,
}: UseExpandCollapseOptions) {
  const [visibleItemCount, setVisibleItemCount] =
    useState<number>(initialItemCount);
  const [height, setHeight] = useState<string | number>(initialHeight);
  const contentRef = useRef<HTMLDivElement | null>(null);

  const toggleItems = () => {
    setVisibleItemCount((prevItemCount) =>
      totalItemCount
        ? prevItemCount === initialItemCount
          ? totalItemCount
          : initialItemCount
        : initialItemCount
    );
  };

  const toggleHeight = () => {
    setHeight((currentHeight) =>
      currentHeight === "auto" ? `${initialHeight}px` : "auto"
    );
  };

  const hasMoreItems = totalItemCount
    ? visibleItemCount < totalItemCount
    : false;
  const showToggle = contentRef.current
    ? contentRef.current.scrollHeight > initialHeight
    : false;

  return {
    contentRef,
    toggleHeight,
    height,
    showToggle,
    toggleItems,
    visibleItemCount,
    hasMoreItems,
  };
}

export default useExpandCollapse;
