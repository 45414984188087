import React from "react";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { primaryPalette } from "../../../../../../libs/styles/theme";
import { ACMGCategories } from "../../../../../../libs/types/variant";

type ACMGCategorySectionProps = {
  label: ACMGCategories;
  icon: React.JSX.Element;
  children: React.ReactNode;
};

const ACMGCategorySection = ({
  label,
  icon,
  children,
}: ACMGCategorySectionProps) => {
  return (
    <Box data-testid={`evidence-viewer-acmg-${label}-section`} width="100%">
      <Box display="flex" alignItems="center">
        {icon}
        <Typography variant="h6" color={primaryPalette.grey.neutral_grey_g7}>
          {label}
        </Typography>
      </Box>
      <Divider aria-hidden="true" sx={{ my: "4px" }} />

      <Stack gap={"10px"}>{children}</Stack>
    </Box>
  );
};

export default ACMGCategorySection;
