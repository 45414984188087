import { SearchState } from "../../store/slices/searchSlice";

export const getSearchTabCount = (search: SearchState): number => {
  let tabCount = 0;

  // Check each category for at least one element
  tabCount += search.gene.length > 0 ? 1 : 0;
  tabCount += search.cnv.length > 0 ? 1 : 0;
  tabCount += search.hpo.length > 0 ? 1 : 0;
  tabCount += search.variant.length > 0 ? 1 : 0;
  tabCount += search.keyword.length > 0 ? 1 : 0;
  tabCount += search.disease.length > 0 ? 1 : 0;
  tabCount += search.unii.length > 0 ? 1 : 0;

  // Check sig_terms and cats for at least one key
  if (
    Object.keys(search.sig_terms).length > 0 ||
    Object.keys(search.cats).length > 0
  ) {
    tabCount += 1;
  }

  return tabCount;
};

export const getSearchWrapperStyles = (search: SearchState): object => {
  const tabCount = getSearchTabCount(search);

  const stylesMap: Record<number, { width: string; marginLeft: string }> = {
    0: { width: "60%", marginLeft: "20%" },
    1: { width: "58%", marginLeft: "20%" },
    2: { width: "38%", marginLeft: "20%" },
    3: { width: "32%", marginLeft: "20%" },
    4: { width: "27%", marginLeft: "10%" },
  };

  const defaultVal = { width: "auto", marginLeft: "0" };
  return stylesMap[tabCount] || defaultVal;
};

export const getSearchStyles = (search: SearchState): string => {
  const tabCount = getSearchTabCount(search);

  switch (tabCount) {
    case 0:
    case 1:
      return "85%";
    case 2:
      return "75%";
    case 3:
    case 4:
      return "70%";
    default:
      return "300px";
  }
};
