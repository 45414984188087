import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { GetArticleResponse } from "../../network/articles";

export type GeneMatchElement = {
  id: string;
  label: string;
  gene?: string;
};
export interface FeaturedArticleState {
  data?: GetArticleResponse;
  selectedGeneMatch?: GeneMatchElement;
  selectedVariantMatch?: GeneMatchElement;
}

const initialFeaturedArticleState: FeaturedArticleState = {
  data: undefined,
  selectedGeneMatch: undefined,
  selectedVariantMatch: undefined,
};

export const featuredArticleSlice = createSlice({
  name: "featuredArticle",
  initialState: initialFeaturedArticleState,
  reducers: {
    updateFeaturedArticleData: (
      state: FeaturedArticleState,
      action: PayloadAction<GetArticleResponse>
    ) => {
      state.data = action.payload;
    },
    updateSelectedGeneMatch: (
      state: FeaturedArticleState,
      action: PayloadAction<GeneMatchElement>
    ) => {
      state.selectedGeneMatch = action.payload;
    },
    updateSelectedVariantMatch: (
      state: FeaturedArticleState,
      action: PayloadAction<GeneMatchElement>
    ) => {
      state.selectedVariantMatch = action.payload;
    },
    clearFeaturedArticleState: () => initialFeaturedArticleState,
  },
});

export const {
  updateFeaturedArticleData,
  updateSelectedGeneMatch,
  updateSelectedVariantMatch,
  clearFeaturedArticleState,
} = featuredArticleSlice.actions;

export default featuredArticleSlice.reducer;
