import React from "react";
import { styled } from "@mui/material/styles";
import { Divider } from "@mui/material";
import { Grid, Typography } from "@mui/material";
import { theme } from "../../../../libs/styles/theme";

export const dataSourceHeader = "Data Source/ Metric";
export const scoreHeader = "Score";
export const evaluationHeader = "Genomenon Evaluation";

const Header = styled(Typography)(({ theme }) => ({
  fontSize: "0.65rem",
  wordBreak: "break-word",
  color: theme.palette.secondary.contrastText,
}));

const Column = styled(Grid)(() => ({
  inlineSize: "min-content",
  paddingTop: "0.25rem",
  textAlign: "center",
}));

interface SummaryCriteriaHeaderProps {
  isModal?: boolean;
}

export function SummaryCriteriaHeader({
  isModal = false,
}: SummaryCriteriaHeaderProps) {
  const paddingLeft = isModal ? "0.75rem" : "inherit";

  return (
    <>
      <Column
        item
        xs={4}
        sx={{
          textAlign: "left",
          paddingBottom: "0.75rem",
          paddingLeft,
        }}
      >
        <Header theme={theme}>Data Source/ Metric</Header>
      </Column>

      <Column item xs={3}>
        <Header theme={theme}>Score</Header>
      </Column>

      <Column item xs={5}>
        <Header theme={theme}>Genomenon Evaluation</Header>
      </Column>

      <Grid item xs={12}>
        <Divider sx={isModal ? { borderStyle: "dashed" } : {}} />
      </Grid>
    </>
  );
}
