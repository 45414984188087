import React, { forwardRef, Ref, CSSProperties, HTMLAttributes } from "react";
import styled from "styled-components";

interface ScrollerProps extends HTMLAttributes<HTMLDivElement> {
  style?: CSSProperties;
}
const StyledScrollbarWrapper = styled.div`
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #c0c0c0;
    border-radius: 6px;
  }
  &::-webkit-scrollbar-track {
    background: #ffffff;
  }
  &::-webkit-scrollbar-button {
    border-radius: 10px;
    height: 5px;
  }
  overflow-y: scroll;
  overflow-x: hidden;
`;

const ScrollerRef = forwardRef<HTMLDivElement, ScrollerProps>(
  ({ style, ...props }, ref: Ref<HTMLDivElement>) => {
    return <StyledScrollbarWrapper style={{ ...style }} ref={ref} {...props} />;
  }
);

export default ScrollerRef;
