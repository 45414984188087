import React from "react";
import {
  DiseaseResource,
  DiseaseTreatment,
} from "../../../../network/reporter/types";
import { Link, Stack, Typography } from "@mui/material";
import TreatmentTrialCard from "../treatment-trial-card/TreatmentTrialCard";
import DOMPurify from "dompurify";
import { primaryPalette } from "../../../../libs/styles/theme";

interface TreatmentTrialListComponentProps {
  resources: DiseaseResource[];
  treatments: DiseaseTreatment;
}

const TreatmentTrialListComponent = ({
  treatments,
  resources,
}: TreatmentTrialListComponentProps) => {
  const sponsoredTherapies = treatments.sponsored_therapies;
  const treatmentResources = treatments.treatment_resources;
  const resourceOptions = resources;

  return (
    <Stack direction="column" gap="2px" sx={{ marginBottom: "20px" }}>
      {sponsoredTherapies && sponsoredTherapies.length > 0 ? (
        <TreatmentTrialCard testid="sponsored" title={"Sponsored"}>
          {sponsoredTherapies.map((sponsoredTherapy) => {
            const linkId = sponsoredTherapy.name
              .toLowerCase()
              .split(" ")
              .join("-");
            return (
              <div key={sponsoredTherapy.name}>
                <Typography
                  variant="text12"
                  component="div"
                  data-testid={`${linkId}-link`}
                >
                  <Link
                    target="_blank"
                    underline="always"
                    href={sponsoredTherapy.url}
                  >
                    {sponsoredTherapy.name}
                  </Link>
                </Typography>
                <Typography variant="text12" component="div">
                  ({sponsoredTherapy.generic})
                </Typography>
                <Typography
                  variant="text14"
                  component="div"
                  sx={{
                    my: 0.5,
                    a: {
                      color: primaryPalette.teal.neutral_teal_t6,
                    },
                  }}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(sponsoredTherapy.description),
                  }}
                />
              </div>
            );
          })}
        </TreatmentTrialCard>
      ) : null}
      <TreatmentTrialCard
        testid="therapies"
        title="Therapies & Clinical Trials"
      >
        {treatmentResources.length > 0 ? (
          treatmentResources.map((therapy) => {
            return (
              <Typography
                key={therapy.label}
                component="p"
                variant="text12"
                mt={0.5}
              >
                <Link target="_blank" underline="always" href={therapy.url}>
                  {" "}
                  {therapy.label}
                </Link>
              </Typography>
            );
          })
        ) : (
          <Typography variant="text12">
            No Therapies Or Clinical Trials Found
          </Typography>
        )}
      </TreatmentTrialCard>
      <TreatmentTrialCard testid="resource-options" title="Resource Options">
        {resourceOptions.length > 0 ? (
          resourceOptions.map((resource) => {
            return (
              <Typography key={resource.label} variant="text12">
                <Link target="_blank" underline="always" href={resource.url}>
                  {" "}
                  {resource.label}
                </Link>{" "}
                {resource.description}
              </Typography>
            );
          })
        ) : (
          <Typography variant="text12">No Resource Options Found</Typography>
        )}
      </TreatmentTrialCard>
    </Stack>
  );
};

export default TreatmentTrialListComponent;
