import React from "react";
import { Stack } from "@mui/material";
import { primaryPalette } from "../../../libs/styles/theme";
import ArticleMetadataSection from "./ArticleMetadataSection";
import ArticleGeneVarSection from "./ArticleGeneVarSection";
import ArticleAbstractSection from "./ArticleAbstractSection";
import ArticleKeywordsSection from "./ArticleKeywordsSection";
import ArticleViewerErrorState from "./ArticleViewerErrorState";
import ArticleViewerLoadingState from "./ArticleViewerLoadingState";
import NotFoundContent from "./NotFoundContent";

type ArticleViewerProps = {
  isError: boolean;
  isLoadingArticleViewer: boolean;
  isEmptyArticleViewer: boolean;
};

export default function ArticleViewer({
  isError,
  isLoadingArticleViewer,
  isEmptyArticleViewer,
}: ArticleViewerProps) {
  if (isError) {
    return <ArticleViewerErrorState />;
  }

  if (isLoadingArticleViewer) {
    return (
      <ArticleViewerLoadingState
        animation="pulse"
        testId="article-viewer-loader"
      />
    );
  }

  if (isEmptyArticleViewer) {
    return <NotFoundContent />;
  }

  return (
    <Stack
      data-testid="article-viewer-pane"
      spacing={2}
      sx={{
        backgroundColor: primaryPalette.gray.variant_01,
        borderRadius: "16px 16px 0px 0px",
        border: `2px solid ${primaryPalette.teal.neutral_teal_t3}`,
      }}
    >
      {/* journal, published date, title, authors, and pub med link */}
      <ArticleMetadataSection />

      {/* gene and variant counts/dropdowns */}
      <ArticleGeneVarSection />

      {/* text matches and supplemental data */}
      <ArticleKeywordsSection />

      {/* abstract, medical subject headings, keywords, substances */}
      <ArticleAbstractSection />
    </Stack>
  );
}
