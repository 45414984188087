import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import {
  scaleLinear,
  transition,
  zoom,
  select,
  D3ZoomEvent,
  zoomIdentity,
} from "d3";
import BottomAxis from "./BottomAxis";
import Bars from "./Bars";
import { VariantDiagramAAPopper } from "./VariantDiagramAAPopper";
import { Proteins } from "./Proteins";
import { Box } from "@mui/material";
import { boxShadowStyles, primaryPalette } from "../../../libs/styles/theme";
import { Domain } from "../../../libs/types/gene";
import { ProteinDomainPopper } from "./ProteinDomainPopper";
import VariantMinimap from "./VariantMinimap";
import {
  GRAPH_HEIGHT,
  MARGIN,
  PROTEIN_MARGIN_TOP,
  PROTEINS_HEIGHT,
  STICK_HEIGHT,
  XAXIS_HEIGHT,
} from "./VariantHistogramContainer";
import { CIRCLE_GRAPH_BUTTON_HISTOGRAM_MINIMAP_HEIGHT } from "../Gene";
import { useGetGeneInfoQuery } from "../../../network/genes";
import { useGetVariantsQuery } from "../../../network/variants/variants";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useAppSelector } from "../../../store/hooks";
import { SearchBarTerms } from "../../../store/slices/searchSlice";
import { selectUrlTermIds } from "../../../store/selectors/urlSelectors";
import { Variant } from "../../../network/variants/types";

export type HistogramProps = {
  data: DataItems[];
};

export type DataItems = {
  variant: string;
  codon: number;
};

export type InteractionDataType = {
  data: Variant[];
};

export type GraphVariant = {
  count: number;
  variants: Variant[];
};

const TICKS = 10;
export const BAR_WIDTH = 8;

const VariantHistogram = () => {
  const { urlTermBooleans, urlCats, urlSigTerms } = useAppSelector(
    (state) => state.url
  );
  const urlTermIds = useAppSelector((state) => selectUrlTermIds(state));
  const searchedGene = urlTermIds[SearchBarTerms.gene][0];
  const { data: geneData } = useGetGeneInfoQuery(
    searchedGene
      ? {
          gene: searchedGene,
        }
      : skipToken
  );
  const { data: variantsData } = useGetVariantsQuery(
    searchedGene
      ? {
          urlTermIds: urlTermIds,
          urlBooleanParams: urlTermBooleans,
          urlCats: urlCats,
          urlSigTerms: urlSigTerms,
          urlString: urlTermIds.toString(),
        }
      : skipToken
  );
  const variants = variantsData?.variants ?? [];
  const [width, setWidth] = useState(0);
  const [tooltipAnchorEl, setTooltipAnchorEl] =
    React.useState<SVGRectElement | null>(null);
  const [interactionData, setInteractionData] = useState<InteractionDataType>();
  const [domainData, setDomainData] = useState<Domain>();
  const [currentGlobalZoomState, setCurrentGlobalZoomState] =
    useState(zoomIdentity);
  const minAmino = 0;
  const maxAmino = (geneData?.protein_sequence ?? "").length + 2;
  const [maxBarHeight, setMaxBarHeight] = useState<number>(0);
  const [maxVariantPos, setMaxVariantPos] = useState<number>(0);
  const t = transition().duration(1000);
  const svgRef = useRef<SVGSVGElement>(null);
  const miniMapHeight =
    CIRCLE_GRAPH_BUTTON_HISTOGRAM_MINIMAP_HEIGHT.height +
    CIRCLE_GRAPH_BUTTON_HISTOGRAM_MINIMAP_HEIGHT.upDownPadding * 2;
  const shouldSetInitialZoom = useRef(true);

  const transformedData = useMemo(() => {
    const mapArray: Map<number, GraphVariant> = new Map();
    for (let i = minAmino; i < maxAmino + 1; i++) {
      mapArray.set(i, {
        count: 0,
        variants: [],
      });
    }
    let maxCount = 0;
    let maxPosition = 0;

    variants.map((d) => {
      if (d.position !== undefined) {
        const mapData = mapArray.get(d.position);
        let tempData: GraphVariant = {
          count: 1,
          variants: [d],
        };
        if (mapData !== undefined) {
          tempData = {
            count: mapData.count + 1,
            variants: [...mapData.variants, d],
          };
        }

        mapArray.set(d.position, tempData);
        if (tempData.count > maxCount) {
          maxCount = tempData.count;
        }
        if (d.position > maxPosition) {
          maxPosition = d.position;
        }
      }
    });
    setMaxBarHeight(maxCount);
    setMaxVariantPos(maxPosition);
    return mapArray;
  }, [variantsData]);

  // set the max for domain to the greater of the proteinSequence length or the highest variant position
  const finalMax = Math.max(maxAmino, maxVariantPos + 2);

  const scaleX = scaleLinear().domain([minAmino, finalMax]).range([0, width]);
  const xReference = scaleX.copy();

  const scaleY = scaleLinear()
    .domain([0, maxBarHeight])
    .range([GRAPH_HEIGHT - STICK_HEIGHT, 0]);

  if (currentGlobalZoomState) {
    const newXScale = currentGlobalZoomState.rescaleX(xReference);
    scaleX.domain(newXScale.domain());
  }

  useEffect(() => {
    if (svgRef.current) {
      const handleZoom = (e: D3ZoomEvent<any, any>) => {
        setCurrentGlobalZoomState(e.transform);
      };

      const zoomAction = zoom<SVGSVGElement, unknown>()
        .scaleExtent([1, Math.sqrt(finalMax)])
        .translateExtent([
          [0, 0],
          [width, maxBarHeight],
        ])
        .on("zoom", handleZoom);

      // Only do this once and wait until the container is mounted and
      // width is set
      if (shouldSetInitialZoom.current && width > 0) {
        const svg = select(svgRef.current);
        const startScale = Math.sqrt(finalMax) / 2;

        // Finds the variant with the most articles and centers at that variant
        // position on graph load
        const findStartPosition = () => {
          if (!variants || variants.length === 0) return 0;
          // create a copy of our current scale
          // then update this copy to use our start scale value
          // then use this scale to determine the starting domain
          // and thus start translation centering on the top variant
          const xReference = scaleX.copy();
          const newXScale = zoomIdentity.scale(startScale).rescaleX(xReference);
          xReference.domain(newXScale.domain());
          const dom = xReference.domain();
          const len = dom[1] - dom[0];

          // find the top variant to center on
          const topVariant = [...variants].sort(
            (a, b) => b.without_mut_hits - a.with_mut_hits
          )[0];
          if (topVariant.position === undefined) return 0;

          // if centering on top pos puts domain before 0, instead set furthest
          // left point at 0
          if (topVariant.position - len / 2 < 0) return 0;
          // if centering on top pos puts domain past maxPos, set furthest left
          // point to a domain ending at the maxPos
          if (topVariant.position + len / 2 > maxVariantPos)
            return -xReference(maxVariantPos - len);

          // otherwise, just return the pos where the top article pos is centered
          return -xReference(topVariant.position - len / 2);
        };

        svg.call(zoomAction).call(
          // eslint-disable-next-line @typescript-eslint/unbound-method
          zoomAction.transform,
          zoomIdentity.translate(findStartPosition(), 0).scale(startScale)
        );

        // this ensures that we only do this once!
        shouldSetInitialZoom.current = false;
      }
    }
  }, [width, maxBarHeight, variants, finalMax, maxVariantPos]);

  // keep track of our container width so
  // we can set the widths and transforms of our graph elements
  const container = useCallback((node: HTMLDivElement) => {
    if (!node) return;
    const resizeObserver = new ResizeObserver(() => {
      setWidth(node.getBoundingClientRect().width);
    });
    resizeObserver.observe(node);
  }, []);

  return (
    <div style={{ width: "99%" }}>
      <Box sx={{ height: miniMapHeight }}>
        <div style={{ paddingTop: "20px" }}>
          <svg
            width={width + MARGIN.left + MARGIN.right}
            height={miniMapHeight - 20}
          >
            <VariantMinimap
              data={transformedData}
              maxAmino={finalMax}
              height={miniMapHeight - 20}
              width={width}
              maxBarHeight={maxBarHeight}
              zoomedDomain={scaleX.domain()}
            />
          </svg>
        </div>
      </Box>
      <div
        style={{
          borderRadius: "0px 8px 8px 0px",
          background: primaryPalette.gradients.teal.neutral_teal_t1,
          boxShadow: boxShadowStyles.genePage,
          padding: `${MARGIN.top}px ${MARGIN.right}px ${MARGIN.bottom}px ${MARGIN.left}px`,
        }}
      >
        <Box sx={{ height: GRAPH_HEIGHT }} ref={container}>
          <svg ref={svgRef} height={GRAPH_HEIGHT} width={width}>
            <g transform={`translate(${MARGIN.left}, 0)`}>
              <Bars
                data={transformedData}
                height={GRAPH_HEIGHT}
                barWidth={BAR_WIDTH}
                scaleX={scaleX}
                scaleY={scaleY}
                stickHeight={STICK_HEIGHT}
                setInteractionData={setInteractionData}
                setTooltipAnchorEl={setTooltipAnchorEl}
              />
            </g>
          </svg>
        </Box>
        <Box sx={{ height: XAXIS_HEIGHT }}>
          <svg height={XAXIS_HEIGHT} width={width}>
            <BottomAxis scale={scaleX} transition={t} tickCount={TICKS} />
          </svg>
        </Box>
        <Box
          sx={{
            borderRadius: "2px",
            border: `1px solid ${primaryPalette.gray.variant_04}`,
            marginTop: `${PROTEIN_MARGIN_TOP}px`,
            background: primaryPalette.teal.neutral_teal_t1,
          }}
        >
          <svg width={width > 2 ? width - 2 : width} height={PROTEINS_HEIGHT}>
            <g transform={`translate(0, ${PROTEIN_MARGIN_TOP})`}>
              <Proteins
                scaleX={scaleX}
                setDomainPopoverData={setDomainData}
                setTooltipAnchorEl={setTooltipAnchorEl}
              />
            </g>
          </svg>
        </Box>
      </div>
      <div
        style={{
          position: "relative",
          pointerEvents: "none",
        }}
      >
        <VariantDiagramAAPopper
          interactionData={interactionData}
          anchorEl={tooltipAnchorEl}
        />
        <ProteinDomainPopper
          interactionData={domainData}
          anchorEl={tooltipAnchorEl}
        />
      </div>
    </div>
  );
};

export default VariantHistogram;
