import { AxiosResponse } from "axios";
import { httpClient } from "./http-client";

export type MMNotification = {
  banner: boolean;
  body: string;
  id: number;
  message: string;
};

export async function fetchNotifications(): Promise<
  AxiosResponse<MMNotification[]>
> {
  return await httpClient.get("/message?ext_missing=false");
}

export async function dismissMessage(id: number): Promise<AxiosResponse> {
  return await httpClient.post<number>(`/message/dismiss`, { message_id: id });
}
