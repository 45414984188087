"use client";

import * as React from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Stack,
  Typography,
  CardActionArea,
  useMediaQuery,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../../../../../store/hooks";
import { getFeatureFlagByName } from "../../../../../../store/selectors/featureFlagSelectors";
import { primaryPalette, theme } from "../../../../../../libs/styles/theme";
import {
  AssessmentBarProps,
  VariantSubTypesDisplay,
} from "../../../../../../libs/types/card";
import { VariantSubTypes } from "../../../../../../libs/types/variant";
import { isProUser } from "../../../../../../libs/utils/user";
import { getVariantColor } from "../../../../../../libs/utils/variant";
import { IconNext } from "../../../../../common/icons/Generic";
import { LogoGenomenonSmallNoBackground } from "../../../../../common/icons/SmallGLogo";
import Paywall from "../../../../../common/paywalls";
import { RootState } from "../../../../../../store/store";
import { UrlParams } from "../../../../../../libs/types/url-params";
import { ArticlePageViews } from "../../../../../../libs/types/articles";
import { useUrlSearchParamState } from "../../../../../../libs/hooks/useSearchParamState";
import { Modals } from "../../../../../../store/slices/modalSlice";
import { useOpenSelectedModal } from "../../../../../modals/modalHooks";
import { pathogenictyIconMap } from "../../../../constants";

const CustomBox = styled(Box)(() => ({
  fontSize: "0.75rem",
  padding: "6px 4px 6px 16px",
  color: primaryPalette.gray.variant_01,
  position: "relative",
  "& .MuiBox-root": {
    position: "relative",
    minWidth: "24px",
  },
  "& .info": {
    right: "0",
    fontSize: "0.75rem",
    "& a, & a:hover, & a:focus": {
      color: primaryPalette.gray.variant_01,
      textDecorationColor: "unset",
    },
  },
  "& .request": {
    right: "0.25rem",
  },
  "& svg": {
    maxWidth: "0.875rem",
    height: "auto",
  },
  "& .LogoGenomenonSmallNoBackground": {
    position: "absolute",
    top: "0.125rem",
    left: "0.3125rem",
  },
  "& .IconNext": {
    margin: "0 0.25rem 0.063rem",
  },
  "& .IconVariantAssessment": {
    marginLeft: "0.375rem",
  },
}));

// TODO: this should rely on redux search slice urlTermIds, urlTermBooleans, urlCats, and urlSigTerms
const assessmentPageLink = (searchParams: URLSearchParams): string => {
  searchParams.delete("pmid");
  searchParams.delete("search_source");
  return `/interpretation?${searchParams.toString()}`;
};

const AssessmentBarSmall: React.FC<AssessmentBarProps> = ({
  curatedVariantData,
  evidenceViewerData,
}) => {
  const openSelectedModal = useOpenSelectedModal();
  const { user, isLoggedIn } = useAppSelector((state) => state.user);
  const curatedEvidenceIsEnabled = useSelector(
    (state: RootState) =>
      getFeatureFlagByName(state, "curated_content").treatment
  );
  const { updateSearchParams } = useUrlSearchParamState();
  const [searchParams] = useSearchParams();
  const currentArticlePageView = searchParams.get(UrlParams.VIEW);
  const isArticleViewerMode =
    currentArticlePageView === ArticlePageViews.FEATURED_ARTICLE;
  const isArticleListMode =
    currentArticlePageView === ArticlePageViews.ARTICLE_LIST;
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleAssessmentClick = () => {
    if (curatedEvidenceIsEnabled) {
      if (isLoggedIn) {
        let view = ArticlePageViews.FEATURED_ARTICLE;
        if (isArticleViewerMode || isArticleListMode) {
          view = ArticlePageViews.CURATED_EVIDENCE_VIEWER;
        } else if (isMobile) {
          view = ArticlePageViews.ARTICLE_LIST;
        } else {
          view = ArticlePageViews.FEATURED_ARTICLE;
        }

        updateSearchParams({
          [UrlParams.VIEW]: view,
        });
      } else {
        openSelectedModal(Modals.login, undefined, true);
      }
    } else {
      window.open(assessmentPageLink(searchParams), "_blank");
    }
  };

  if (curatedVariantData === undefined) {
    if (user.id === undefined) {
      return (
        <Paywall
          titleText="Basic Edition Preview"
          bodyText="Take a look inside for Genomenon and ClinVar evidence."
        />
      );
    } else if (!isProUser(user)) {
      return (
        <Paywall
          titleText="pro edition feature"
          bodyText="Take a look inside for gene or variant curated evidence"
        />
      );
    } else {
      return (
        <CustomBox
          sx={{ bgcolor: getVariantColor(VariantSubTypes.UNCURATED) }}
          data-testid="assessment-bar-small"
        >
          <Stack direction="row" gap={1}>
            <LogoGenomenonSmallNoBackground
              style={{ verticalAlign: "center" }}
            />
            <Typography
              color={primaryPalette.grey.neutral_grey_g1}
              variant="text12"
            >
              {VariantSubTypesDisplay[VariantSubTypes.UNCURATED]}
            </Typography>
          </Stack>
        </CustomBox>
      );
    }
  } else {
    const pathogenicity = curatedVariantData.acmg_call as VariantSubTypes;
    return (
      <CardActionArea
        data-testid="last-assessed-link"
        onClick={() => handleAssessmentClick()}
        sx={{
          "&:hover, &:focus": {
            filter: "grayscale(20%)",
          },
        }}
      >
        <CustomBox
          sx={{ bgcolor: getVariantColor(pathogenicity) }}
          data-testid="assessment-bar-small"
        >
          <Stack direction="row">
            <Box>
              <LogoGenomenonSmallNoBackground />
            </Box>
            <Stack>
              <Box style={{ paddingBottom: "0.125rem" }} className="info">
                {evidenceViewerData?.record?.review_date && (
                  <Typography
                    variant="text12"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      textDecoration: "underline",
                    }}
                  >
                    View Evidence Last Assessed:{" "}
                    {new Date(
                      evidenceViewerData?.record?.review_date
                    ).toLocaleDateString(undefined, {
                      year: "2-digit",
                      month: "numeric",
                      day: "numeric",
                    })}
                    <IconNext reversed />
                  </Typography>
                )}
              </Box>
              <Stack direction="row" sx={{ paddingBottom: "0.125rem" }}>
                <Typography variant="h3">
                  {VariantSubTypesDisplay[pathogenicity] ?? pathogenicity}
                </Typography>
                {pathogenictyIconMap[pathogenicity]}
              </Stack>
              <Typography variant="text12" fontWeight="fontWeightLight">
                {evidenceViewerData?.record?.disease?.display_name}
              </Typography>
            </Stack>
          </Stack>
        </CustomBox>
      </CardActionArea>
    );
  }
  return <></>;
};

export default AssessmentBarSmall;
