import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { MMNotification } from "../../network/notifications";

export interface MMNotificationState {
  userMessages: MMNotification[];
  bannerMessages: MMNotification[];
}

export const initialState: MMNotificationState = {
  userMessages: [],
  bannerMessages: [],
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setNotifications: (
      state: MMNotificationState,
      action: PayloadAction<MMNotification[]>
    ) => {
      const newBannerMessages: MMNotification[] = [];
      const newUserMessages: MMNotification[] = [];
      action.payload.forEach((message) => {
        if (message.banner) {
          newBannerMessages.push(message);
        } else {
          newUserMessages.push(message);
        }
      });

      state.userMessages = [...newUserMessages, ...state.userMessages];
      state.bannerMessages = [...newBannerMessages, ...state.bannerMessages];
    },
    dismissNotification: (
      state: MMNotificationState,
      action: PayloadAction<MMNotification>
    ) => {
      const { id } = action.payload;
      state.userMessages = state.userMessages.filter(
        (message) => message.id !== id
      );
      state.bannerMessages = state.bannerMessages.filter(
        (message) => message.id !== id
      );
    },
  },
});

export const { setNotifications, dismissNotification } =
  notificationSlice.actions;

export default notificationSlice.reducer;
