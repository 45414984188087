import React from "react";
import { Divider, Typography } from "@mui/material";
import { primaryPalette } from "../../../../../libs/styles/theme";

const ACMGCriteriaHeader = () => {
  return (
    <>
      <Typography
        variant="h5"
        color={primaryPalette.grey.neutral_grey_g7}
        fontWeight={600}
      >
        Criteria
      </Typography>
      <Divider
        variant="fullWidth"
        color={primaryPalette.grey.neutral_grey_g5}
      />
    </>
  );
};

export default ACMGCriteriaHeader;
