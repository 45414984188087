import React from "react";
import { Grid } from "@mui/material";
import ACMGCategorySection from "./ACMGCategorySection";
import { ACMGCategories } from "../../../../../libs/types/variant";
import { acmgCategoryIconMap } from "../../../constants";
import ACMGCriteriaItem from "./ACMGCriteriaItem";
import { useAppSelector } from "../../../../../store/hooks";
import { selectEvidenceViewerACMGBodyData } from "../../../../../store/selectors/curatedEvidenceSelectors";
import ACMGCategorySectionEmptyState from "./ACMGCategorySectionEmptyState";
import GnomadPopulationData from "./GnomadPopulationData";

const ACMGCriteriaBody = () => {
  const dataByACMGCategory = useAppSelector((state) =>
    selectEvidenceViewerACMGBodyData(state)
  );

  if (!dataByACMGCategory) {
    return null;
  }

  return (
    <Grid
      container
      spacing={2}
      data-testid="evidence-viewer-acmg-criteria-body"
    >
      {/* Column 1 Clinical */}
      <Grid item xs={12} sm={6} md={6} xl={3}>
        <ACMGCategorySection
          label={ACMGCategories.CLINICAL}
          icon={acmgCategoryIconMap[ACMGCategories.CLINICAL]}
        >
          {dataByACMGCategory[ACMGCategories.CLINICAL].length > 0 ? (
            <>
              {dataByACMGCategory[ACMGCategories.CLINICAL]?.map((criteria) => {
                return (
                  <ACMGCriteriaItem
                    key={`acmg-criteria-item-${criteria.acmg_call}`}
                    criteria={criteria}
                  />
                );
              })}
            </>
          ) : (
            <ACMGCategorySectionEmptyState label={ACMGCategories.CLINICAL} />
          )}
        </ACMGCategorySection>
      </Grid>

      {/* Column 2 Functional */}
      <Grid item xs={12} sm={6} md={6} xl={3}>
        <ACMGCategorySection
          label={ACMGCategories.FUNCTIONAL}
          icon={acmgCategoryIconMap[ACMGCategories.FUNCTIONAL]}
        >
          {dataByACMGCategory[ACMGCategories.FUNCTIONAL].length > 0 ? (
            <>
              {dataByACMGCategory[ACMGCategories.FUNCTIONAL]?.map(
                (criteria) => {
                  return (
                    <ACMGCriteriaItem
                      key={`acmg-criteria-item-${criteria.acmg_call}`}
                      criteria={criteria}
                    />
                  );
                }
              )}
            </>
          ) : (
            <ACMGCategorySectionEmptyState label={ACMGCategories.FUNCTIONAL} />
          )}
        </ACMGCategorySection>
      </Grid>

      {/* Column 3 */}
      <Grid container item direction="column" xs={12} md={12} xl={6}>
        {/* Top Row */}
        <Grid container item spacing={2}>
          <Grid item xs={12} md={6}>
            <ACMGCategorySection
              label={ACMGCategories.POPULATION}
              icon={acmgCategoryIconMap[ACMGCategories.POPULATION]}
            >
              {dataByACMGCategory[ACMGCategories.POPULATION].length > 0 ? (
                <>
                  {dataByACMGCategory[ACMGCategories.POPULATION]?.map(
                    (criteria) => {
                      return (
                        <ACMGCriteriaItem
                          key={`acmg-criteria-item-${criteria.acmg_call}`}
                          criteria={criteria}
                        />
                      );
                    }
                  )}
                </>
              ) : (
                <ACMGCategorySectionEmptyState
                  label={ACMGCategories.POPULATION}
                />
              )}
              <GnomadPopulationData />
            </ACMGCategorySection>
          </Grid>
          <Grid item xs={12} md={6}>
            <ACMGCategorySection
              label={ACMGCategories.COMPUTATIONAL}
              icon={acmgCategoryIconMap[ACMGCategories.COMPUTATIONAL]}
            >
              {dataByACMGCategory[ACMGCategories.COMPUTATIONAL].length > 0 ? (
                <>
                  {dataByACMGCategory[ACMGCategories.COMPUTATIONAL]?.map(
                    (criteria) => {
                      return (
                        <ACMGCriteriaItem
                          key={`acmg-criteria-item-${criteria.acmg_call}`}
                          criteria={criteria}
                        />
                      );
                    }
                  )}
                </>
              ) : (
                <ACMGCategorySectionEmptyState
                  label={ACMGCategories.COMPUTATIONAL}
                />
              )}
            </ACMGCategorySection>
          </Grid>
        </Grid>

        {/* Bottom Row */}
        <Grid item pt={2}>
          <ACMGCategorySection
            label={ACMGCategories.INTRINSIC}
            icon={acmgCategoryIconMap[ACMGCategories.INTRINSIC]}
          >
            {dataByACMGCategory[ACMGCategories.INTRINSIC].length > 0 ? (
              <>
                {dataByACMGCategory[ACMGCategories.INTRINSIC]?.map(
                  (criteria) => {
                    return (
                      <ACMGCriteriaItem
                        key={`acmg-criteria-item-${criteria.acmg_call}`}
                        criteria={criteria}
                      />
                    );
                  }
                )}
              </>
            ) : (
              <ACMGCategorySectionEmptyState label={ACMGCategories.INTRINSIC} />
            )}
          </ACMGCategorySection>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ACMGCriteriaBody;
