import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CategoryKeywordResponse } from "../../network/mm-categories";

export interface Category {
  parentKeys: string[];
  siblingKeys: string[];
  piblingKeys: string[]; //pibling: Parent Sibling -- IE Aunt/Uncle
  key: string;
  displayName: string;
  children: Category[];
  childCount: number;
  allChildKeys: string[];
  acmgClasses: string[];
  acmgCategory: boolean;
  description: string;
}
export interface DuplicateCategory {
  key: string;
  categories: string[];
}

export interface CategoryLayoutState {
  categories: Category[];
  duplicates: DuplicateCategory[];
}

const initialState: CategoryLayoutState = {
  categories: [],
  duplicates: [],
};

// Function to find duplicate keys with the same display name
const findDuplicateKeys = (
  categories: Category[]
): Record<string, string[]> => {
  const duplicateKeys: Record<string, string[]> = {};

  const traverseCategories = (category: Category[]) => {
    for (let i = 0; i < category.length; i++) {
      const value = category[i];

      if (!value.key.includes("-grp")) {
        if (value.displayName) {
          if (!duplicateKeys[value.displayName]) {
            duplicateKeys[value.displayName] = [];
          }

          duplicateKeys[value.displayName].push(
            `${value.key}, ${value.parentKeys.join(", ") || "root"}`
          );
        }
      }

      if (value.children) {
        traverseCategories(value.children);
      }
    }
  };

  traverseCategories(categories);

  // Filter out non-duplicates
  Object.keys(duplicateKeys).forEach((key) => {
    if (duplicateKeys[key].length <= 1) {
      delete duplicateKeys[key];
    }
  });

  return duplicateKeys;
};

export const categoryLayoutSlice = createSlice({
  name: "categoryLayout",
  initialState,
  reducers: {
    loadCategoryLayout: (
      state: CategoryLayoutState,
      action: PayloadAction<CategoryKeywordResponse>
    ) => {
      if (
        action.payload.childOrder !== undefined &&
        action.payload.children !== undefined
      ) {
        const newCategories = [];
        for (const key of action.payload.childOrder) {
          const categories = parseCategoryKeywordResponse(
            action.payload.children[key],
            key,
            action.payload.displayName,
            [],
            action.payload.childOrder.filter((child) => child !== key),
            []
          );

          newCategories.push(categories);
        }
        const duplicates = findDuplicateKeys(newCategories);
        const duplicateCategories: DuplicateCategory[] = Object.keys(
          duplicates
        ).map((displayName) => {
          return {
            key: displayName,
            categories: duplicates[displayName],
          };
        });
        state.duplicates = duplicateCategories;
        state.categories = newCategories;
      }
    },
  },
});

export const parseCategoryKeywordResponse = (
  catPayload: CategoryKeywordResponse,
  catKey: string,
  parentName: string,
  parentKeyList: string[],
  siblings: string[],
  piblings: string[]
) => {
  let childKeys: string[] = [];

  if (
    catPayload.childOrder !== undefined &&
    catPayload.children !== undefined
  ) {
    childKeys = catPayload.childOrder;
  }

  const category: Category = {
    allChildKeys: childKeys,
    childCount: childKeys.length,
    parentKeys: parentKeyList,
    piblingKeys: piblings,
    key: catKey,
    displayName: catPayload.displayName,
    acmgClasses: catPayload.acmgClasses ?? [],
    acmgCategory: parentName === "ACMG Interpretation",
    description: catPayload.description ?? "",
    children: [],
    siblingKeys: siblings,
  };

  if (
    catPayload.childOrder !== undefined &&
    catPayload.children !== undefined
  ) {
    const parentKeys = [...parentKeyList];
    parentKeys.push(catKey);

    for (const key of catPayload.childOrder) {
      const childCat = parseCategoryKeywordResponse(
        catPayload.children[key],
        key,
        catPayload.displayName,
        parentKeys,
        catPayload.childOrder.filter((child) => child !== key),
        siblings
      );

      category.allChildKeys = category.allChildKeys.concat(
        childCat.allChildKeys
      );

      category.children.push(childCat);
    }
  }

  return category;
};

export const { loadCategoryLayout } = categoryLayoutSlice.actions;

export default categoryLayoutSlice.reducer;
