import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: "/",
    prepareHeaders: (headers) => {
      const token = getXsrfTokenFromCookie();
      if (token) {
        headers.set("X-Xsrf-Token", token);
        headers.set("Content-Type", "application/json");
        headers.set("Accept", "application/json");
      }

      return headers;
    },
  }),
  reducerPath: "api",
  endpoints: () => ({}),
});

export function getXsrfTokenFromCookie() {
  const cookies = document.cookie.split("; ");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].split("=");
    if (cookie[0] === "XSRF-TOKEN") {
      return decodeURIComponent(cookie[1]);
    }
  }
  return null;
}
