import React from "react";
import { SvgIcon } from "@mui/material";
import { primaryPalette } from "../../../libs/styles/theme";
import { IconItem } from "../../../libs/types/icon";

export const IconBack = ({
  reversed,
  width,
  height,
  color,
  transform,
}: IconItem) => (
  <SvgIcon style={{ height: height, width: width, transform: transform }}>
    <svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.4999 5.99977C11.4999 6.20602 11.3264 6.37477 11.1108 6.37477H2.26806L5.56572 9.87399C5.71002 10.0271 5.69826 10.2645 5.53952 10.4034C5.4647 10.467 5.37095 10.4998 5.2772 10.4998C5.17162 10.4998 5.06605 10.4588 4.98939 10.3767L1.10111 6.25172C0.966297 6.10889 0.966297 5.89055 1.10111 5.74782L4.98939 1.62282C5.13447 1.46902 5.38056 1.45875 5.539 1.59718C5.69774 1.73635 5.70951 1.97358 5.5652 2.12663L2.26806 5.62477H11.111C11.3264 5.62477 11.4999 5.79352 11.4999 5.99977Z"
        fill={
          color
            ? color
            : reversed
            ? primaryPalette.gray.variant_01
            : primaryPalette.gray.variant_08
        }
      />
    </svg>
  </SvgIcon>
);

export const IconNext = ({ reversed, width, height }: IconItem) => (
  <SvgIcon style={{ height: height, width: width }}>
    <svg
      className="IconNext"
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.70675 9.89359C3.52451 9.71135 3.52451 9.41587 3.70675 9.23362L6.7101 6.23027L3.70675 3.22692C3.52451 3.04468 3.52451 2.7492 3.70675 2.56696C3.889 2.38471 4.18447 2.38471 4.36672 2.56696L8.03003 6.23027L4.36672 9.89359C4.18447 10.0758 3.889 10.0758 3.70675 9.89359Z"
        fill={
          reversed
            ? primaryPalette.gray.variant_01
            : primaryPalette.gray.variant_08
        }
      />
    </svg>
  </SvgIcon>
);

export const IconDrop = ({ reversed }: IconItem) => (
  <SvgIcon>
    <svg
      className="IconDrop"
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.13668 4.13668C2.31893 3.95444 2.61441 3.95444 2.79665 4.13668L5.8 7.14003L8.80335 4.13668C8.98559 3.95444 9.28107 3.95444 9.46332 4.13668C9.64556 4.31893 9.64556 4.61441 9.46332 4.79665L5.8 8.45997L2.13668 4.79665C1.95444 4.61441 1.95444 4.31893 2.13668 4.13668Z"
        fill={
          reversed
            ? primaryPalette.gray.variant_01
            : primaryPalette.gray.variant_08
        }
      />
    </svg>
  </SvgIcon>
);

export const IconInfo = ({ reversed, height, width }: IconItem) => (
  <SvgIcon style={{ height: height, width: width }}>
    <svg
      className="IconInfo"
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4153_14590)">
        <path
          d="M6 0C2.68594 0 0 2.68594 0 6C0 9.31406 2.68594 12 6 12C9.31406 12 12 9.31406 12 6C12 2.68594 9.31406 0 6 0ZM6 11.25C3.10547 11.25 0.75 8.89453 0.75 6C0.75 3.10547 3.10547 0.75 6 0.75C8.89453 0.75 11.25 3.10547 11.25 6C11.25 8.89453 8.89453 11.25 6 11.25ZM6 4.3125C6.31055 4.3125 6.5625 4.06078 6.5625 3.75C6.5625 3.43945 6.31055 3.1875 6 3.1875C5.68945 3.1875 5.4375 3.43828 5.4375 3.75C5.4375 4.06172 5.68828 4.3125 6 4.3125ZM7.125 8.25H6.375V5.625C6.375 5.41875 6.20625 5.25 6 5.25H5.25C5.04375 5.25 4.875 5.41875 4.875 5.625C4.875 5.83125 5.04375 6 5.25 6H5.625V8.25H4.875C4.66875 8.25 4.5 8.41875 4.5 8.625C4.5 8.83125 4.66875 9 4.875 9H7.125C7.33209 9 7.5 8.83209 7.5 8.625C7.5 8.41875 7.33125 8.25 7.125 8.25Z"
          fill={
            reversed
              ? primaryPalette.gray.variant_01
              : primaryPalette.gray.variant_09
          }
        />
      </g>
    </svg>
  </SvgIcon>
);
