import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  BooleanSearchOptions,
  Suggestion,
  SuggestionTerms,
} from "../../network/suggestions";
import { VariantResult } from "../../libs/models/variant-result";

/*
  The search slice keeps track of the search state (used to populate the search tags)
  and the url state (used to load data on the page

  When a user lands on a page, we parse the url query params and set both the search and url
  states. They should be in sync at this point.

  As a user interacts with the "search bar", they may add or remove tags and update boolean
  search operators. This will all affect the search state but NOT affect the url state.

  Once a user clicks "search" to actually execute the search, the url state will be synced with the
  search state. We will then determine what page the user should be on (based on their search) and redirect
  there if necessary or simply update the url query params if we are staying on the same page.
 */

export type SearchState = {
  cats: Categories;
  sig_terms: SignificantTerms;
  booleans: BooleanSearch;
  gene: Suggestion[];
  disease: Suggestion[];
  variant: Suggestion[];
  keyword: Suggestion[];
  hpo: Suggestion[];
  unii: Suggestion[];
  cnv: Suggestion[];
  shouldUpdateURL: boolean;
  searchHasChanged: boolean;
  editTagValue: string;
};

export type SearchBarTermsType = Pick<
  SearchState,
  "gene" | "variant" | "disease" | "hpo" | "unii" | "cnv" | "keyword"
>;

export const SearchBarTerms: Record<
  keyof SearchBarTermsType,
  keyof SearchBarTermsType
> = {
  gene: "gene",
  variant: "variant",
  disease: "disease",
  keyword: "keyword",
  hpo: "hpo",
  unii: "unii",
  cnv: "cnv",
} as const;

export type SearchBarKeywordType = Pick<SearchState, "cats" | "sig_terms">;

export const SearchBarKeywordTerms: Record<
  keyof SearchBarKeywordType,
  keyof SearchBarKeywordType
> = { cats: "cats", sig_terms: "sig_terms" } as const;

type CategoryKeyword = {
  key: string;
};

type SignificantTerm = {
  key: string;
};

export type SignificantTerms =
  | Record<string, SignificantTerm>
  | Record<string, never>;

export type Categories =
  | Record<string, CategoryKeyword>
  | Record<string, never>;

export type SearchStateKeys = keyof SearchState;

export type BooleanSearch = Record<
  keyof SearchBarTermsType,
  BooleanSearchOptions
>;

export type SearchedTerms = Record<keyof SearchBarTermsType, Suggestion[]>;
export type SearchedTermIds = Record<keyof SearchBarTermsType, string[]>;

export const initialSearchState: SearchState = {
  cats: {},
  sig_terms: {},
  booleans: {
    gene: BooleanSearchOptions.AND,
    disease: BooleanSearchOptions.OR,
    variant: BooleanSearchOptions.AND,
    keyword: BooleanSearchOptions.AND,
    hpo: BooleanSearchOptions.OR,
    unii: BooleanSearchOptions.OR,
    cnv: BooleanSearchOptions.AND,
  },
  gene: [],
  disease: [],
  variant: [],
  keyword: [],
  hpo: [],
  unii: [],
  cnv: [],
  shouldUpdateURL: false,
  searchHasChanged: false,
  editTagValue: "",
};

export type AddTagPayload = {
  shouldReplace: boolean;
  suggestion: Suggestion;
};

export type ReplaceTagPayload = {
  type: keyof SearchBarTermsType;
  suggestions: Suggestion[];
};

type SuggestionPayload = Record<keyof SearchBarTermsType, Suggestion[]>;

type BooleanPayload = Record<keyof SearchBarTermsType, BooleanSearchOptions>;

type CategoryPayload = {
  cats: Categories;
  sig_terms: SignificantTerms;
};

export type URLPayload = {
  terms: SuggestionPayload;
  suggTerms: SuggestionPayload;
  bools: BooleanPayload;
  categories: CategoryPayload;
};

export const searchSlice = createSlice({
  name: "search",
  initialState: initialSearchState,
  reducers: {
    // On page load, parse the url query params and set both the url and search states
    setPageLoadSearchState: (
      state: SearchState,
      action: PayloadAction<URLPayload>
    ) => {
      // load up suggestions
      for (const [k, val] of Object.entries(action.payload.terms)) {
        state[k as keyof SearchBarTermsType] = val;
      }
      // load up booleans
      for (const [k, v] of Object.entries(action.payload.bools)) {
        state.booleans[k as keyof SearchBarTermsType] = v;
      }
      // load up categories
      state.cats = action.payload.categories.cats;
      state.sig_terms = action.payload.categories.sig_terms;

      state.searchHasChanged = false;
    },
    // On page load, parse the url query params and set both the url and search states
    setPageLoadUrlTermsState: (
      state: SearchState,
      action: PayloadAction<{ [key in keyof SearchBarTermsType]: Suggestion[] }>
    ) => {
      for (const [k, v] of Object.entries(action.payload)) {
        state[k as keyof SearchBarTermsType] = v;
      }
    },
    setCategories: (state: SearchState, action: PayloadAction<Categories>) => {
      state.cats = action.payload;
    },
    setSignificantTerms: (
      state: SearchState,
      action: PayloadAction<SignificantTerms>
    ) => {
      state.sig_terms = action.payload;
    },
    deleteCategory: (
      state: SearchState,
      action: PayloadAction<CategoryKeyword>
    ) => {
      const newCats = state.cats;
      delete newCats[action.payload.key];
      state.cats = newCats;
    },
    deleteSignificantTerm: (
      state: SearchState,
      action: PayloadAction<SignificantTerm>
    ) => {
      const newSigTerms = state.sig_terms;
      delete newSigTerms[action.payload.key];
      state.sig_terms = newSigTerms;
    },
    // Add a search term tag to the search state
    addTag: (state: SearchState, action: PayloadAction<AddTagPayload>) => {
      const sugg = action.payload.suggestion;
      if (action.payload.shouldReplace) {
        const suggToReplace = { ...state[sugg.type][0] };
        state[sugg.type] = [sugg];
        // if we replaced a gene, we need to remove any variants related to the original gene
        if (sugg.type === SuggestionTerms.gene) {
          const variantTerms = state[SuggestionTerms.variant];
          const updatedVariantTerms = variantTerms.filter(
            (s) => s.gene !== VariantResult.geneCasing(suggToReplace.id)
          );
          state[SuggestionTerms.variant] = updatedVariantTerms;
        }

        // if we added a variant, we need to remove any genes NOT related to the variant
        if (sugg.type === SuggestionTerms.variant) {
          const geneTerms = state[SuggestionTerms.gene];
          const updatedGeneTerms = geneTerms.filter((s) => s.id === sugg.gene);
          state[SuggestionTerms.gene] = updatedGeneTerms;
        }
      } else {
        // check that tag is not already in list of terms
        const terms = state[sugg.type];
        if (!terms.some((s) => s.id === sugg.id)) {
          state[sugg.type] = [...state[sugg.type], sugg];
        }
      }
      state.editTagValue = "";
    },
    // Remove a search term tag to the search state
    removeTag: (state: SearchState, action: PayloadAction<Suggestion>) => {
      const terms = state[action.payload.type];
      const updatedTerms = terms.filter((s) => s.id !== action.payload.id);
      state[action.payload.type] = updatedTerms;

      // if we removed a gene, we need to remove any variants related to the gene
      if (action.payload.type === SuggestionTerms.gene) {
        const variantTerms = state[SuggestionTerms.variant];
        const updatedVariantTerms = variantTerms.filter(
          (s) => s.gene !== action.payload.text
        );
        state[SuggestionTerms.variant] = updatedVariantTerms;
      }
    },
    replaceVariantTags: (
      state: SearchState,
      action: PayloadAction<Suggestion[]>
    ) => {
      state["variant"] = action.payload;
      state.searchHasChanged = true;
    },
    // Swap the bool search operator in the search state
    swapTermBool: (
      state: SearchState,
      action: PayloadAction<keyof SearchBarTermsType>
    ) => {
      if (state.booleans[action.payload] === BooleanSearchOptions.AND) {
        state.booleans[action.payload] = BooleanSearchOptions.OR;
      } else {
        state.booleans[action.payload] = BooleanSearchOptions.AND;
      }
    },
    executeSearch: (state: SearchState) => {
      state.shouldUpdateURL = true;
      state.searchHasChanged = false;
    },
    resetShouldUpdateURL: (state: SearchState) => {
      state.shouldUpdateURL = false;
    },
    // set edit tag text value and remove tag from search terms list
    updateEditTagValue: (
      state: SearchState,
      action: PayloadAction<Suggestion>
    ) => {
      const terms = state[action.payload.type];
      const updatedTerms = terms.filter((s) => s.id !== action.payload.id);
      state[action.payload.type] = updatedTerms;
      state.editTagValue = action.payload.text;
    },
    updatedSearchChanged: (
      state: SearchState,
      action: PayloadAction<boolean>
    ) => {
      state.searchHasChanged = action.payload;
    },
  },
});

export const {
  addTag,
  removeTag,
  replaceVariantTags,
  swapTermBool,
  setPageLoadSearchState,
  setPageLoadUrlTermsState,
  setCategories,
  setSignificantTerms,
  deleteCategory,
  deleteSignificantTerm,
  executeSearch,
  resetShouldUpdateURL,
  updateEditTagValue,
  updatedSearchChanged,
} = searchSlice.actions;

export default searchSlice.reducer;
