import { SvgIcon } from "@mui/material";
import React from "react";

export function PathogenicIcon({
  height,
  width,
}: {
  height?: string;
  width?: string;
}) {
  return (
    <SvgIcon style={{ height: height, width: width }}>
      <svg viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5 2.25C5.20709 2.25 5.375 2.08209 5.375 1.875C5.375 1.66781 5.20625 1.5 5 1.5C4.79375 1.5 4.625 1.66781 4.625 1.875C4.625 2.08219 4.79375 2.25 5 2.25ZM8.825 2.34375C8.72905 2.30346 8.61992 2.34522 8.5782 2.44116C8.53719 2.53563 8.5804 2.64623 8.67561 2.68795C8.94922 2.80781 9.125 3.07734 9.125 3.375V10.875C9.125 11.2889 8.78891 11.625 8.375 11.625H1.625C1.21109 11.625 0.875 11.2889 0.875 10.875V3.375C0.875 3.07758 1.05151 2.80805 1.32547 2.68805C1.41995 2.64703 1.46316 2.53643 1.42142 2.44125C1.38031 2.34609 1.27203 2.30203 1.17453 2.34609C0.765078 2.52422 0.5 2.90859 0.5 3.375V10.875C0.5 11.4961 1.00461 12 1.625 12H8.375C8.99539 12 9.5 11.4954 9.5 10.875V3.375C9.5 2.92734 9.23516 2.52187 8.825 2.34375ZM2.75 3.75H7.25C7.66414 3.75 8 3.41414 8 3V2.6625C8 2.02055 7.47969 1.5 6.8375 1.5C6.64297 0.644063 5.90703 0 5 0C4.09297 0 3.33594 0.644063 3.1625 1.5C2.52055 1.5 2 2.02055 2 2.6625V3C2 3.41484 2.33586 3.75 2.75 3.75ZM2.375 2.6625C2.375 2.22773 2.72773 1.875 3.1625 1.875H3.46906L3.51233 1.66186C3.62919 1.08623 4.03428 0.57975 4.6017 0.427875C5.46725 0.196289 6.30233 0.752015 6.46944 1.57467L6.53047 1.875H6.83703C7.25234 1.875 7.625 2.22773 7.625 2.6625V3C7.625 3.20709 7.45714 3.375 7.25 3.375H2.75C2.54281 3.375 2.375 3.20625 2.375 3V2.6625ZM4.76094 10.7917H5.32146C5.52855 10.7917 5.69646 10.6238 5.69646 10.4167V8.15526H7.95833C8.16543 8.15526 8.33333 7.98735 8.33333 7.78026V7.21927C8.33333 7.01213 8.16543 6.84427 7.95833 6.84427H5.69693V4.58333C5.69693 4.37619 5.52902 4.20833 5.32193 4.20833H4.76094C4.55384 4.20833 4.38594 4.37619 4.38594 4.58333V6.84474H2.125C1.91791 6.84474 1.75 7.0126 1.75 7.21974V7.78026C1.75 7.98735 1.91791 8.15526 2.125 8.15526H4.38641V10.4167C4.38594 10.6229 4.53359 10.7917 4.76094 10.7917Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
}
