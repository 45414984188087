import { Menu } from "@mui/material";
import React from "react";

interface NavbarDropdownProps {
  handleClose: () => void;
  open: boolean;
  anchorEl: null | HTMLElement;
  testid?: string;
  children?: React.ReactNode;
}

const NavBarDropdown = ({
  handleClose,
  open,
  anchorEl,
  testid = "nav-dropdown",
  children = null,
}: NavbarDropdownProps) => {
  return (
    <Menu
      data-testid={testid}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          overflowY: "auto",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mr: 5,
          mt: 1.5,
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      {children}
    </Menu>
  );
};

export default NavBarDropdown;
