import React from "react";
import { Link, Typography } from "@mui/material";
import { primaryPalette } from "../../../../../libs/styles/theme";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

type ACMGCriteriaGenomenonLinkProps = {
  projectType: string;
};

const ACMGCriteriaGenomenonLink = ({
  projectType,
}: ACMGCriteriaGenomenonLinkProps) => {
  return (
    <Typography variant="text11" fontWeight="bold">
      <Link
        target="_blank"
        href={`/reporter/docs?type=${projectType}`}
        style={{
          display: "flex",
          alignItems: "center",
          color: primaryPalette.grey.neutral_grey_g1,
          textDecorationColor: primaryPalette.grey.neutral_grey_g1,
        }}
      >
        Genomenon Sequence Variant Interpretation Standards
        <OpenInNewIcon fontSize="inherit" style={{ marginLeft: 4 }} />
      </Link>
    </Typography>
  );
};

export default ACMGCriteriaGenomenonLink;
