import { Icon } from "@mui/material";
import React from "react";

export function MastermindIcon() {
  return (
    <Icon style={{ display: "flex", alignItems: "center" }}>
      <img src="../../assets/mastermind_icon_white.svg" />
    </Icon>
  );
}
