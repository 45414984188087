import { Stack, Typography } from "@mui/material";
import { has } from "lodash";
import React from "react";
import { getBackgroundColor } from "../../../../../../../libs/utils/acmg";
import { useAppSelector } from "../../../../../../../store/hooks";
import ACMGChip from "../../../../../../common/chips/ACMGChip";
import {
  curatedDataCriteriaHeaders,
  NA_CRITERIA_LIST,
  UNWEIGHTED_CRITERIA_EXCLUDE_LIST,
  UNWEIGHTED_CRITERIA_LABEL,
} from "../../../../../constants";
import { EvidenceViewerArticleCardFooterDiscreteDataItemProps } from "../types";

const CuratedDataCriteriaHeader = ({
  discreteData,
}: EvidenceViewerArticleCardFooterDiscreteDataItemProps) => {
  const { acmgClasses } = useAppSelector((state) => state.acmgClasses);
  const acmg_call = discreteData.acmg_call;
  const isNACriteria = NA_CRITERIA_LIST.includes(acmg_call);
  const headerKey =
    acmg_call.toLowerCase() as keyof typeof curatedDataCriteriaHeaders;
  const acmgChipText = UNWEIGHTED_CRITERIA_EXCLUDE_LIST.includes(acmg_call)
    ? UNWEIGHTED_CRITERIA_LABEL
    : acmg_call;

  return (
    <Stack direction="row" alignItems="center" gap="3px" mb="2px">
      {/* Header */}
      {isNACriteria ? (
        <Typography variant="text11Emphasis">
          {curatedDataCriteriaHeaders[headerKey]}
        </Typography>
      ) : (
        <>
          {has(
            curatedDataCriteriaHeaders,
            discreteData.acmg_call.toLowerCase()
          ) ? (
            <Typography variant="text11Emphasis">
              {curatedDataCriteriaHeaders[headerKey]} contributes to
            </Typography>
          ) : null}
          {/* ACMG Call chip */}
          {acmgClasses && discreteData.acmg_call ? (
            <ACMGChip
              bgcolor={getBackgroundColor(
                acmgClasses[discreteData.acmg_call.toUpperCase()]
              )}
              category={acmgChipText}
              strength={discreteData.strength ?? ""}
            />
          ) : null}
        </>
      )}
    </Stack>
  );
};

export default CuratedDataCriteriaHeader;
