import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Categories, SignificantTerms } from "./searchSlice";

export type CategoryState = {
  userSelectedCategories: Categories;
  userSelectedSigTerms: SignificantTerms;
};

export const initialCategoryState: CategoryState = {
  userSelectedCategories: {},
  userSelectedSigTerms: {},
};

export type FilterCategoryPayload = {
  categories: Categories;
  sigTerms: SignificantTerms;
};

export const categorySlice = createSlice({
  name: "category",
  initialState: initialCategoryState,
  reducers: {
    setPageLoadCategoryState: (
      state: CategoryState,
      action: PayloadAction<FilterCategoryPayload>
    ) => {
      state.userSelectedCategories = action.payload.categories;
      state.userSelectedSigTerms = action.payload.sigTerms;
    },
    setUserSelectedCategories: (
      state: CategoryState,
      action: PayloadAction<Categories>
    ) => {
      state.userSelectedCategories = action.payload;
    },
    setUserSelectedSigTerms: (
      state: CategoryState,
      action: PayloadAction<SignificantTerms>
    ) => {
      state.userSelectedSigTerms = action.payload;
    },
    resetFilterState: (
      state: CategoryState,
      action: PayloadAction<FilterCategoryPayload>
    ) => {
      state.userSelectedSigTerms = action.payload.sigTerms;
      state.userSelectedCategories = action.payload.categories;
    },
  },
});

export const {
  setPageLoadCategoryState,
  setUserSelectedCategories,
  setUserSelectedSigTerms,
  resetFilterState,
} = categorySlice.actions;

export default categorySlice.reducer;
