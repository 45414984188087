import React, { useState } from "react";
import { Box, Button, Grid, Link, Typography } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { primaryPalette } from "../../../../libs/styles/theme";
import { SummaryText } from "../SummaryText/SummaryText";
import { SummaryCriteria } from "../SummaryCriteria/SummaryCriteria";
import { SummaryFootnotes } from "../SummaryFootnotes/SummaryFootnotes";
import Skeleton from "@mui/material/Skeleton";
import { SummaryPositions } from "../SummaryPositions/SummaryPositions";
import { AcmgCriteria } from "../../../../libs/types/gene";
import { useGetGeneInfoQuery } from "../../../../network/genes";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useAppSelector } from "../../../../store/hooks";
import { SearchBarTerms } from "../../../../store/slices/searchSlice";
import { selectUrlTermIds } from "../../../../store/selectors/urlSelectors";

export const chromosomeSubheader = "Chromosome";
export const defaultValue = "n/a";
export const proteinSubheader = "Protein shift";
export const synonymsSubheader = "Also known as";
export const transcriptSubheader = "Transcript";

const footNoteCount = (acmgCriteria: AcmgCriteria) => {
  return Object.keys(acmgCriteria)
    .map((key) => {
      return acmgCriteria[key].length;
    })
    .reduce((x, y) => {
      return x + y;
    });
};

export const hasCriteria = (acmgCriteria: AcmgCriteria) => {
  return footNoteCount(acmgCriteria) > 0;
};

interface SummaryProps {
  isModal?: boolean;
}

// TODO: Get data and display data for protein shift
export function Summary({ isModal = false }: SummaryProps) {
  const urlTermIds = useAppSelector((state) => selectUrlTermIds(state));
  const gene = urlTermIds[SearchBarTerms.gene][0];

  const {
    data: geneData,
    isLoading,
    isError,
    isUninitialized,
  } = useGetGeneInfoQuery(
    gene
      ? {
          gene: gene,
        }
      : skipToken
  );
  const formatSynonyms = !geneData?.gene_synonyms
    ? defaultValue
    : geneData?.gene_synonyms.join("; ");
  const cytoLocation = () => {
    if (!geneData?.map_location || geneData?.map_location.length === 0)
      return defaultValue;
    return geneData?.map_location[0];
  };
  const maxChars = 280;
  const [expanded, setExpanded] = useState(false);

  const toggleDescription = () => {
    setExpanded(!expanded);
  };

  const summaryGridCols = () => {
    if (
      geneData?.acmg_criteria?.data &&
      hasCriteria(geneData?.acmg_criteria?.data) &&
      isModal
    ) {
      return 6;
    } else {
      return 12;
    }
  };

  const summaryCriteria = () => {
    if (
      geneData?.acmg_criteria?.data &&
      hasCriteria(geneData?.acmg_criteria?.data)
    ) {
      return (
        <Grid item xs={summaryGridCols()} sx={{ inlineSize: "min-content" }}>
          <SummaryCriteria isModal={isModal} />
        </Grid>
      );
    } else {
      return <></>;
    }
  };

  if (isUninitialized || isLoading) {
    return <Skeleton height={"500px"} width={"100%"} />;
  }
  if (isError) return <></>;

  return (
    <Box
      aria-label="gene-summary"
      mt={3}
      mx="auto"
      py={3}
      px={4}
      borderRadius="3px"
      sx={{ backgroundColor: primaryPalette.teal.neutral_teal_t1 }}
      data-testid="gene-summary"
    >
      <Grid container spacing={isModal ? 6 : 0}>
        <Grid item xs={summaryGridCols()} sx={{ inlineSize: "min-content" }}>
          <SummaryText isModal={isModal} />

          <Grid container spacing={3} pt={3}>
            <Grid item xs={12} sx={{ inlineSize: "min-content" }}>
              <Typography component="div" variant="contrastText">
                {synonymsSubheader}
              </Typography>

              {formatSynonyms && formatSynonyms.length > maxChars ? (
                <div>
                  <Typography component="div" variant="text12">
                    {expanded
                      ? formatSynonyms
                      : `${formatSynonyms.slice(0, maxChars)}...`}
                  </Typography>
                  <Grid item xs={12}>
                    <Button
                      onClick={toggleDescription}
                      variant="text"
                      sx={{
                        fontSize: "0.6rem",
                        fontWeight: 600,
                        padding: 0,
                        justifyContent: "left",
                        "&:hover": {
                          background: "none",
                        },
                      }}
                    >
                      {expanded ? "LESS" : "MORE"}
                    </Button>
                  </Grid>
                </div>
              ) : (
                <Typography component="div" variant="text12">
                  {formatSynonyms || defaultValue}
                </Typography>
              )}
            </Grid>
            <Grid item xs={6}>
              <Typography component="div" variant="contrastText">
                {transcriptSubheader}
              </Typography>
              <Typography component="div" variant="text12">
                {geneData?.transcript ?? defaultValue}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography component="div" variant="contrastText">
                {chromosomeSubheader}
              </Typography>
              <Typography component="div" variant="text12">
                {cytoLocation()}
              </Typography>
            </Grid>
          </Grid>

          <SummaryPositions
            nameHeader={"Domains"}
            summaries={geneData?.domains ?? []}
          />
          <SummaryPositions
            nameHeader={"Protein shift"}
            summaries={geneData?.protein_shifts ?? []}
          />

          {gene ? (
            <Box mt={3}>
              <Typography variant="contrastText">
                Genotype-Tissue Expression Portal
              </Typography>
              <Link
                href={`https://gtexportal.org/home/gene/${gene}`}
                target="_blank"
                sx={{
                  fontSize: "12px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                GTEx - {gene.toUpperCase()}
                <OpenInNewIcon fontSize="inherit" style={{ marginLeft: 8 }} />
              </Link>
            </Box>
          ) : null}
        </Grid>
        {summaryCriteria()}
      </Grid>

      {isModal && <SummaryFootnotes />}
    </Box>
  );
}
