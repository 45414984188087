import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { SearchedTermIds } from "../slices/searchSlice";
import { Suggestion } from "../../network/suggestions";

export const selectUrlVariantSuggestions = (state: RootState) =>
  state.url.urlTermSuggestions.variant;

const selectUrlTermSuggestions = (state: RootState) =>
  state.url.urlTermSuggestions;

export const selectUrlTermIds = createSelector(
  selectUrlTermSuggestions,
  (urlTermSuggestions) => {
    return Object.keys(urlTermSuggestions).reduce((acc, key) => {
      acc[key as keyof SearchedTermIds] = urlTermSuggestions[
        key as keyof SearchedTermIds
      ].map((suggestion: Suggestion) => suggestion.id);
      return acc;
    }, {} as SearchedTermIds);
  }
);
