import { Typography, Button, Menu, MenuItem } from "@mui/material";
import React from "react";
import { primaryPalette } from "../../../../../libs/styles/theme";
import { VariantIcon } from "../../../../common/icons/VariantIcon";
import {
  GeneMatchElement,
  updateSelectedVariantMatch,
} from "../../../../../store/slices/featuredArticleSlice";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import {
  isCNomenclature,
  isRSID,
} from "../../../../../libs/utils/strings/regex";
import { selectVariantCount } from "../../../../../store/selectors/featuredArticleSelectors";
import { SearchBarTerms } from "../../../../../store/slices/searchSlice";
import { selectUrlTermIds } from "../../../../../store/selectors/urlSelectors";

const VariantMatch = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useAppDispatch();
  const { variant: searchVariant } = useAppSelector((state) => state.search);
  const urlTermIds = useAppSelector((state) => selectUrlTermIds(state));
  const variantMatch = useAppSelector(
    (state) => state.featuredArticle.selectedVariantMatch
  );
  const variantCount = useAppSelector((state) => selectVariantCount(state));

  const variantMatchElements: GeneMatchElement[] = [
    ...urlTermIds[SearchBarTerms.variant].map<GeneMatchElement>((variant) => {
      return {
        id: variant,
        label: variant.toUpperCase(),
      };
    }),
    ...urlTermIds[SearchBarTerms.gene].map<GeneMatchElement>((gene) => {
      return {
        id: `all_${gene.toLowerCase()}_variants`,
        label: `All ${gene.toUpperCase()} Variants`,
        gene: gene.toLowerCase(),
      };
    }),
    {
      id: "all_variants",
      label: "All Variants",
    },
  ];

  const rsid = isRSID(urlTermIds[SearchBarTerms.variant]);
  const cNomenclature = isCNomenclature(urlTermIds[SearchBarTerms.variant]);

  if (rsid || cNomenclature) {
    const keys = searchVariant.map((v) => (v.keys ? v.keys : [])).flat();
    variantMatchElements.splice(
      0,
      1,
      ...keys.map<GeneMatchElement>((v) => ({
        id: v,
        label: v.toUpperCase(),
      }))
    );
  }

  // set selected variant value on page load to fetch count
  React.useEffect(() => {
    dispatch(updateSelectedVariantMatch(variantMatchElements[0]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <VariantIcon height={"14px"} width={"14px"} />
      &nbsp;
      <Typography
        variant="h6"
        color={primaryPalette.teal.neutral_teal_t4}
        data-testid="variant-match-label"
      >
        Variant Matches:
      </Typography>
      <Button
        onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
          handleClick(event)
        }
        data-testid="variant-match-button"
      >
        <Typography
          data-testid="variant-match-count"
          variant="text12"
          color={primaryPalette.grey.neutral_grey_g7}
        >
          {variantMatch?.label}: {variantCount}
        </Typography>
      </Button>
      <Menu
        id="variant-match-menu"
        data-testid="variant-match-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {variantMatchElements.map((element) => {
          return (
            <MenuItem
              key={element.id}
              data-testid={`variant-match-menu-item-${element.id}`}
              onClick={() => {
                dispatch(updateSelectedVariantMatch(element));
                handleClose();
              }}
            >
              <Typography
                variant="text12"
                color={primaryPalette.grey.neutral_grey_g7}
              >
                {element.label}
              </Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default VariantMatch;
