import { styled, Typography } from "@mui/material";
import { primaryPalette } from "../../../libs/styles/theme";

export const ArticleViewerText = styled(Typography)(() => ({
  color: primaryPalette.grey.neutral_grey_g7,
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "normal",
}));
