import React, { ReactNode } from "react";
import ModalBox from "../../common/ModalBox";
import { Link, Typography } from "@mui/material";
import { primaryPalette } from "../../../libs/styles/theme";
import { MastermindIcon } from "../../common/icons/MastermindIcon";

const style = {
  borderRadius: "15px",
  boxShadow: 24,
  bgcolor: primaryPalette.teal.neutral_teal_t2,
  gap: "20px",
  display: "block",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  overflowY: "auto",
  height: "75%",
  width: "75%",
};

interface FrameProps {
  children: ReactNode;
}

const ModalFrame = ({ children }: FrameProps) => {
  return (
    <ModalBox data-testid={"paywall-modal"} style={style}>
      <div
        style={{
          padding: "4px",
          backgroundColor: primaryPalette.blue.variant_01,
        }}
      >
        <Link
          href={"/"}
          underline="none"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 10,
          }}
        >
          <Typography
            variant={"h4"}
            sx={{ color: primaryPalette.teal.neutral_teal_t1 }}
          >
            Mastermind
          </Typography>
          <MastermindIcon />
        </Link>
      </div>
      {children}
    </ModalBox>
  );
};

export default ModalFrame;
