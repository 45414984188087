import { httpClient } from "./http-client";
import {
  BooleanSearch,
  initialSearchState,
  SearchBarTerms,
  SearchedTermIds,
} from "../store/slices/searchSlice";

export type CategoryCountResponse = Array<CategoryCount>;

export type CategoryCount = {
  term: string;
  hits?: number;
};

export const emptyCounts: CategoryCountResponse = [];

export type GetCategoryCountsReqBody = {
  boolean: boolean;
  article_sort: string;
  article_sort_asc: boolean;
  mutation_source: string;
  cnv_match_op: string;
  article_list_filter: string;
  offset: number;
  gene: string[];
  mutation: string[];
  disease: string[];
  keyword: string[];
  hpo: string[];
  unii: string[];
  cnv: string[];
  gene_op: string;
  disease_op: string;
  mutation_op: string;
  keyword_op: string;
  hpo_op: string;
  unii_op: string;
  cnv_op: string;
  category: string;
};

export async function getCategoryCounts(
  urlSearchParams: SearchedTermIds,
  urlBooleanParams: BooleanSearch,
  category: string
): Promise<CategoryCountResponse> {
  // TODO: This is similar to the payload we send for mutations.  We might want to extract this and that somehow
  const json = JSON.stringify({
    boolean: true,
    article_sort: "relevance",
    article_sort_asc: false,
    mutation_source: "supplemental",
    cnv_match_op: "intersects",
    article_list_filter: "",
    offset: 0,
    gene: urlSearchParams[SearchBarTerms.gene],
    mutation: urlSearchParams[SearchBarTerms.variant],
    disease: urlSearchParams[SearchBarTerms.disease],
    keyword: urlSearchParams[SearchBarTerms.keyword],
    hpo: urlSearchParams[SearchBarTerms.hpo],
    unii: urlSearchParams[SearchBarTerms.unii],
    cnv: urlSearchParams[SearchBarTerms.cnv],
    gene_op:
      urlBooleanParams[SearchBarTerms.gene] ??
      initialSearchState.booleans[SearchBarTerms.gene],
    disease_op:
      urlBooleanParams[SearchBarTerms.disease] ??
      initialSearchState.booleans[SearchBarTerms.disease],
    mutation_op:
      urlBooleanParams[SearchBarTerms.variant] ??
      initialSearchState.booleans[SearchBarTerms.variant],
    keyword_op:
      urlBooleanParams[SearchBarTerms.keyword] ??
      initialSearchState.booleans[SearchBarTerms.keyword],
    hpo_op:
      urlBooleanParams[SearchBarTerms.hpo] ??
      initialSearchState.booleans[SearchBarTerms.hpo],
    unii_op:
      urlBooleanParams[SearchBarTerms.unii] ??
      initialSearchState.booleans[SearchBarTerms.unii],
    cnv_op:
      urlBooleanParams[SearchBarTerms.cnv] ??
      initialSearchState.booleans[SearchBarTerms.cnv],
    category: category,
  });

  const { data } = await httpClient.post<CategoryCountResponse>(
    "/keywords",
    json
  );
  return data;
}
