import React, { useState } from "react";
import {
  SignificantCount,
  SignificantCountResponse,
} from "../../../network/mm-significant-counts";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { isProUser } from "../../../libs/utils/user";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import ProWarningHeader from "../ProWarningHeader";
import { SignificantTerms } from "../../../store/slices/searchSlice";
import Grid from "@mui/material/Unstable_Grid2";
import { setUserSelectedSigTerms } from "../../../store/slices/filterSlice";

export default function SigTermModal(props: {
  terms: SignificantCountResponse;
  handleSelection: () => void;
  handleCancel: () => void;
}) {
  const { handleSelection, handleCancel, terms } = props;
  const { user } = useAppSelector((state) => state.user);
  const selectedSigTerms = useAppSelector(
    (state) => state.filter.userSelectedSigTerms
  );
  const [currentTerms, setCurrentTerms] = useState<SignificantTerms>({
    ...selectedSigTerms,
  });
  const dispatch = useAppDispatch();

  const handleDone = () => {
    dispatch(setUserSelectedSigTerms(currentTerms));
    handleSelection();
  };

  const handleTermSelection = (
    term: SignificantCount,
    target: HTMLInputElement
  ) => {
    const terms = { ...currentTerms };
    const shouldSelect = target.checked;

    if (shouldSelect) {
      terms[term.term] = {
        key: term.term,
      };
    } else {
      delete terms[term.term];
    }

    setCurrentTerms(terms);
  };

  const selectedTerm = (sigTerm: SignificantCount) => {
    return currentTerms[sigTerm.term] !== undefined;
  };

  const doneCancelButtons = () => {
    return (
      <Grid container columns={2}>
        <Grid xs={1}>
          <Button
            color={"secondary"}
            variant="contained"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </Grid>
        <Grid xs={1}>
          <Button
            sx={{ float: "right", marginRight: "10px" }}
            color={"primary"}
            variant="contained"
            onClick={handleDone}
            disabled={!isProUser(user)}
          >
            Done
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Box>
      <Box
        sx={{
          background: "#FFFFFF",
          padding: "1px 20px 10px",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        <ProWarningHeader />
        <Typography variant={"h4"}>Significant Terms in Abstract</Typography>
        <br />
        <Grid container columns={3}>
          {terms.map((term) => {
            return (
              <Grid key={`significantTerm-${term.term}`} xs={1}>
                <Box
                  sx={{
                    borderBottom: "1px solid #000000",
                    padding: "5px 0px",
                    marginLeft: "15px",
                    marginRight: "15px",
                    marginBottom: "5px",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={!isProUser(user)}
                        data-testid={`${term.term}-checkbox`}
                        checked={selectedTerm(term)}
                        onChange={(event: React.ChangeEvent) =>
                          handleTermSelection(
                            term,
                            event.target as HTMLInputElement
                          )
                        }
                      />
                    }
                    label={`${term.hits} ${term.term}`}
                  />
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <Box
        sx={{
          padding: "10px",
          position: "sticky",
          bottom: "0px",
          bgcolor: "#DDDDDD",
        }}
      >
        {doneCancelButtons()}
      </Box>
    </Box>
  );
}
