import { Skeleton, TableCell, TableRow } from "@mui/material";
import React from "react";

export function TableSkeletonLoader({
  rowsNum,
  cellNum,
}: {
  rowsNum: number;
  cellNum: number;
}) {
  return Array.from(Array(rowsNum)).map((_row, index) => (
    <TableRow key={index}>
      {Array.from(Array(cellNum)).map((_cell, index) => (
        <TableCell key={index} component="th" scope="row">
          <Skeleton animation="wave" variant="text" />
        </TableCell>
      ))}
    </TableRow>
  ));
}
