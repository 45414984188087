import { SearchExampleData } from ".";

// When we update these also update the robot config/sitemap_genes.txt
export const freeCuratedGenes = [
  "PIK3R1",
  "PIK3CD",
  "ABCC6",
  "ENPP1",
  "ALPL",
  "C3",
  "CD46",
  "CFB",
  "CFH",
  "CFI",
  "DGKE",
  "THBD",
];

export const searchExamples: SearchExampleData[] = [
  {
    title: "Search Mastermind using different nomenclatures:",
    examples: [
      {
        searchType: "all",
        href: "/articles?gene=enpp1&mutation=enpp1%3Ac.795%2B1G%3EA&gene_op=and&mutation_op=and&pmid=22209248",
        label: "cDNA nomenclature ENPP1:c.795+1G>A",
      },
      {
        searchType: "all",
        href: "/articles?gene=cfb&mutation=cfb%3AK323E&gene_op=and&mutation_op=and&pmid=34721423",
        label: "Protein nomenclature CFB:p.K323E",
      },
      {
        searchType: "all",
        href: "/articles?mutation=NC_000001.11%3Ag.21564139G%3EA&mutation_op=and&pmid=35878747",
        label: "Genomic Coordinates NC_000001.11:g.21564139G>A",
      },
      {
        searchType: "all",
        href: "/articles?mutation=rs312262695&mutation_op=and&pmid=38374836",
        label: "rsID rs312262695",
      },
    ],
  },
  {
    title:
      "Explore our gene summary, protein diagram, variant table, and more using our Gene Pages:",
    examples: [
      {
        searchType: "all",
        href: "/gene?gene=apob&gene_op=and",
        label: "APOB",
      },
      {
        searchType: "all",
        href: "/gene?gene=braf&gene_op=and",
        label: "BRAF",
      },
    ],
  },
  {
    title: "Or if you're pro, try multiple search features at once:",
    examples: [
      {
        searchType: "Pro",
        href: "/articles?gene=egfr&mutation=egfr%3AL858R&cnv=amp%3A7%3A116672358-116798386&disease=D008175&unii=UNII%3AJ4T82NDH7E&cats=drug+resistance&cats=resistance&gene_op=and&mutation_op=and&cnv_op=and&disease_op=and&unii_op=and&pmid=36765799",
        label:
          "MET amplification + EGFR:p.L858R + Erlotinib + Lung Neoplasms + drug resistance + resistance",
      },
    ],
  },
];
