export type User = {
  id: number;
  email: string;
  name: string;
  first_name: string;
  last_name: string;
  user_role_names: Array<UserRoles>;
  is_pro: boolean;
  eula_accepted: boolean;
  needs_tour: boolean;
  completed_tour: boolean;
  ref_id: string;
  has_projects: boolean;
  company_name: string;
  subscription_description: string;
  subscription_type: string;
  config: unknown;
  user_type: string;
  searches_left?: number;
  trial_end?: string;
  license_expiration?: string;
  last_timed_trial_request: string | null;
  total_timed_trial_requests: number;
};

export enum UserRoles {
  ADMIN = "admin",
  BASIC = "basic",
  TRIAL = "trial",
  TIMED_TRIAL = "timed_trial",
  EXPIRED = "expired",
  SUSPENDED = "suspended",
  PAID = "paid",
  PILOT = "pilot",
  RESELLER_PILOT = "reseller_pilot",
  DELINQUENT = "delinquent",
}

export enum UserTypes {
  INACTIVE = "Inactive",
  CONCURRENT = "Concurrent",
  BASIC = "Basic",
  INTRODUCTORY = "Introductory",
  TRIAL = "Trial",
  TIMED_TRIAL = "Timed Trial",
  EMPLOYEE = "Employee",
  PROFESSIONAL = "Professional",
  UNKNOWN = "Unknown",
}
