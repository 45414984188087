import React from "react";
import { SvgIcon } from "@mui/material";

export function Popup() {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
      >
        <g clipPath="url(#clip0_796_35406)">
          <path
            d="M0 2.25C0 1.42148 0.671484 0.75 1.5 0.75H10.5C11.3273 0.75 12 1.42148 12 2.25V9.75C12 10.5773 11.3273 11.25 10.5 11.25H1.5C0.671484 11.25 0 10.5773 0 9.75V2.25ZM0.75 2.25V4.5H11.25V2.25C11.25 1.83586 10.9148 1.5 10.5 1.5H1.5C1.08586 1.5 0.75 1.83586 0.75 2.25ZM0.75 5.25V9.75C0.75 10.1648 1.08586 10.5 1.5 10.5H10.5C10.9148 10.5 11.25 10.1648 11.25 9.75V5.25H0.75Z"
            fill="#262626"
          />
        </g>
        <defs>
          <clipPath id="clip0_796_35406">
            <rect width="12" height="12" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}
