import { ZodError, ZodType } from "zod";
import { fromError } from "zod-validation-error";
import logError from "./log-error";

type TypeOf<T extends ZodType<any, any, any>> = T["_output"];

export function validate<T extends ZodType<any, any, any>>(
  schema: T,
  schemaName: string
): (response: unknown, meta: unknown, arg: unknown) => TypeOf<T> {
  return (response) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return schema.parse(response);
    } catch (error) {
      const err = error as ZodError;
      void logError({
        name: `${err.name} - ${schemaName}`,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        message: fromError(err).toString(),
        stack: err.stack,
      });
      throw new Error(err.message);
    }
  };
}
