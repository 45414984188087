import { SvgIcon } from "@mui/material";
import React from "react";

export function BookIcon({
  height,
  width,
}: {
  height?: string;
  width?: string;
}) {
  return (
    <SvgIcon style={{ height: height, width: width }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
      >
        <g clipPath="url(#clip0_1805_1145)">
          <path
            d="M17.7082 13.6771V2.21875C17.7082 1.2695 16.9383 0.5 15.9894 0.5H4.53109C2.94912 0.5 1.6665 1.78262 1.6665 3.36458V16.5417C1.6665 17.8075 2.69238 18.8333 3.95817 18.8333H17.1353C17.4519 18.8333 17.7082 18.5771 17.7082 18.2604C17.7082 17.9437 17.4504 17.6875 17.1353 17.6875H16.5623V15.2902C17.2284 15.0521 17.7082 14.4219 17.7082 13.6771ZM2.81234 3.36458C2.81234 2.41533 3.58184 1.64583 4.53109 1.64583H5.104V14.25H3.95817C3.53887 14.25 3.15072 14.3714 2.81234 14.5687V3.36458ZM15.4165 17.6875H3.95817C3.32653 17.6875 2.81234 17.1733 2.81234 16.5417C2.81234 15.91 3.32653 15.3958 3.95817 15.3958H15.4165V17.6875ZM15.9894 14.25H6.24984V1.64583H15.9894C16.3045 1.64583 16.5623 1.90257 16.5623 2.21875V13.6771C16.5623 13.9922 16.3045 14.25 15.9894 14.25ZM14.2707 5.08333H8.5415C8.2264 5.08333 7.96859 5.34115 7.96859 5.65625C7.96859 5.97135 8.2264 6.22917 8.5415 6.22917H14.2707C14.5874 6.22917 14.8436 5.97293 14.8436 5.65625C14.8436 5.33957 14.5858 5.08333 14.2707 5.08333ZM14.2707 8.52083H8.5415C8.2264 8.52083 7.96859 8.77865 7.96859 9.09375C7.96859 9.40885 8.2264 9.66667 8.5415 9.66667H14.2707C14.5874 9.66667 14.8436 9.41043 14.8436 9.09375C14.8436 8.77707 14.5858 8.52083 14.2707 8.52083Z"
            fill="#262626"
          />
        </g>
        <defs>
          <clipPath id="clip0_1805_1145">
            <rect
              width="20"
              height="20"
              fill="white"
              transform="translate(0 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}
