import React from "react";
import NavBarDropdownItem, {
  NavBarDropdownItemProps,
} from "../../NavBarDropdownItem";

const genomenonMarketingBaseURL = "https://genomenon.com";
const helpMenuItems: NavBarDropdownItemProps[] = [
  {
    id: "video_tutorials",
    label: "Video Tutorials",
    route: `${genomenonMarketingBaseURL}/resources`,
    isExternalLink: true,
  },
  {
    id: "faq",
    label: "FAQ",
    route: `${genomenonMarketingBaseURL}/faq`,
    isExternalLink: true,
  },
  {
    id: "cite_mastermind",
    label: "How to cite Mastermind",
    route: `${genomenonMarketingBaseURL}/citing-mastermind`,
    isExternalLink: true,
  },
];

const HelpDropdown = () => {
  return helpMenuItems.map((item) => (
    <NavBarDropdownItem key={item.id} {...item} />
  ));
};

export default HelpDropdown;
