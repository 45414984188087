import React from "react";
import {
  IconBenign,
  IconPathogenic,
  IconVus,
} from "../common/icons/VariantAssessments";
import {
  IconComputation,
  IconDoctor,
  IconGene,
  IconPopulation,
  IconUnclassified,
  IconVial,
} from "../common/icons/VariantKpis";
import {
  ACMGAndUnclassifiedCategories,
  ACMGCategories,
  UnclassifiedCategories,
} from "../../libs/types/variant";
import { primaryPalette } from "../../libs/styles/theme";
import { CuratedDataObjectKeys } from "../../network/reporter/types";

export const pathogenictyIconMap: {
  benign: React.JSX.Element;
  pathogenic: React.JSX.Element;
  likely_pathogenic: React.JSX.Element;
  likely_benign: React.JSX.Element;
  conflict: React.JSX.Element;
  vus: React.JSX.Element;
  [key: string]: React.JSX.Element; // Index signature
} = {
  benign: <IconBenign />,
  likely_benign: <IconBenign />,
  pathogenic: <IconPathogenic />,
  likely_pathogenic: <IconPathogenic />,
  vus: <IconVus />,
  conflict: <IconVus />,
};

export const curatedDataCriteriaHeaders = {
  ppc: "Affected Probands",
  ppc_het: "Affected Probands",
  ppc_homo: "Affected Probands",
  ppc_compound: "Affected Probands",
  ps4m: "Affected Probands",
  pp1: "Segregation",
  pp1m: "Segregation",
  ps2: "De novo observations",
  pm6: "De novo observations",
  bs4: "Segregation",
  bp5: "Unweighted probands",
  add_report: "Additional report on a previously published patient",
  unweighted_case: "Unweighted probands",
  pm3: "In trans observations",
  pp4: "Affected Probands",
  bp2: "Unweighted probands",
  bs2: "Healthy Individuals",
  ps4: "Affected Probands",
  pm2: "Population",
  ba1: "Population",
  bs1: "Population",
  ps3: "Functional evidence",
  bs3: "Functional evidence",
  fx: "Functional evidence",
  pvs1: "Splicing evidence",
  pm4: "Intrinsic evidence",
  pm1: "Intrinsic evidence",
  pm5: "Intrinsic evidence",
  pp2: "Intrinsic evidence",
  bp1: "Intrinsic evidence",
  bp3: "Intrinsic evidence",
  bp7: "Intrinsic evidence",
  ps1: "Intrinsic evidence",
  pp3: "Computational evidence",
  bp4: "Computational evidence",
  na1: "Variant referenced with no evidence for classification",
  na2: "Variant referenced with no evidence for classification",
  na3: "Variant referenced with no evidence for classification",
  na4: "Variant referenced with no evidence for classification",
  na5: "Variant referenced with no evidence for classification",
  review: "Variant referenced with no evidence for classification",
  fp1: "",
  fp2: "",
  fp3: "",
  fp4: "",
  flag: "",
  note: "",
  alias: "",
  splicing: "Splicing evidence",
  alleles: "Affected Probands",
  ppc_hemi: "Affected Probands",
  oligo: "Affected Probands",
  mosaic: "Affected Probands",
};

export const NA_CRITERIA_LIST = [
  "add_report",
  "na1",
  "na2",
  "na3",
  "na4",
  "na5",
  "review",
];

export const UNWEIGHTED_CRITERIA_EXCLUDE_LIST = [
  "add_report",
  "fx",
  "splicing",
  "unweighted_case",
];

export const UNWEIGHTED_CRITERIA_LABEL = "Unweighted Evidence";

const acmgIconStyles = {
  width: "14px",
  height: "14px",
  color: primaryPalette.grey.neutral_grey_g7,
  marginRight: "4px",
};

export const acmgCategoryIconMap: {
  [key in ACMGAndUnclassifiedCategories]: React.JSX.Element;
} = {
  [ACMGCategories.FUNCTIONAL]: <IconVial sx={acmgIconStyles} />,
  [ACMGCategories.INTRINSIC]: <IconGene sx={acmgIconStyles} />,
  [ACMGCategories.COMPUTATIONAL]: <IconComputation sx={acmgIconStyles} />,
  [ACMGCategories.CLINICAL]: <IconDoctor sx={acmgIconStyles} />,
  [ACMGCategories.POPULATION]: <IconPopulation sx={acmgIconStyles} />,
  [UnclassifiedCategories.UNCLASSIFIED]: (
    <IconUnclassified sx={acmgIconStyles} />
  ),
};

export const discreteArticleDataLabelMap: Record<
  CuratedDataObjectKeys,
  string
> = {
  in_trans: "Observed in trans with",
  na_type: "N/A Type",
  probands: "Number of probands",
  unrelated_proband: "Unrelated Proband(s)",
  zygosity: "Zygosity/variant status",
  alleles: "Number of alleles",
  prev_pmid: "Previous PMID patient(s) published in",
  unweighted_reason: "Unweighted Reason",
  splicing_impact: "Splicing evidence",
  impact_degree: "Degree of impact",
  impact_type: "Impact on splicing",
  other_impact: "Impact on splicing",
  segregation_type: "Segregation evidence",
  meioses: "Number of observed meioses",
  affected: "Affected patients without variant",
  subpopulation: "Subpopulation",
  allele_count: "Allele count",
  total_alleles: "Total alleles",
  carrier_age: "Age of unsymptomatic individual",
  unaffected: "Confirmed unaffected in publication",
  value: "Quantitative value",
  unit_test: "Unit",
  model: "Model",
  cell_type: "Cell type",
  exclude: "Functional criteria not applied to this study",
  functional_impact: "Assay result",
  functional_criteria: "Criteria/assessment",
  category: "Criteria",
  strength: "Strength",
  de_novo_type: "Confirmation",
  de_novo_probands: "Number of de novo observations",
  prediction_model: "Prediction model",
  score: "Score",
};
