export enum MenuItemId {
  ACCOUNT = "nav-bar-account",
  ALERTS = "nav-bar-alerts",
  API = "nav-bar-api",
  CONTACT = "nav-bar-contact",
  HELP = "nav-bar-help",
  LOGIN = "login-btn",
  LOGO = "nav-bar-logo",
  NOTIFICATIONS = "nav-bar-notifications",
}
