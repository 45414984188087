import { httpClient } from "./http-client";
import { AxiosResponse } from "axios";

export interface CategoryKeywordResponse {
  displayName: string;
  children?: CategoryKeywordChild;
  childOrder?: string[];
  acmgClasses?: string[];
  description?: string;
}

interface CategoryKeywordChild {
  [key: string]: CategoryKeywordResponse;
}

export const getCommonCategories = async () =>
  await httpClient.get<any, AxiosResponse<CategoryKeywordResponse>, any>(
    "/category/tree"
  );
