import { SvgIcon } from "@mui/material";
import React from "react";

export function ExportIcon({
  height,
  width,
}: {
  height?: string;
  width?: string;
}) {
  return (
    <SvgIcon
      fontSize="small"
      data-testid="export-icon"
      style={{ height: height, width: width }}
    >
      <svg
        width="12"
        height="14"
        viewBox="0 0 12 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_6838_47761)">
          <path
            d="M6.38306 1.375C6.38306 1.16875 6.21431 1 6.00806 1C5.80181 1 5.63306 1.16875 5.63306 1.375V8.72031L3.2729 6.36016C3.12759 6.21484 2.88853 6.21484 2.74321 6.36016C2.5979 6.50547 2.5979 6.74453 2.74321 6.88984L5.74321 9.88984C5.88853 10.0352 6.12759 10.0352 6.2729 9.88984L9.2729 6.88984C9.41821 6.74453 9.41821 6.50547 9.2729 6.36016C9.12759 6.21484 8.88853 6.21484 8.74321 6.36016L6.38306 8.72031V1.375ZM3.29165 8.5H1.50806C0.680713 8.5 0.00805664 9.17266 0.00805664 10V11.5C0.00805664 12.3273 0.680713 13 1.50806 13H10.5081C11.3354 13 12.0081 12.3273 12.0081 11.5V10C12.0081 9.17266 11.3354 8.5 10.5081 8.5H8.72446L7.97446 9.25H10.5081C10.9229 9.25 11.2581 9.58516 11.2581 10V11.5C11.2581 11.9148 10.9229 12.25 10.5081 12.25H1.50806C1.09321 12.25 0.758057 11.9148 0.758057 11.5V10C0.758057 9.58516 1.09321 9.25 1.50806 9.25H4.04165L3.29165 8.5ZM10.1331 10.75C10.1331 10.6008 10.0738 10.4577 9.9683 10.3523C9.86281 10.2468 9.71974 10.1875 9.57056 10.1875C9.42137 10.1875 9.2783 10.2468 9.17281 10.3523C9.06732 10.4577 9.00806 10.6008 9.00806 10.75C9.00806 10.8992 9.06732 11.0423 9.17281 11.1477C9.2783 11.2532 9.42137 11.3125 9.57056 11.3125C9.71974 11.3125 9.86281 11.2532 9.9683 11.1477C10.0738 11.0423 10.1331 10.8992 10.1331 10.75Z"
            fill="#104851"
          />
        </g>
        <defs>
          <clipPath id="clip0_6838_47761">
            <rect
              width="12"
              height="12"
              fill="white"
              transform="translate(0 1)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}
