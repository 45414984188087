import { variantColors } from "../styles/theme";

export enum VariantTypes {
  MISSENSE = "missense",
  SYNONYMOUS = "synonymous",
  TRUNCATING = "truncating",
  INFRAME = "inFrame",
  SPLICE = "splice",
  NONCODING = "nonCoding",
}

export enum ACMGCategories {
  POPULATION = "population",
  COMPUTATIONAL = "computational",
  INTRINSIC = "intrinsic",
  CLINICAL = "clinical",
  FUNCTIONAL = "functional",
}

export enum UnclassifiedCategories {
  UNCLASSIFIED = "Variant Referenced with Inapplicable",
}

export type ACMGAndUnclassifiedCategories =
  | ACMGCategories
  | UnclassifiedCategories;

export enum VariantSubTypes {
  PATHOGENIC = "pathogenic",
  BENIGN = "benign",
  VUS = "vus",
  UNCURATED = "uncurated",
}

export enum VariantTypeStrings {
  SUBSTITUTION = "substitution",
  SYNONYMOUS = "synonymous",
  INSERTION = "insertion",
  DUPLICATION = "duplication",
  EXTENSION = "extension",
  FRAMESHIFT = "frameshift",
  SPLICE_REGION = "splice_region",
  INTRONIC = "intronic",
  UNTRANSLATED_REGION = "untranslated_region",
  SPLICE_ACCEPTOR = "splice_acceptor",
  SPLICE_DONOR = "splice_donor",
  DELETION_INSERTION = "deletion_insertion",
  NONSENSE = "nonsense",
  DELETION = "deletion",
}

export type SubVariantDataType = {
  variant_type: VariantTypes;
  unique_match_count: number;
  pathogenicity: VariantSubTypes;
};

type VariantBucketColors = {
  backgroundColor: string;
};

export const variantWedgeColors: Record<string, VariantBucketColors> = {
  [VariantTypes.NONCODING]: {
    backgroundColor: variantColors.variantBucket.non_coding,
  },
  [VariantTypes.INFRAME]: {
    backgroundColor: variantColors.variantBucket.in_frame,
  },
  [VariantTypes.TRUNCATING]: {
    backgroundColor: variantColors.variantBucket.truncating,
  },
  [VariantTypes.SYNONYMOUS]: {
    backgroundColor: variantColors.variantBucket.synonymous,
  },
  [VariantTypes.MISSENSE]: {
    backgroundColor: variantColors.variantBucket.missense,
  },
  [VariantTypes.SPLICE]: {
    backgroundColor: variantColors.variantBucket.splice,
  },
};

export const variantBucketColors: Record<string, VariantBucketColors> = {
  [VariantTypeStrings.SUBSTITUTION]: {
    backgroundColor: variantColors.variantBucket.missense,
  },
  [VariantTypeStrings.SYNONYMOUS]: {
    backgroundColor: variantColors.variantBucket.synonymous,
  },
  [VariantTypeStrings.INSERTION]: {
    backgroundColor: variantColors.variantBucket.in_frame,
  },
  [VariantTypeStrings.DUPLICATION]: {
    backgroundColor: variantColors.variantBucket.in_frame,
  },
  [VariantTypeStrings.DELETION_INSERTION]: {
    backgroundColor: variantColors.variantBucket.in_frame,
  },
  [VariantTypeStrings.DELETION]: {
    backgroundColor: variantColors.variantBucket.in_frame,
  },
  [VariantTypeStrings.FRAMESHIFT]: {
    backgroundColor: variantColors.variantBucket.truncating,
  },
  [VariantTypeStrings.NONSENSE]: {
    backgroundColor: variantColors.variantBucket.truncating,
  },
  [VariantTypeStrings.EXTENSION]: {
    backgroundColor: variantColors.variantBucket.non_coding,
  },
  [VariantTypeStrings.SPLICE_REGION]: {
    backgroundColor: variantColors.variantBucket.non_coding,
  },
  [VariantTypeStrings.INTRONIC]: {
    backgroundColor: variantColors.variantBucket.non_coding,
  },
  [VariantTypeStrings.UNTRANSLATED_REGION]: {
    backgroundColor: variantColors.variantBucket.non_coding,
  },
  [VariantTypeStrings.SPLICE_ACCEPTOR]: {
    backgroundColor: variantColors.variantBucket.splice,
  },
  [VariantTypeStrings.SPLICE_DONOR]: {
    backgroundColor: variantColors.variantBucket.splice,
  },
};
