import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { primaryPalette } from "../../../libs/styles/theme";
import { useAppSelector } from "../../../store/hooks";
import TreatmentTrialListComponent from "./treatment-trial-list/TreatmentTrialList";
import { useLazyGetEvidenceViewerRecordsQuery } from "../../../network/reporter/reporter";
import { IconInfo } from "../../common/icons/Generic";
import { Instance } from "@popperjs/core";
import TooltipComponent from "../../common/tooltip/TooltipComponent";

const popperContent = (
  <Typography variant="text11">
    The clinical trial and/or treatment information provided above is based on
    the gene and variant selected by the user and the classification of that
    variant. Treatment decisions should be considered based on an individual's
    clinical presentation, genetic testing results, and/or family history.
    Please consult with a healthcare professional before taking any medical
    action. By clicking on the provided links, you authorize Genomenon to take
    you to a third party website.
  </Typography>
);

const TreatmentTrialContainer = () => {
  const { selectedVariant } = useAppSelector((state) => state.curatedEvidence);
  const popperRef = React.useRef<Instance>(null);

  const [triggerEvidenceViewerData, { data: evidenceViewerData }] =
    useLazyGetEvidenceViewerRecordsQuery({
      selectFromResult: (response) => ({
        data: {
          treatments: response.data?.record?.disease.treatments,
          resources: response.data?.record?.disease.resources,
        },
      }),
    });

  useEffect(() => {
    if (selectedVariant && selectedVariant.gene) {
      void triggerEvidenceViewerData(selectedVariant, true);
    }
  }, [selectedVariant]);

  return (
    <Box>
      <Stack direction="row" gap="4px" alignItems="center">
        <Typography
          variant={"text12"}
          sx={{
            color: primaryPalette.teal.neutral_teal_t4,
            textTransform: "uppercase",
          }}
        >
          Treatments & Clinical Trials
        </Typography>
        <TooltipComponent
          content={popperContent}
          placement="bottom"
          popperProps={{
            popperRef,
          }}
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, -13],
                  },
                },
              ],
            },
          }}
        >
          <Box data-testid="treatment-trial-info-icon">
            <IconInfo height="11px" width="11px" />
          </Box>
        </TooltipComponent>
      </Stack>

      {evidenceViewerData &&
      evidenceViewerData.resources &&
      evidenceViewerData.treatments ? (
        <TreatmentTrialListComponent
          resources={evidenceViewerData.resources}
          treatments={evidenceViewerData.treatments}
        />
      ) : null}
    </Box>
  );
};

export default TreatmentTrialContainer;
