import React from "react";
import { useAppDispatch } from "../../../store/hooks";
import { clearArticleState } from "../../../store/slices/articleSlice";
import { clearCuratedEvidenceState } from "../../../store/slices/curatedEvidenceSlice";
import { clearFeaturedArticleState } from "../../../store/slices/featuredArticleSlice";
import { useSearchParams } from "react-router-dom";
import { UrlParams } from "../../../libs/types/url-params";

type ArticlePageStateManagerProps = {
  children: React.ReactNode;
};

const ArticlePageStateManager = ({
  children,
}: ArticlePageStateManagerProps) => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  // clears stale article page data on page load
  React.useEffect(() => {
    dispatch(clearArticleState());
    dispatch(clearCuratedEvidenceState());
    dispatch(clearFeaturedArticleState());
  }, [dispatch]);

  // clears stale curated evidence data if variant removed
  // from search
  React.useEffect(() => {
    if (!searchParams.has(UrlParams.VARIANT)) {
      dispatch(clearCuratedEvidenceState());
    }
  }, [dispatch, searchParams]);

  return <>{children}</>;
};

export default ArticlePageStateManager;
