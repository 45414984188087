import { Box, Stack } from "@mui/material";
import React from "react";
import ACMGCriteriaHeader from "./ACMGCriteriaHeader";
import ACMGCriteriaBody from "./ACMGCriteriaBody";

const EvidenceViewerACMGCriteria = () => {
  return (
    <Box m={0}>
      <Stack
        sx={{
          margin: "10px 20px",
        }}
        gap="5px"
      >
        <ACMGCriteriaHeader />
        <ACMGCriteriaBody />
      </Stack>
    </Box>
  );
};

export default EvidenceViewerACMGCriteria;
