import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Button, Grid, Typography } from "@mui/material";
import { Domain, ProteinShift } from "../../../../libs/types/gene";
import { defaultValue } from "../Summary/Summary";

export const summaryLimit = 5;
export const startSubheader = "Start";
export const endSubheader = "End";

const Subheader = styled(Typography)(({ theme }) => ({
  fontSize: "0.75rem",
  color: theme.palette.secondary.contrastText,
}));

const Text = styled(Typography)(() => ({
  fontSize: "0.8rem",
}));

const Column = styled(Grid)(() => ({
  inlineSize: "min-content",
  textAlign: "center",
  "& .blur": {
    filter: "blur(4px)",
  },
}));

export function SummaryPositions({
  nameHeader,
  summaries,
}: {
  nameHeader: string;
  summaries: Domain[] | ProteinShift[];
}) {
  const [showFullSummary, setShowFullSummary] = useState(false);

  const getSummariesToMap = () => {
    const summaryLength = summaries.length ?? 0;

    if (summaryLength === 0) {
      return [{ name: defaultValue, start: defaultValue, end: defaultValue }];
    }

    const summarySpliceEnd =
      summaryLength <= summaryLimit || showFullSummary
        ? summaryLength
        : summaryLimit;

    return [...summaries].splice(0, summarySpliceEnd);
  };
  const includeButton = () => summaries.length > summaryLimit;

  return (
    <Grid
      container
      rowSpacing={0}
      sx={{ paddingTop: "24px" }}
      data-testid={`summary-positions-${nameHeader}`}
    >
      <Column item xs={6} sx={{ textAlign: "left" }}>
        <Subheader>{nameHeader}</Subheader>
      </Column>
      <Column item xs={3}>
        <Subheader>{startSubheader}</Subheader>
      </Column>
      <Column item xs={3}>
        <Subheader>{endSubheader}</Subheader>
      </Column>
      {getSummariesToMap().map((s) => {
        const shouldBlur = s.name === "insufficient_permissions";
        return (
          <React.Fragment key={`${s.name}-${s.start}-${s.end}`}>
            <Column item xs={6} sx={{ textAlign: "left" }}>
              <Text className={shouldBlur ? "blur" : ""}>{s.name}</Text>
            </Column>
            <Column item xs={3}>
              <Text className={shouldBlur ? "blur" : ""}>{s.start}</Text>
            </Column>
            <Column item xs={3}>
              <Text className={shouldBlur ? "blur" : ""}>{s.end}</Text>
            </Column>
          </React.Fragment>
        );
      })}
      {includeButton() && (
        <Grid item xs={12}>
          <Button
            onClick={() => setShowFullSummary(!showFullSummary)}
            variant="text"
            sx={{
              fontSize: "0.6rem",
              fontWeight: 600,
              marginTop: "0.6rem",
              padding: 0,
              justifyContent: "left",
              "&:hover": {
                background: "none",
              },
            }}
          >
            {showFullSummary ? "LESS" : "MORE"}
          </Button>
        </Grid>
      )}
    </Grid>
  );
}
