import React, { useEffect } from "react";
import { Box, Chip, Skeleton, Tooltip, Typography } from "@mui/material";
import { useGetVariantsQuery } from "../../../network/variants/variants";
import { skipToken } from "@reduxjs/toolkit/query";
import { primaryPalette } from "../../../libs/styles/theme";
import { VariantResult } from "../../../libs/models/variant-result";
import {
  executeSearch,
  replaceVariantTags,
} from "../../../store/slices/searchSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { removeTag } from "../../../store/slices/searchSlice";
import { Suggestion } from "../../../network/suggestions";
import {
  selectUrlTermIds,
  selectUrlVariantSuggestions,
} from "../../../store/selectors/urlSelectors";
import { VariantResponse } from "../../../network/variants/types";

type MatchVariant = {
  name: string;
  hit_count: number | "N/A";
};

type MatchChips = {
  [key: string]: MatchVariant[];
};

const determineTooltipText = (
  isActiveTerm: boolean,
  variantTermsCount: number
): string => {
  if (isActiveTerm) {
    return "Click to remove search term";
  }
  if (variantTermsCount > 1) {
    return "Click to replace searched variants";
  }
  return "Click to add search term";
};

export default function ExactMatch() {
  const [matchData, setMatchData] = React.useState<MatchChips>({});
  const dispatch = useAppDispatch();
  const { articleListData } = useAppSelector((state) => state.articles);
  const exactCountCDNA = articleListData?.ExactDNAMatchCount ?? 0;
  const searchUrl = useAppSelector((state) => state.url);
  const urlTermIds = useAppSelector((state) => selectUrlTermIds(state));
  const urlVariantSuggestions = useAppSelector((state) =>
    selectUrlVariantSuggestions(state)
  );

  const { isLoading: variantDataLoading, data: variantData } =
    useGetVariantsQuery(
      urlTermIds.variant.length > 0
        ? {
            urlTermIds: urlTermIds,
            urlBooleanParams: searchUrl.urlTermBooleans,
            urlCats: searchUrl.urlCats,
            urlSigTerms: searchUrl.urlSigTerms,
            urlString: urlTermIds.toString(),
          }
        : skipToken
    );

  const getSearchedVariantCount = (searchedVariant: string) => {
    const count =
      variantData?.variants.find((v) => {
        return v.name === searchedVariant;
      })?.without_mut_hits ?? "N/A";
    const kind = urlVariantSuggestions.find((v) => {
      return v.id === searchedVariant;
    })?.variant_kind;

    return kind === "cDNA" ? exactCountCDNA.toString() : count;
  };

  const getMatchData = (mutationsResp: VariantResponse): MatchChips => {
    const matchVars: MatchChips = {};
    // not sure we need to check the suggestion length but probably should
    if (mutationsResp?.variants !== null && urlVariantSuggestions.length > 0) {
      // iterate through all the suggestions -- we need to display the different notations for this variant
      for (const [_, variantSuggestion] of Object.entries(
        urlVariantSuggestions
      )) {
        // iterate through all the suggestion keys -- these appear to be all the other ways to describe this variant
        const altNotations = variantSuggestion.keys ?? [];
        if (
          altNotations.length > 1 ||
          (altNotations.length === 1 &&
            altNotations[0] !== variantSuggestion.id)
        ) {
          matchVars[variantSuggestion.id] = [];
          altNotations.forEach((notation) => {
            const mutationCountData = mutationsResp.variants.find(
              (v) => v.name === notation
            );

            if (mutationCountData !== undefined) {
              const exactMatch = urlVariantSuggestions.find(
                (suggestion) => suggestion.id === mutationCountData.name
              );

              if (exactMatch !== undefined) {
                // The notation was one of our search terms -- use without_mut_hits
                matchVars[variantSuggestion.id].push({
                  name: notation,
                  hit_count: mutationCountData.without_mut_hits,
                });
              } else {
                // The notation was NOT one of our search terms
                // Dig through all the suggestions we were given (so we can access the variant_kind... *twitch*
                const suggestionForTerm =
                  searchUrl.urlVariantsWithAltNotationsSuggestions.variant.find(
                    (f) => f.id === notation
                  );

                if (suggestionForTerm !== undefined) {
                  if (suggestionForTerm.variant_kind === "cDNA") {
                    // if the type is cDNA, we use the exactCount
                    matchVars[variantSuggestion.id].push({
                      name: notation,
                      hit_count: exactCountCDNA,
                    });
                  } else {
                    // otherwise use total hits
                    matchVars[variantSuggestion.id].push({
                      name: notation,
                      hit_count: mutationCountData.total_hits,
                    });
                  }
                }
              }
            } else {
              // our term wasn't in the mutations response -- seems to happen for rsIDs, c notations, etc
              matchVars[variantSuggestion.id].push({
                name: notation,
                hit_count: "N/A",
              });
            }
          });
        }
      }
    }

    return matchVars;
  };

  const handleClick = (
    matchName: string,
    isActiveTerm: boolean,
    searchedVariant: string
  ) => {
    if (isActiveTerm) {
      const sugg = urlVariantSuggestions.find((t) => {
        return t.id === matchName;
      }) as Suggestion;

      dispatch(removeTag(sugg));
    } else {
      // persist exact match search term
      const variant = urlVariantSuggestions.find((t) => {
        return t.id === searchedVariant;
      }) as Suggestion;

      const selectedMatch = {
        type: "variant",
        id: matchName,
        text: matchName.toUpperCase(),
      } as Suggestion;

      dispatch(replaceVariantTags([variant, selectedMatch]));
    }
    dispatch(executeSearch());
  };

  const displayMatchForTerm = (searchedVariant: string) => {
    const matches: MatchVariant[] = matchData[searchedVariant];

    return matches.map((match) => {
      const isActiveTerm = !!urlVariantSuggestions.find(
        (t) => t?.id === match.name
      );

      return (
        <Tooltip
          title={determineTooltipText(
            isActiveTerm,
            urlVariantSuggestions.length
          )}
          placement="top"
          arrow
          key={`exact-match-${match.name}`}
        >
          <Chip
            label={`${VariantResult.variantCasing(match.name)} (${
              match.hit_count
            })`}
            data-testid={
              isActiveTerm
                ? `exact-match-${match.name}-active`
                : `exact-match-${match.name}`
            }
            size={"small"}
            variant={isActiveTerm ? "activeFilter" : "filter"}
            onClick={() =>
              handleClick(match.name, isActiveTerm, searchedVariant)
            }
          />
        </Tooltip>
      );
    });
  };

  useEffect(() => {
    if (variantData !== undefined) {
      const mData = getMatchData(variantData);
      setMatchData(mData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variantData]);

  if (variantDataLoading || !articleListData || !matchData) {
    return (
      <Skeleton
        width={"100%"}
        height={"100px"}
        variant={"rectangular"}
        sx={{ mb: "10px" }}
      />
    );
  }

  return (
    <>
      {Object.keys(matchData).map((searchedVariant) => (
        <Box
          pb="10px"
          key={`exact-match-rep-${searchedVariant}`}
          data-testid={`exact-match-rep-${searchedVariant}`}
        >
          <Typography variant="h5" color={primaryPalette.teal.neutral_teal_t4}>
            {searchedVariant} ({getSearchedVariantCount(searchedVariant)})
            Corresponds to Multiple Representations
          </Typography>
          <Box display="flex" alignItems="center" flexWrap="wrap" gap="2px">
            {displayMatchForTerm(searchedVariant)}
          </Box>
        </Box>
      ))}
    </>
  );
}
