import { SvgIcon } from "@mui/material";
import React from "react";

export function AttachmentIcon(props: { height: string; width: string }) {
  return (
    <SvgIcon sx={{ height: props.height, width: props.width }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="15"
        viewBox="0 0 14 15"
        fill="none"
      >
        <path
          d="M10.8994 2.96889C10.1689 2.26519 8.984 2.26519 8.25348 2.96889L3.43382 7.61322C2.25972 8.74533 2.25972 10.579 3.43382 11.7111C4.60842 12.8432 6.51129 12.8432 7.68614 11.7111L11.5019 8.03413C11.6576 7.88173 11.9136 7.88173 12.0693 8.03413C12.2274 8.18411 12.2274 8.43085 12.0693 8.58084L8.25348 12.2578C6.76483 13.6923 4.33228 13.6923 2.86597 12.2578C1.378 10.8233 1.37802 8.47924 2.86597 7.06651L7.68614 2.4217C8.73046 1.41542 10.4224 1.41542 11.4668 2.4217C12.5111 3.42778 12.5111 5.0587 11.4668 6.06503L6.8527 10.5112C6.14477 11.1934 4.97996 11.1305 4.33228 10.3782C3.81941 9.71295 3.87288 8.80581 4.47788 8.22282L8.28863 4.5507C8.44427 4.3983 8.70033 4.3983 8.85597 4.5507C9.01413 4.70068 9.01413 4.94743 8.85597 5.09741L5.04523 8.76952C4.70884 9.07432 4.70131 9.55813 4.95987 9.89438C5.28622 10.2863 5.91633 10.3201 6.28535 9.96453L10.8994 5.51832C11.6299 4.81438 11.6299 3.67283 10.8994 2.96889Z"
          fill="#437179"
        />
      </svg>
    </SvgIcon>
  );
}
