import React from "react";
import { FlexColumnDiv } from "../../../common/Flex";
import GeneOverview from "../../common/GeneOverview/GeneOverview";

const GeneOverviewText = () => {
  return (
    <FlexColumnDiv
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        width: "10rem",
      }}
    >
      <GeneOverview />
    </FlexColumnDiv>
  );
};

export default GeneOverviewText;
