import React from "react";
import { Skeleton, Stack } from "@mui/material";

interface ArticleViewerLoadingStateProps {
  animation: "pulse" | "wave" | false;
  testId?: string;
}

const ArticleViewerLoadingState = ({
  animation,
  testId,
}: ArticleViewerLoadingStateProps) => {
  return (
    <Stack spacing={2} data-testid={testId}>
      <Skeleton
        variant="rectangular"
        width="100%"
        height={130}
        animation={animation}
      ></Skeleton>
      <Skeleton
        variant="rectangular"
        width="100%"
        height={300}
        animation={animation}
      ></Skeleton>
      <Skeleton
        variant="rectangular"
        width="100%"
        height={500}
        animation={animation}
      ></Skeleton>
    </Stack>
  );
};

export default ArticleViewerLoadingState;
