import {
  setCategories,
  setSignificantTerms,
} from "../../store/slices/searchSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { resetFilterState } from "../../store/slices/filterSlice";

export const useHandleFilterInteraction = () => {
  const { cats, sig_terms } = useAppSelector((state) => state.search);
  const { userSelectedCategories, userSelectedSigTerms } = useAppSelector(
    (state) => state.filter
  );
  const dispatch = useAppDispatch();

  // user cancelled their category/sig term selections; reset to what we have in search bar
  const onCancelSearch = () => {
    dispatch(resetFilterState({ categories: cats, sigTerms: sig_terms }));
  };

  // user wants to add their category/sig term selections to search bar; add to search slice
  const onSearch = () => {
    dispatch(setCategories(userSelectedCategories));
    dispatch(setSignificantTerms(userSelectedSigTerms));
  };

  return { onCancelSearch, onSearch };
};
