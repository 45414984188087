import {
  Box,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses,
} from "@mui/material";
import React, { useState } from "react";
import pluralize from "pluralize";
import useExpandCollapse from "../../../../../libs/hooks/useExpandCollapse";
import SeeMoreButton from "../../../../common/buttons/SeeMoreButton";
import { AttachmentIcon } from "../../../../common/icons/AttachmentIcon";
import { StyledCommonCard } from "../../../../common/StyledCommonCard";
import { MatchSummary } from "../../../../../network/articles";
import { VariantResult } from "../../../../../libs/models/variant-result";
import { determineFileName } from "./supplemental-match-util";
import { useAppSelector } from "../../../../../store/hooks";
import { selectSupplementalMatches } from "../../../../../store/selectors/featuredArticleSelectors";
import { primaryPalette } from "../../../../../libs/styles/theme";
import AccordionButton from "../../../../common/AccordionButton";

const initialVisibleCount = 3;

const matchTitle = (match: MatchSummary): string => {
  let geneticIdentifier = "";
  if (match.type === "gene") {
    geneticIdentifier = VariantResult.geneCasing(match.gene_id);
  } else if (match.type === "variant") {
    geneticIdentifier = VariantResult.variantCasing(match?.mutation_id ?? "");
  }
  return `${geneticIdentifier} ${match.type} found ${
    match.match_count
  } ${pluralize(
    "times",
    match.match_count
  )} in the supplemental data for this article.`;
};

const SupplementalMatches = () => {
  const supplementalMatches = useAppSelector((state) =>
    selectSupplementalMatches(state)
  );
  const [expanded, setExpanded] = useState(true);
  const { toggleItems, visibleItemCount, hasMoreItems } = useExpandCollapse({
    initialItemCount: initialVisibleCount,
    totalItemCount: supplementalMatches?.length,
  });

  return (
    <>
      {supplementalMatches ? (
        <>
          {supplementalMatches?.length > 0 ? (
            <Box sx={{ marginTop: "16px" }}>
              <AccordionButton
                handleClick={() => setExpanded((prev) => !prev)}
                expanded={expanded}
                label={`${supplementalMatches?.length} ${pluralize(
                  "Matches",
                  supplementalMatches?.length
                )} in Supplemental Data`}
              />
              {expanded ? (
                <>
                  <Grid container spacing={1}>
                    {supplementalMatches
                      ?.slice(0, visibleItemCount)
                      .map((match: MatchSummary) => (
                        <Grid
                          item
                          xs={12}
                          key={`supp-match-${match.gene_id}-${
                            match.mutation_id ?? ""
                          }`}
                        >
                          <StyledCommonCard style={{ padding: "0.5rem" }}>
                            <div>
                              <AttachmentIcon height="14px" width="14px" />
                              &nbsp;
                              <Typography
                                variant="text12"
                                color={primaryPalette.grey.neutral_grey_g7}
                              >
                                {matchTitle(match)}
                              </Typography>
                            </div>
                            {(match.supplemental_matches ?? []).length > 0 && (
                              <Table
                                size="small"
                                sx={{
                                  [`& .${tableCellClasses.root}`]: {
                                    borderBottom: "none",
                                    color: primaryPalette.grey.neutral_grey_g7,
                                  },
                                }}
                              >
                                <TableHead>
                                  <TableRow
                                    aria-label={
                                      "supplemental_match_table_header"
                                    }
                                  >
                                    <TableCell>Match Text</TableCell>
                                    <TableCell align="right">Count</TableCell>
                                    <TableCell align="left">File</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {match.supplemental_matches?.map(
                                    (sup_match, _i) => (
                                      <TableRow
                                        key={`sup_match_row_${sup_match.match_text}_${_i}`}
                                        aria-label="supplemental_match_row"
                                      >
                                        <TableCell>
                                          <Typography variant="text11">
                                            {sup_match.match_text}
                                          </Typography>
                                        </TableCell>
                                        <TableCell align="right">
                                          <Typography variant="text11">
                                            {sup_match.count}
                                          </Typography>
                                        </TableCell>
                                        <TableCell>
                                          <Typography variant="text11">
                                            {determineFileName(sup_match)}
                                            &nbsp;
                                            <i>
                                              {
                                                sup_match
                                                  .supplementary_match_source
                                                  .provenance
                                              }
                                            </i>
                                          </Typography>
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )}
                                </TableBody>
                              </Table>
                            )}
                          </StyledCommonCard>
                        </Grid>
                      ))}
                  </Grid>
                  {supplementalMatches?.length > initialVisibleCount ? (
                    <Box paddingBottom={1} textAlign="center">
                      <Divider variant={"middle"} sx={{ marginBottom: 1 }} />
                      <SeeMoreButton
                        callback={toggleItems}
                        hasMoreItems={hasMoreItems}
                        id="see-more-supplemental-matches"
                      />
                    </Box>
                  ) : null}
                </>
              ) : null}
            </Box>
          ) : (
            <Box
              data-testid="supplemental-matches-empty"
              sx={{
                fontSize: "14px",
                fontWeight: "600",
                letterSpacing: "0.56px",
                color: primaryPalette.teal.neutral_teal_t4,
                marginTop: "16px",
                textTransform: "uppercase",
              }}
            >
              0 Matches in Supplemental Data
            </Box>
          )}
        </>
      ) : null}
    </>
  );
};

export default SupplementalMatches;
