import * as React from "react";
import { styled } from "@mui/material/styles";

import { CategoriesProps } from "../../../../../libs/types/card";
import { Grid } from "@mui/material";
import { getBackgroundColor } from "../../../../../libs/utils/acmg";
import ACMGChip from "../../../../common/chips/ACMGChip";
import { useAppSelector } from "../../../../../store/hooks";
import { UNWEIGHTED_CRITERIA_EXCLUDE_LIST } from "../../../constants";

const CustomGrid = styled(Grid)({
  padding: "0 0.625rem",
});

const Categories: React.FC<CategoriesProps> = ({ acmgRecords }) => {
  const { acmgClasses } = useAppSelector((state) => state.acmgClasses);
  const filteredAcmgRecords = Object.entries(acmgRecords).filter(
    ([key, _]) =>
      acmgRecords[key].type && !UNWEIGHTED_CRITERIA_EXCLUDE_LIST.includes(key)
  );

  if (Object.keys(acmgRecords).length && acmgClasses) {
    return (
      <CustomGrid container columnSpacing={0.5} rowSpacing={0}>
        {filteredAcmgRecords.map(([key, value]) => {
          const bgcolor = getBackgroundColor(acmgClasses[key.toUpperCase()]);
          return (
            <Grid className="acmg-chip" item key={key}>
              <ACMGChip
                bgcolor={bgcolor}
                category={key}
                strength={value.strength ?? ""}
              />
            </Grid>
          );
        })}
      </CustomGrid>
    );
  }
  return <></>;
};

export default Categories;
