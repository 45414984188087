import {
  combineReducers,
  configureStore,
  PreloadedState,
} from "@reduxjs/toolkit";
import userReducer from "./slices/userSlice";
import searchReducer from "./slices/searchSlice";
import modalReducer from "./slices/modalSlice";
import variantReducer from "./slices/variantSlice";
import categoryLayoutReducer from "./slices/categoryLayoutSlice";
import filterReducer from "./slices/filterSlice";
import { listenerMiddleware } from "./listenerMiddleware";
import keywordCountReducer from "./slices/categoryKeywordSlice";
import notificationReducer from "./slices/notificationSlice";
import articleReducer from "./slices/articleSlice";
import featuredArticleReducer from "./slices/featuredArticleSlice";
import featuresReducer from "./slices/featureSlice";
import textMatchFilterReducer from "./slices/textMatchFilterSlice";
import curatedEvidenceReducer from "./slices/curatedEvidenceSlice";
import urlReducer from "./slices/urlSlice";
import acmgClassesReducer from "./slices/acmgClassesSlice";
import { api } from "../network/api";

export const rootReducer = combineReducers({
  acmgClasses: acmgClassesReducer,
  user: userReducer,
  search: searchReducer,
  modal: modalReducer,
  notifications: notificationReducer,
  variant: variantReducer,
  categoryLayout: categoryLayoutReducer,
  keywordCount: keywordCountReducer,
  filter: filterReducer,
  articles: articleReducer,
  featuredArticle: featuredArticleReducer,
  features: featuresReducer,
  textMatchFilter: textMatchFilterReducer,
  curatedEvidence: curatedEvidenceReducer,
  url: urlReducer,
  [api.reducerPath]: api.reducer,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: { warnAfter: 100 },
        serializableCheck: false,
      })
        .prepend(listenerMiddleware.middleware)
        .concat(api.middleware),
  });
};

export const store = setupStore();

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = AppStore["dispatch"];
