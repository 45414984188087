import { useAppDispatch } from "../../store/hooks";
import {
  ErrorData,
  Modals,
  openModal,
  setCurrentModal,
  setError,
} from "../../store/slices/modalSlice";

export const useOpenSelectedModal = () => {
  const dispatch = useAppDispatch();

  return (modalName: Modals, error?: ErrorData, canClose?: boolean) => {
    dispatch(setCurrentModal(modalName));
    dispatch(openModal(canClose ?? true));
    if (error) {
      dispatch(setError(error));
    }
  };
};
