import React, { useEffect } from "react";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";
import { AxiosResponse } from "axios";
import { Box, Typography } from "@mui/material";
import uniqWith from "lodash/uniqWith";
import { useSnackbar } from "notistack";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  BooleanSearch,
  Categories,
  SearchBarTerms,
  SearchBarTermsType,
  SearchedTerms,
  SignificantTerms,
  URLPayload,
  initialSearchState,
  resetShouldUpdateURL,
  setPageLoadSearchState,
} from "../../store/slices/searchSlice";
import {
  boxShadowStyles,
  elementHeights,
  primaryPalette,
  theme,
} from "../../libs/styles/theme";
import {
  constructURLQueryParams,
  determineBestSuggestion,
  determineRoute,
  reorderQueryParams,
} from "../../libs/utils/search";
import { useHandleError } from "../../libs/hooks/errorHandlerHook";
import { clearKeywordCounts } from "../../store/slices/categoryKeywordSlice";
import {
  BooleanSearchOptions,
  Suggestion,
  getSuggestions,
} from "../../network/suggestions";
import { UrlParams } from "../../libs/types/url-params";
import { setPageLoadUrlState } from "../../store/slices/urlSlice";
import { setPageLoadCategoryState } from "../../store/slices/filterSlice";
import SearchInput from "./SearchInput";
import SearchTagContainer from "./SearchTagContainer";
import { getSearchWrapperStyles } from ".";

export const Search = () => {
  const isHome = location.pathname === "/";
  const search = useAppSelector((state) => state.search);

  return (
    <Box width={isHome ? "100%" : "auto"} display="flex" alignItems="center">
      <Box
        display="flex"
        alignItems="center"
        alignSelf="stretch"
        minWidth={isHome ? "350px" : "auto"}
        style={{
          backgroundColor: primaryPalette.blue.variant_01,
          ...(isHome ? getSearchWrapperStyles(search) : {}),
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          height="100%"
          width={isHome ? "100%" : "auto"}
          pt="10px"
          pr={isHome ? "10px" : "20px"}
          pb="12px"
          pl="10px"
        >
          <Typography
            variant="text12"
            style={{
              color: primaryPalette.teal.neutral_teal_t2,
              paddingBottom: "5px",
              lineHeight: "12px",
              letterSpacing: "0.24px",
            }}
          >
            Search
          </Typography>
          <SearchInput />
        </Box>
      </Box>
      <SearchTagContainer />
    </Box>
  );
};

const SearchBar = () => {
  const location = useLocation();
  const isHome = location.pathname === "/";
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const search = useAppSelector((state) => state.search);
  const handleError = useHandleError();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const shouldNavigateToNewURL = async () => {
    const queryParams = constructURLQueryParams();
    const updatedUrl = await determineRoute(search);
    // check if user needs to be redirect to a new page
    if (updatedUrl !== location.pathname) {
      if (updatedUrl === "") {
        navigate(updatedUrl);
      } else if (updatedUrl === "/gene" || updatedUrl === "/articles") {
        navigate(`${updatedUrl}?${queryParams.toString()}`);
      } else {
        window.location.href = `${updatedUrl}?${queryParams.toString()}`;
      }
      return true;
    }
    return false;
  };

  // Parse the url query params on page load and use that to set search / url states
  useEffect(() => {
    const newUrlState: URLPayload = {
      terms: {
        gene: [],
        disease: [],
        variant: [],
        keyword: [],
        hpo: [],
        unii: [],
        cnv: [],
      },
      suggTerms: {
        gene: [],
        disease: [],
        variant: [],
        keyword: [],
        hpo: [],
        unii: [],
        cnv: [],
      },
      bools: {
        gene: BooleanSearchOptions.OR,
        disease: BooleanSearchOptions.OR,
        variant: BooleanSearchOptions.OR,
        keyword: BooleanSearchOptions.OR,
        hpo: BooleanSearchOptions.OR,
        unii: BooleanSearchOptions.OR,
        cnv: BooleanSearchOptions.OR,
      },
      categories: {
        cats: {},
        sig_terms: {},
      },
    };

    const newTermsState: SearchBarTermsType = {
      gene: [],
      disease: [],
      variant: [],
      keyword: [],
      hpo: [],
      unii: [],
      cnv: [],
    };

    const newSuggestTermsState: SearchedTerms = {
      gene: [],
      disease: [],
      variant: [],
      keyword: [],
      hpo: [],
      unii: [],
      cnv: [],
    };

    const newBoolState: BooleanSearch = {
      ...initialSearchState.booleans,
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const promises = [] as Array<Promise<AxiosResponse<Suggestion[], any>>>;
    let shouldUpdateUrl = false;

    (Object.keys(SearchBarTerms) as Array<keyof SearchBarTermsType>).map(
      (v) => {
        const queryKey = v === SearchBarTerms.variant ? "mutation" : v;
        const queryParams = searchParams.getAll(queryKey);
        const queryParamsDedup = uniqWith(
          queryParams,
          (first, second) => first.toLowerCase() === second.toLowerCase()
        );

        if (queryParams.length !== queryParamsDedup.length) {
          shouldUpdateUrl = true;
        }

        if (queryParamsDedup.length > 0) {
          queryParamsDedup.forEach((qp) => {
            promises.push(
              getSuggestions(
                qp,
                queryKey === "mutation" ? searchParams.getAll("gene") : [],
                queryKey
              )
            );
          });
        }

        // get and set and/or booleans
        const boolQueryParam = searchParams.get(`${queryKey}_op`);

        if (
          boolQueryParam &&
          Object.values(BooleanSearchOptions).includes(
            boolQueryParam as BooleanSearchOptions
          )
        ) {
          newBoolState[v] = boolQueryParam as BooleanSearchOptions;
        }
      }
    );

    // get and set filter params: cats
    const newFilterState: Categories = {};
    const filterParams = searchParams.getAll(UrlParams.CATEGORY);
    filterParams.forEach((fParam) => {
      newFilterState[fParam] = {
        key: fParam,
      };
    });

    // get and set filter params: sig terms
    const newSigFilterState: SignificantTerms = {};
    const sigFilterParams = searchParams.getAll(UrlParams.SIGTERM);
    sigFilterParams.forEach((fParam) => {
      newSigFilterState[fParam] = {
        key: fParam,
      };
    });

    Promise.all(promises)
      .then((res) => {
        res.forEach((r) => {
          const { term, term_type } = r.config.params as {
            term: string;
            term_type: string;
          };
          const { bestSuggestion, exactMatch } = determineBestSuggestion(
            term,
            term_type === "mutation" ? "variant" : term_type,
            r.data
          );
          if (term_type === "keyword") {
            newTermsState["keyword"].push({
              type: "keyword",
              id: term,
              text: term,
            });
          } else if (bestSuggestion) {
            if (bestSuggestion && exactMatch) {
              newTermsState[r.data[0].type].push(bestSuggestion);
            } else if (!exactMatch && bestSuggestion) {
              shouldUpdateUrl = true;
              newTermsState[r.data[0].type].push(bestSuggestion);
              enqueueSnackbar(
                `We were not able to match your input term ${term}. We replaced it with our best match ${bestSuggestion.text}.`,
                { variant: "error", preventDuplicate: true }
              );
            } else if (!exactMatch && !bestSuggestion) {
              shouldUpdateUrl = true;
              enqueueSnackbar(
                `We were not able to match your input term ${term}. We removed it from the search.`,
                { variant: "error", preventDuplicate: true }
              );
            }
            r.data.forEach((sugg) => {
              newSuggestTermsState[r.data[0].type].push(sugg);
            });
          } else {
            shouldUpdateUrl = true;
            enqueueSnackbar(
              `We were not able to match your input term ${term}. We removed it from the search.`,
              { variant: "error", preventDuplicate: true }
            );
          }
        });
        if (shouldUpdateUrl) {
          (Object.keys(SearchBarTerms) as Array<keyof SearchBarTermsType>).map(
            (v) => {
              const termKey = v === "variant" ? "mutation" : v;
              searchParams.delete(termKey);
              newTermsState[v].forEach((term) =>
                searchParams.append(termKey, term.id)
              );
            }
          );
          const reorderedParams = reorderQueryParams(searchParams);
          setSearchParams(reorderedParams, { replace: true });
        }
        newUrlState.bools = newBoolState;
        newUrlState.categories.cats = newFilterState;
        newUrlState.categories.sig_terms = newSigFilterState;
        newUrlState.terms = newTermsState;
        newUrlState.suggTerms = newSuggestTermsState;

        dispatch(setPageLoadSearchState(newUrlState));
        dispatch(setPageLoadUrlState(newUrlState));
        dispatch(
          setPageLoadCategoryState({
            categories: newFilterState,
            sigTerms: newSigFilterState,
          })
        );
      })
      .catch((err) => {
        // TODO - handle this with error
        // eslint-disable-next-line
        console.log("some error getting suggestions for url params", err);
      });
    // sync search and filter states upon page load and url updates
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (search.shouldUpdateURL) {
      // check if we should navigate to a new URL
      shouldNavigateToNewURL()
        .then((didNavigate) => {
          // otherwise just update query params
          if (!didNavigate) {
            const queryParams = constructURLQueryParams();
            setSearchParams(queryParams);
          }
          dispatch(resetShouldUpdateURL());
          // reset keyword counts since our search has changed
          dispatch(clearKeywordCounts());
        })
        .catch((err) => {
          handleError(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search.shouldUpdateURL]);

  return (
    <Box
      data-testid="search-bar"
      width="100%"
      position={isHome ? "relative" : "fixed"}
      top={isHome ? "auto" : elementHeights.navbar}
      display="flex"
      alignItems="center"
      justifyContent={isHome ? "center" : "left"}
      flexGrow={1}
      zIndex={theme.zIndex.appBar}
      boxShadow={isHome ? "none" : boxShadowStyles.default}
      style={{
        overflowX: "auto",
        background: isHome
          ? primaryPalette.blue.variant_01
          : primaryPalette.gradients.blue.branding,
      }}
    >
      <Search />
    </Box>
  );
};

export default SearchBar;
