import React from "react";
import { Box, Skeleton, Typography } from "@mui/material";
import { primaryPalette } from "../../../libs/styles/theme";
import { VariantIcon } from "../../common/icons/VariantIcon";
import { FlexRowDiv } from "../../common/Flex";
import { useGetRelatedVariantsQuery } from "../../../network/variants/variants";
import { ErrorCard } from "../../common/ErrorCard";
import { Modals } from "../../../store/slices/modalSlice";
import { useOpenSelectedModal } from "../../modals/modalHooks";
import { isProUser } from "../../../libs/utils/user";
import Paywall from "../../common/paywalls/Paywall";
import { useAppSelector } from "../../../store/hooks";
import { SearchBarTerms } from "../../../store/slices/searchSlice";
import { skipToken } from "@reduxjs/toolkit/query";
import { selectUrlTermIds } from "../../../store/selectors/urlSelectors";
import RelatedVariantsList from "./related-variants-list";

export const relatedVariantsErrorText =
  "We had trouble retrieving the related variants for this search.";
export const relatedVariantsErrorButtonText = "Contact Mastermind Support";

const RelatedVariantsContainer = () => {
  const { urlTermBooleans, urlCats, urlSigTerms } = useAppSelector(
    (state) => state.url
  );
  const urlTermIds = useAppSelector((state) => selectUrlTermIds(state));
  const openSelectedModal = useOpenSelectedModal();
  const { user } = useAppSelector((state) => state.user);
  const {
    data: relatedVariants,
    isLoading: isRelatedVariantDataLoading,
    isError: isRelatedVariantDataError,
  } = useGetRelatedVariantsQuery(
    urlTermIds[SearchBarTerms.gene].length > 0 ||
      urlTermIds[SearchBarTerms.variant].length > 0
      ? {
          gene: urlTermIds[SearchBarTerms.gene],
          variant: urlTermIds[SearchBarTerms.variant],
          urlTermIds: urlTermIds,
          urlBooleanParams: urlTermBooleans,
          urlCats: urlCats,
          urlSigTerms: urlSigTerms,
        }
      : skipToken
  );

  const renderVariantList = () => {
    if (isRelatedVariantDataLoading) {
      return (
        <Skeleton
          data-testid="related-variants-skeleton"
          variant="rectangular"
          height={"300px"}
        />
      );
    }

    if (isRelatedVariantDataError) {
      return (
        <ErrorCard
          bodyText={relatedVariantsErrorText}
          buttonText={relatedVariantsErrorButtonText}
          buttonFunction={() => openSelectedModal(Modals.contactUs)}
          testid="related-variants-list-error-card"
        />
      );
    }

    if (!relatedVariants || relatedVariants.length === 0) {
      return (
        <Typography
          variant="text12"
          color={primaryPalette.grey.neutral_grey_g7}
        >
          No related variants found
        </Typography>
      );
    }

    return <RelatedVariantsList variants={relatedVariants} />;
  };

  return (
    <Box>
      <FlexRowDiv style={{ gap: "2px" }}>
        <VariantIcon height={"12px"} width={"12px"} />
        <Typography
          variant={"text12"}
          sx={{
            color: primaryPalette.teal.neutral_teal_t4,
            textTransform: "uppercase",
          }}
        >
          Related Variants
        </Typography>
      </FlexRowDiv>
      {!isProUser(user) && (
        <Paywall
          titleText="Pro Edition Feature"
          bodyText="Genomenon includes classifications for related variants"
          id="related-variants-paywall"
        />
      )}
      {renderVariantList()}
    </Box>
  );
};

export default RelatedVariantsContainer;
