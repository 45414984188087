import React from "react";
import { Box, Grid } from "@mui/material";
import { primaryPalette } from "../../../../libs/styles/theme";
import {
  ACMGCategories,
  UnclassifiedCategories,
} from "../../../../libs/types/variant";
import { useAppSelector } from "../../../../store/hooks";
import { selectEvidenceViewerArticleListData } from "../../../../store/selectors/curatedEvidenceSelectors";
import { acmgCategoryIconMap } from "../../constants";
import ArticleListSection from "./ArticleListSection";
import ArticleListSectionEmptyState from "./ArticleListSectionEmptyState";
import EvidenceViewerArticleCard from "./EvidenceViewerArticleCard";

type EvidenceViewerArticleListProps = {
  onArticleChange: (pmid: string) => void;
};

const EvidenceViewerArticleList = ({
  onArticleChange,
}: EvidenceViewerArticleListProps) => {
  const cevArticleListData = useAppSelector((state) =>
    selectEvidenceViewerArticleListData(state)
  );
  const clinVarPmids = useAppSelector(
    (state) => state.articles.clinVarArticles?.pmids
  );

  if (!cevArticleListData) {
    return null;
  }

  const evidenceClinical = cevArticleListData[ACMGCategories.CLINICAL];
  const evidenceFunctional = cevArticleListData[ACMGCategories.FUNCTIONAL];

  return (
    <Box
      data-testid="evidence-viewer-article-list"
      py={3}
      px={2}
      sx={{
        backgroundColor: primaryPalette.brown.neutral_brown_b2,
        borderRadius: "0 0 16px 16px",
      }}
    >
      <Grid container columnSpacing={3}>
        {/* We anticipate the largest number of curated articles will be classified as
        "Clinical" therefore it has been given the entire left side column */}
        <Grid item xs={12} sm={6} md={12} xl={6}>
          <ArticleListSection
            label={ACMGCategories.CLINICAL}
            icon={acmgCategoryIconMap[ACMGCategories.CLINICAL]}
          >
            {Object.keys(evidenceClinical).length > 0 ? (
              <>
                {Object.entries(evidenceClinical).map(
                  ([pmid, article], index) => (
                    <EvidenceViewerArticleCard
                      key={`${ACMGCategories.CLINICAL}-${index}`}
                      articleData={article}
                      pmid={pmid}
                      showClinVarBadge={!!clinVarPmids[Object.keys(article)[0]]}
                      onArticleChange={onArticleChange}
                    />
                  )
                )}
              </>
            ) : (
              <ArticleListSectionEmptyState label={ACMGCategories.CLINICAL} />
            )}
          </ArticleListSection>
        </Grid>

        <Grid item xs={12} sm={6} md={12} xl={6}>
          <ArticleListSection
            label={ACMGCategories.FUNCTIONAL}
            icon={acmgCategoryIconMap[ACMGCategories.FUNCTIONAL]}
          >
            {Object.keys(evidenceFunctional).length > 0 ? (
              <>
                {Object.entries(evidenceFunctional).map(
                  ([pmid, article], index) => (
                    <EvidenceViewerArticleCard
                      key={`${ACMGCategories.FUNCTIONAL}-${index}`}
                      articleData={article}
                      pmid={pmid}
                      showClinVarBadge={!!clinVarPmids[Object.keys(article)[0]]}
                      onArticleChange={onArticleChange}
                    />
                  )
                )}
              </>
            ) : (
              <ArticleListSectionEmptyState label={ACMGCategories.FUNCTIONAL} />
            )}
          </ArticleListSection>

          {[
            ACMGCategories.INTRINSIC,
            ACMGCategories.POPULATION,
            ACMGCategories.COMPUTATIONAL,
            UnclassifiedCategories.UNCLASSIFIED,
          ].map((section) => {
            if (Object.keys(cevArticleListData[section]).length > 0) {
              return (
                <ArticleListSection
                  label={section}
                  icon={acmgCategoryIconMap[section]}
                  key={`${section}-section`}
                >
                  {Object.entries(cevArticleListData[section]).map(
                    ([pmid, article], index) => (
                      <EvidenceViewerArticleCard
                        key={`${section}-${index}`}
                        articleData={article}
                        pmid={pmid}
                        showClinVarBadge={
                          !!clinVarPmids[Object.keys(article)[0]]
                        }
                        onArticleChange={onArticleChange}
                      />
                    )
                  )}
                </ArticleListSection>
              );
            } else {
              return null;
            }
          })}
        </Grid>
      </Grid>
    </Box>
  );
};

export default EvidenceViewerArticleList;
