import React, { useState, FormEvent } from "react";
import { Button, Typography, TextField, Link } from "@mui/material";
import { userSignIn } from "../../../network/user";
import { loginUser } from "../../../store/slices/userSlice";
import { useAppDispatch } from "../../../store/hooks";
import ModalBox from "../../common/ModalBox";
import axios, { HttpStatusCode } from "axios";
import { closeModal, Modals } from "../../../store/slices/modalSlice";
import { useOpenSelectedModal } from "../modalHooks";
import { UserRoles } from "../../../libs/types/user";
import { useLocation } from "react-router-dom";
import { theme } from "../../../libs/styles/theme";
import { useHandleError } from "../../../libs/hooks/errorHandlerHook";
import { useSnackbar } from "notistack";
import { splitFeatureFlags } from "../../../libs/utils/splitFeatures";

const LoginModal = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [unauthorizedError, setUnauthorizedError] = useState(false);
  const dispatch = useAppDispatch();
  const openSelectedModal = useOpenSelectedModal();
  const location = useLocation();
  const handleError = useHandleError();

  const resetForm = () => {
    setEmailError(false);
    setPasswordError(false);
    setUnauthorizedError(false);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setEmailError(false);
    setPasswordError(false);

    if (email === "") {
      setEmailError(true);
    }
    if (password === "") {
      setPasswordError(true);
    }

    if (email && password) {
      resetForm();
      userSignIn({ user: { email: email, password: password } })
        .then(async (res) => {
          dispatch(loginUser(res.data));
          await splitFeatureFlags(dispatch, handleError);
          dispatch(closeModal());
          enqueueSnackbar("Welcome to Mastermind!", { variant: "success" });
          // Check user role and redirect if necessary
          if (
            res?.data?.user_role_names &&
            (res.data.user_role_names.includes(UserRoles.SUSPENDED) ||
              res.data.user_role_names.includes(UserRoles.EXPIRED))
          ) {
            // Redirect to user profile page
            window.location.assign("/users/profile");
          }
        })
        .catch((err) => {
          if (axios.isAxiosError(err)) {
            if (
              (err?.response?.status as HttpStatusCode) ===
              HttpStatusCode.Unauthorized
            ) {
              setUnauthorizedError(true);
            } else {
              const errData = err?.response?.data as { error: string };
              if (errData?.error === "otp required") {
                openSelectedModal(Modals.otp);
              }
            }
          } else {
            handleError(err);
          }
        });
    }
  };

  return (
    <ModalBox>
      <form
        onSubmit={handleSubmit}
        aria-label="login-form"
        id="login-form"
        noValidate
        data-testid={"login-modal-form"}
      >
        <Typography
          gutterBottom={true}
          id="modal-modal-title"
          variant="h3"
          style={{
            textAlign: "center",
            marginBottom: "0.75em",
          }}
        >
          Welcome to Mastermind!
        </Typography>
        <TextField
          inputProps={{ "data-testid": "login-modal-email" }}
          label="Email"
          onChange={(e) => setEmail(e.target.value)}
          required
          variant="outlined"
          color="primary"
          type="email"
          sx={{ mb: 3 }}
          fullWidth
          value={email}
          error={emailError}
          helperText={emailError ? "Email is required" : ""}
        />
        <TextField
          label="Password"
          inputProps={{ "data-testid": "login-modal-password" }}
          onChange={(e) => setPassword(e.target.value)}
          required
          variant="outlined"
          color="primary"
          type="password"
          value={password}
          error={passwordError}
          fullWidth
          sx={{ mb: 3 }}
          helperText={passwordError ? "Password is required" : ""}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            type="submit"
            data-testid="login-modal-submit"
          >
            Login
          </Button>
        </div>
      </form>
      {unauthorizedError ? (
        <>
          <Typography color={theme.palette.warning.main}>
            Invalid email or password
          </Typography>
        </>
      ) : (
        <></>
      )}
      <Typography variant={"text12"}>
        Forgot your password?&nbsp;
        <Link href="/users/password/new/">Reset here</Link>
      </Typography>
      <div>
        <Typography variant={"text14"}>New to Mastermind?</Typography>
        &nbsp;
        <Link
          variant={"text14"}
          href={`/users/sign_up?last_path=${location.pathname}${location.search}`}
        >
          Create an Account
        </Link>
      </div>
    </ModalBox>
  );
};

export default LoginModal;
