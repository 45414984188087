import { TooltipProps, Tooltip } from "@mui/material";
import { primaryPalette, boxShadowStyles } from "../../../libs/styles/theme";
import React from "react";
import { PopperProps } from "@mui/material/Popper/BasePopper.types";

type TooltipComponentProps = {
  children: React.ReactElement<any, any>;
  content: React.ReactNode;
  placement: TooltipProps["placement"];
  popperProps: Partial<PopperProps>;
  slotProps?: TooltipProps["slotProps"];
  textTransform?: string;
  minWidth?: number | string;
  testId?: string;
};

const TooltipComponent = (props: TooltipComponentProps) => {
  const {
    children,
    content,
    placement,
    popperProps,
    slotProps,
    textTransform = "none",
    minWidth = 300,
    testId,
  } = props;

  return (
    <Tooltip
      data-testid={testId}
      title={content}
      placement={placement}
      arrow
      PopperProps={popperProps}
      slotProps={{
        ...slotProps,
        tooltip: {
          sx: {
            fontSize: "0.75rem",
            minWidth: minWidth,
            padding: "0.625rem 1.25rem",
            color: primaryPalette.grey.neutral_grey_g8,
            backgroundColor: primaryPalette.grey.neutral_grey_g1,
            boxShadow: boxShadowStyles.default,
            textTransform: textTransform,
            "& .MuiTooltip-arrow": {
              "&::before": {
                backgroundColor: primaryPalette.grey.neutral_grey_g1,
              },
            },
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );
};

export default TooltipComponent;
