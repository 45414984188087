import { DuplicateCategory } from "../../../store/slices/categoryLayoutSlice";
import { Categories } from "../../../store/slices/searchSlice";

/**
 * Check if there are duplicates with the specified name in the given array of duplicates.
 *
 * @param name - The display name to check for duplicates.
 * @param duplicates - The array of duplicate categories.
 * @returns A boolean indicating whether duplicates with the specified name exist.
 */
export const hasDuplicates = (
  name: string,
  duplicates: DuplicateCategory[]
): boolean => {
  return duplicates.some((duplication) => duplication.key === name);
};

/**
 * Get duplicate keywords based on the specified name and key from an array of duplicates.
 *
 * @param name - The name to identify duplicates.
 * @param key - The unique key associated with the entry.
 * @param duplicates - The array of duplicate categories.
 * @returns An array of duplicate keywords excluding the specified key.
 */
export const getDuplicateKeywords = (
  name: string,
  key: string,
  duplicates: DuplicateCategory[]
): string[] => {
  const duplicateRow = duplicates.find(
    (duplication) => duplication.key === name
  );

  const rows = duplicateRow?.categories || [];

  // Filter out the rows that have no same key to match up the duplicate entries only.
  return rows
    .filter((row) => {
      const keys = row.split(", ");
      return key !== keys[0];
    })
    .map((filteredRow) => filteredRow);
};

/**
 * Select duplicates based on specified criteria.
 *
 * @param name - The display name to identify duplicates.
 * @param key - The category key associated with cateogry.
 * @param cats - The object containing selected categories.
 * @param duplicates - The array of duplicate categories.
 * @param selectedCategories - The object containing previously selected categories.
 * @returns An object with updated selected categories.
 */
export const selectDuplicates = (
  name: string,
  key: string,
  cats: Categories,
  duplicates: DuplicateCategory[],
  selectedCategories: Categories
): { [key: string]: { key: string } } => {
  // Get duplicate keywords based on name and key
  const duplicateKeywords = getDuplicateKeywords(name, key, duplicates);

  for (const row of duplicateKeywords) {
    const keys = row.split(", ");

    // Check if any key is already present in the selected categories or current selection
    if (
      !keys.some((k) => k in selectedCategories) &&
      !keys.some((k) => k in cats)
    ) {
      // Add the key to the selected categories if not present
      cats[keys[0]] = {
        key: keys[0],
      };
    }
  }

  return cats;
};

/**
 * De-select duplicates based on specified criteria.
 *
 * @param name - The display name to identify duplicates.
 * @param key - The category key associated with cateogry.
 * @param cats - The object containing selected categories.
 * @param categories - The array of all categories.
 * @param duplicates - The array of duplicate categories.
 * @returns An object with updated selected categories.
 */
export const deSelectDuplicates = (
  name: string,
  key: string,
  cats: Categories,
  duplicates: DuplicateCategory[]
): { [key: string]: { key: string } } => {
  const duplicateKeywords = getDuplicateKeywords(name, key, duplicates);

  for (const row of duplicateKeywords) {
    const keys = row.split(", ");

    // Remove the primary key if it exists
    if (cats[keys[0]]) {
      delete cats[keys[0]];
    }
  }

  return cats;
};

/**
 * Get the display name if there is a duplicate entry present.
 *
 * @param value - The display name to identify duplicates.
 * @param duplicates - The array of duplicate categories.
 * @returns A string if it presents else returns empty string.
 */
const findKeyByValue = (
  value: string,
  duplicates: DuplicateCategory[]
): string => {
  for (const dup of duplicates) {
    if (dup.categories.some((item) => item.split(", ")[0] === value)) {
      return dup.key;
    }
  }
  return ""; // If the value is not found in any array
};

/**
 * Display search keyword based on duplicat selected or not.
 *
 * @param name - The display name to identify duplicates.
 * @param key - The category key associated with cateogry.
 * @param cats - The object containing selected categories.
 * @param duplicates - The array of duplicate categories.
 * @returns A boolean with that determines the display of the selected catgory in search bar.
 */
export const shouldRenderSearchBump = (
  termKey: string,
  cats: Categories,
  duplicates: DuplicateCategory[]
): boolean => {
  const termName = findKeyByValue(termKey, duplicates);
  if (termName) {
    const duplication = duplicates.find((dup) => dup.key === termName);
    const rows = duplication?.categories || [];
    if (rows) {
      for (const row of rows) {
        const [, , parentGroup, subParentGroup] = row.split(", ");

        if (cats[parentGroup] || cats[subParentGroup]) {
          // If parent selected, return false
          return false;
        }
      }
    }
    if (termKey !== termName) {
      return false;
    }
  }

  return true; // Default behavior is to render
};
