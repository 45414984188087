import { TableRow, TableCell, Box, Link } from "@mui/material";
import React from "react";
import { VariantResult } from "../../../../libs/models/variant-result";
import { styled } from "styled-components";
import { primaryPalette } from "../../../../libs/styles/theme";
import { useLazyGetGenomicPositionQuery } from "../../../../network/variants/variants";
import { GenomicPosition } from "../genomic-position/GenomicPosition";
import { Add, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import GLogo from "../../../common/icons/SmallGLogo";
import ClinVarIcon from "../../../common/icons/SmallClinVarIcon";
import { useHandleError } from "../../../../libs/hooks/errorHandlerHook";
import { FlexRowDiv } from "../../../common/Flex";
import { addTag } from "../../../../store/slices/searchSlice";
import { useAppDispatch } from "../../../../store/hooks";
import { Suggestion } from "../../../../network/suggestions";

const VariantNameDiv = styled.div((props) => ({
  display: "flex",
  padding: "2px 6px",
  gap: "8px",
  borderRadius: "2px",
  background: props.color,
  width: "80px",
  color: "white",
  justifyContent: "center",
}));

type VariantListRowProps = {
  id: string;
  mutation: string;
  cdnaPosition: string | null;
  totalHits: number;
  type: string;
  articleUrl: string;
  backgroundColor: string;
  variantResult: VariantResult;
  hasClinVar: boolean;
  hasCurated: boolean;
};

export function VariantListRow({
  id,
  mutation,
  cdnaPosition,
  totalHits,
  type,
  articleUrl,
  backgroundColor,
  variantResult,
  hasClinVar,
  hasCurated,
}: VariantListRowProps) {
  const [trigger, { data: genomicPositionData, isLoading }] =
    useLazyGetGenomicPositionQuery();
  const [open, setOpen] = React.useState(false);
  const handleError = useHandleError();
  const dispatch = useAppDispatch();

  const handleCdnaClick = () => {
    if (!open) {
      void trigger(variantResult, true).catch((err) => handleError(err));
    }
    setOpen(!open);
  };

  const addVariantToSearch = (vr: VariantResult) => {
    const sugg = {
      type: "variant",
      id: vr.variantName,
      text: `${vr.gene.toUpperCase()}:${VariantResult.variantCasing(
        vr.mutation
      )}`,
    } as Suggestion;

    dispatch(addTag({ suggestion: sugg, shouldReplace: false }));
  };

  return (
    <>
      <TableRow
        key={id}
        aria-label={"variant-row"}
        data-testid={`variant-row-${id}`}
        className="variant-row"
        sx={{
          backgroundColor: primaryPalette.grey.neutral_grey_g1,
          "&:nth-of-type(4n + 3)": {
            backgroundColor: primaryPalette.brown.variant_01,
          },
          "&:last-child td, &:last-child th": { border: 0 },
          "& > *": { borderBottom: "unset" },
        }}
      >
        <TableCell
          data-testid={`variant-case-${id}`}
          component="td"
          scope="row"
          onClick={() => addVariantToSearch(variantResult)}
          sx={{ cursor: "pointer", borderBottom: "unset" }}
        >
          <FlexRowDiv>
            <VariantNameDiv color={backgroundColor}>
              {VariantResult.variantCasing(mutation)}
            </VariantNameDiv>
            <Add fontSize="small" />
          </FlexRowDiv>
        </TableCell>
        <TableCell data-testid={`variant-type-${id}`} align="left">
          {type}
        </TableCell>
        <TableCell
          data-testid={`cdna-position-${id}`}
          align="left"
          onClick={() => handleCdnaClick()}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              cursor: "pointer",
            }}
          >
            {cdnaPosition}{" "}
            {open ? (
              <KeyboardArrowUp sx={{ width: ".75em", height: ".75em " }} />
            ) : (
              <KeyboardArrowDown sx={{ width: ".75em", height: ".75em " }} />
            )}
          </Box>
        </TableCell>
        <TableCell
          data-testid={`total-articles-${id}`}
          align="right"
          sx={{
            display: "flex",
            border: "none",
            width: "fit-content",
            marginLeft: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "15px",
              width: "90px",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
              {!hasCurated && <div style={{ width: "1em" }} />}
              {hasCurated && (
                <Link
                  className={"GenomenonLink"}
                  href={articleUrl + "&tab=curated"}
                  underline="none"
                  target="_blank"
                >
                  <GLogo />
                </Link>
              )}
              {!hasClinVar && <div style={{ width: "1em" }} />}
              {hasClinVar && (
                <Link
                  className={"ClinVarLink"}
                  href={articleUrl + "&tab=clinvar"}
                  underline="none"
                  target="_blank"
                >
                  <ClinVarIcon />
                </Link>
              )}
            </Box>
            <Box>
              <Link
                className={"variantSearch"}
                href={articleUrl}
                underline="none"
                target="_blank"
              >
                {totalHits}
              </Link>
            </Box>
          </Box>
        </TableCell>
      </TableRow>
      {!isLoading && (
        <GenomicPosition
          open={open}
          genomicPosData={genomicPositionData?.transcript_position}
          variantResult={variantResult}
        />
      )}
    </>
  );
}
