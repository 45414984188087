import React, { CSSProperties, MouseEventHandler } from "react";
import { Box, Link, Typography } from "@mui/material";
import { GENOMENON_EMAIL_SALES } from "../../../libs/constants";
import { primaryPalette } from "../../../libs/styles/theme";
import { getProSignUpLink } from "../../../libs/utils/links";
import { useAppSelector } from "../../../store/hooks";
import { Modals } from "../../../store/slices/modalSlice";
import { useOpenSelectedModal } from "../../modals/modalHooks";
import GLogo from "../icons/SmallGLogo";

type PaywallProps = {
  titleText: string;
  bodyText: string;
  contactSales?: boolean;
  id?: string;
  styles?: CSSProperties;
  onMouseDown?: MouseEventHandler;
};

const linkStyles = {
  color: primaryPalette.focus.variant_02,
  lineHeight: "13px",
  textDecoration: "underline",
};

const Paywall = ({
  titleText,
  bodyText,
  contactSales = false,
  id,
  styles,
  onMouseDown,
}: PaywallProps) => {
  const { isLoggedIn, user } = useAppSelector((state) => state.user);
  const openSelectedModal = useOpenSelectedModal();
  const upgradeLink = contactSales
    ? `mailto:${GENOMENON_EMAIL_SALES}`
    : getProSignUpLink(user);

  return (
    <Box
      id={id}
      data-testid={id ? id : "paywall-card"}
      display="flex"
      flexDirection="column"
      borderRadius="0 0 6px 6px"
      p={1}
      fontSize="0.8rem"
      sx={{
        backgroundColor: primaryPalette.blue.variant_01,
        ...styles,
      }}
      onMouseDown={onMouseDown}
    >
      <Typography
        variant="text11"
        fontWeight="fontWeightBold"
        style={{
          textTransform: "uppercase",
          color: primaryPalette.focus.variant_02,
          lineHeight: "13px",
        }}
      >
        {titleText}
      </Typography>

      <Typography
        variant="text14"
        style={{ color: `${primaryPalette.teal.neutral_teal_t2}` }}
      >
        {bodyText}
      </Typography>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt="5px"
      >
        <Box display="flex" alignItems="center" gap="5px">
          {!isLoggedIn ? (
            <>
              <Link
                onClick={() => openSelectedModal(Modals.login, undefined, true)}
                style={{
                  ...linkStyles,
                }}
              >
                Log in
              </Link>
              <Typography
                variant="text11"
                style={{
                  color: primaryPalette.focus.variant_02,
                  lineHeight: "13px",
                }}
              >
                or
              </Typography>
            </>
          ) : null}

          <Link
            href={upgradeLink}
            target={contactSales ? "_self" : "_blank"}
            style={{
              ...linkStyles,
            }}
          >
            {contactSales
              ? "Contact sales@genomenon.com to purchase license."
              : "Sign up for full access"}
          </Link>
        </Box>
        <GLogo />
      </Box>
    </Box>
  );
};

export default Paywall;
