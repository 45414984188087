import React from "react";
import { List, ListItem, Typography } from "@mui/material";
import { SearchExampleData } from "..";
import HomePageLink from "../HomePageLink";

const SearchExampleSection = ({ title, examples }: SearchExampleData) => {
  return (
    <List
      dense
      sx={{
        listStyleType: "disc",
      }}
      subheader={
        <Typography component="h2" variant="text14" fontWeight="normal">
          {title}
        </Typography>
      }
    >
      {examples.map((ex, index) => (
        <ListItem
          key={`${ex.searchType}-${index}`}
          sx={{
            display: "list-item",
            ml: 3,
            py: "2px",
            "&::marker": {
              fontSize: ".7rem",
            },
          }}
        >
          <HomePageLink
            searchType={ex.searchType}
            href={`${ex.href}&search_source=homepage_example`}
          >
            {ex.label}
          </HomePageLink>
        </ListItem>
      ))}
    </List>
  );
};

export default SearchExampleSection;
