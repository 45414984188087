export enum UrlParams {
  GENE = "gene",
  VARIANT = "mutation",
  DISEASE = "disease",
  KEYWORD = "keyword",
  HPO = "hpo",
  UNII = "unii",
  CNV = "cnv",
  CATEGORY = "cats",
  SELECTED_CURATED_VARIANT = "selected_curated_variant",
  SIGTERM = "sig_terms",
  PMID = "pmid",
  VIEW = "view",
  ARTICLE_LIST_FILTER = "article_list_filter",
  ARTICLE_LIST_SEARCH = "article_list_search",
  ARTICLE_SORT = "article_sort",
  ARTICLE_SORT_ASC = "article_sort_asc",
}

export type UrlParamKeys = keyof typeof UrlParams;

export enum UrlBoolParams {
  GENE = "gene_op",
  VARIANT = "mutation_op",
  DISEASE = "disease_op",
  KEYWORD = "keyword_op",
  HPO = "hpo_op",
  UNII = "unii_op",
  CNV = "cnv_op",
}

export type UrlBoolParamKeys = keyof typeof UrlBoolParams;
