import { Stack } from "@mui/material";
import React from "react";
import { EvidenceViewerArticleCardFooterProps } from "./types";
import AdditionalCuratorContext from "./AdditionalCuratorContext";
import AssociatedDiseases from "./AssociatedDiseases";
import CuratedDataCriteriaHeader from "./CuratedDataCriteriaHeader";
import DiscreteArticleData from "./DiscreteArticleData";

const EvidenceViewerArticleCardFooter = ({
  data,
  projectType,
}: EvidenceViewerArticleCardFooterProps) => {
  if (!data || data.length === 0) {
    return null;
  }

  return (
    <Stack gap={1} mt={0.5}>
      {data.map((obj, idx) => (
        <Stack key={`${obj.pmid}-${obj.acmg_call}-${idx}`}>
          <CuratedDataCriteriaHeader discreteData={obj} />
          <DiscreteArticleData discreteData={obj} />
          <AssociatedDiseases discreteData={obj} />
          <AdditionalCuratorContext
            discreteData={obj}
            projectType={projectType}
          />
        </Stack>
      ))}
    </Stack>
  );
};

export default EvidenceViewerArticleCardFooter;
