import { Menu } from "@mui/material";
import React from "react";
import {
  MenuItem,
  sortItems,
} from "../../../../../../store/slices/articleSlice";
import ListMenuItem from "../ListMenuItem";

export interface SortProps {
  sortAnchorEl: null | HTMLElement;
  sortOpen: boolean;
  handleSortItemClick: (item: MenuItem) => void;
  handleClose: () => void;
}

function SortMenu({
  sortAnchorEl,
  sortOpen,
  handleSortItemClick,
  handleClose,
}: SortProps) {
  return (
    <Menu
      data-testid="article-list-sort-menu"
      anchorEl={sortAnchorEl}
      open={sortOpen}
      onClose={handleClose}
    >
      {sortItems.map((item) => (
        <ListMenuItem
          key={item.id}
          type="sort"
          item={item}
          onClick={handleSortItemClick}
        />
      ))}
    </Menu>
  );
}

export default SortMenu;
