import { z } from "zod";

// TODO, need exhaustive list from GUAC/GCAT
export const CuratedDataObjectSchema = z.object({
  in_trans: z.string().or(z.number()),
  na_type: z.string().or(z.number()),
  probands: z.string().or(z.number()),
  unrelated_proband: z.string().or(z.number()),
  zygosity: z.string().or(z.number()),
  alleles: z.string().or(z.number()),
  prev_pmid: z.string().or(z.number()),
  unweighted_reason: z.string().or(z.number()),
  splicing_impact: z.string().or(z.number()),
  impact_degree: z.string().or(z.number()),
  impact_type: z.string().or(z.number()),
  other_impact: z.string().or(z.number()),
  segregation_type: z.string().or(z.number()),
  meioses: z.string().or(z.number()),
  affected: z.string().or(z.number()),
  subpopulation: z.string().or(z.number()),
  allele_count: z.string().or(z.number()),
  total_alleles: z.string().or(z.number()),
  carrier_age: z.string().or(z.number()),
  unaffected: z.string().or(z.number()),
  value: z.string().or(z.number()),
  unit_test: z.string().or(z.number()),
  model: z.string().or(z.number()),
  cell_type: z.string().or(z.number()),
  exclude: z.string().or(z.number()),
  functional_impact: z.string().or(z.number()),
  functional_criteria: z.string().or(z.number()),
  category: z.string().or(z.number()),
  strength: z.string().or(z.number()),
  de_novo_type: z.string().or(z.number()),
  de_novo_probands: z.string().or(z.number()),
  prediction_model: z.string().or(z.number()),
  score: z.string().or(z.number()),
});

const SummarizedCountsSchema = z.object({
  weighted_probands: z.number(),
  meioses: z.number(),
  families_with_segregation: z.number(),
});

const ACMGLitItemEvidenceSchema = z.object({
  body: z.string(),
  new_data: z.boolean().nullable(),
});

const ACMGEvidenceTags = z.object({
  description: z.string(),
  name: z.string(),
});

const ACMGLitItemSchema = z.object({
  context: z.string().nullish(),
  data: CuratedDataObjectSchema.partial().optional(),
  evidence: ACMGLitItemEvidenceSchema.or(z.string()), // may be able to remove once CEV released and data portal projects all re-released
  evidence_tags: z.array(ACMGEvidenceTags).optional(),
  evidence_text: z.string().nullish(),
  evidence_type: z.string().optional(),
  pmid: z.string(),
});

export const ACMGCriteriaSchema = z.object({
  call: z.boolean(),
  caution: z.string().optional(), // may be able to remove once CEV released and data portal projects all re-released
  explanation: z.string().optional(), // may be able to remove once CEV released and data portal projects all re-released
  lit_call: z.boolean().nullish(),
  lit_items: z.array(ACMGLitItemSchema).optional(),
  non_lit_call: z.boolean().nullable().optional(),
  lit_nonlit_conflict: z.boolean().optional(),
  original_lit_item_count: z.number().optional(),
  short_desc: z.string(),
  strength: z.string().nullable().optional(), // may be able to remove once CEV released and data portal projects all re-released
  summarized_counts: SummarizedCountsSchema.partial().optional(),
  type: z.string().optional(), // may be able to remove once CEV released and data portal projects all re-released
});

export const PMIDDiscreteDataSchema = z.object({
  type: z.string().nullable(),
  acmg_call: z.string(),
  strength: z.string().nullish().optional(),
  context: z.string().nullish(),
  data: CuratedDataObjectSchema.partial().optional(),
  evidence: z.string(),
  evidence_type: z.string().optional(),
  pmid: z.string(),
  evidence_tags: z.array(
    z.object({
      description: z.string(),
      name: z.string(),
    })
  ),
  evidence_text: z.string().nullish(),
});

export const CuratedPMIDSchema = z.object({
  discrete_data: z.array(PMIDDiscreteDataSchema),
  date: z.string().nullish(),
  primary_author: z.string(),
  journal: z.string(),
  title: z.string().nullish(),
  projectType: z.string().optional(),
});

export const DiseaseTreatmentSchema = z.object({
  sponsored_therapies: z.array(
    z.object({
      description: z.string(),
      name: z.string(),
      url: z.string(),
      generic: z.string(),
    })
  ),
  treatment_resources: z.array(
    z.object({
      label: z.string(),
      url: z.string(),
    })
  ),
});

export const DiseaseResourceSchema = z.object({
  label: z.string(),
  url: z.string(),
  description: z.string(),
});

export const ReporterCuratedDataSchema = z.object({
  curationRecords: z.object({
    curatedFor: z.string(),
    gene: z.string(),
    isEnterprise: z.boolean(),
    isSponsored: z.boolean(),
    variants: z.array(
      z.object({
        acmg_call: z.string(),
        article_count: z.number(),
        gene: z.string(),
        id: z.string(),
        type: z.string(),
      })
    ),
  }),
});

export const ReporterClinvarDataSchema = z.object({
  clinVarRecords: z.array(
    z.object({
      citations: z.number(),
      description: z.string(),
      displayName: z.string(),
      fullName: z.string(),
      id: z.string(),
      interpretation: z.string(),
      lastEvaluated: z.string(),
      mostRecent: z.string(),
      reviewStars: z.number(),
      reviewStatus: z.string(),
      submissions: z.number(),
      submitterCalls: z.array(
        z.object({
          call: z.string(),
          submitter: z.string(),
        })
      ),
      url: z.string(),
    })
  ),
});

const ReporterGnomadPopulationDataSchema = z.object({
  ac: z.number().optional(),
  af: z.number().optional(),
  an: z.number().optional(),
});

const ReporterGnomadVepRecordsDataSchema = z.object({
  consequences: z.array(z.string()),
  gene: z.string(),
  hgvsp: z.string(),
});

// matches type GnomADVCFRecord in mm-data-go `gnomad.go`
export const ReporterGnomadDataSchema = z.object({
  chrom: z.string(),
  pos: z.number(),
  ref: z.string(),
  alt: z.string(),
  ac: z.number(),
  af: z.number(),
  an: z.number(),
  numHomo: z.number().optional(),
  rsID: z.string().optional(),
  qual: z.number().optional(),
  filter: z.string().optional(),
  populations: z.object({
    afr: ReporterGnomadPopulationDataSchema.optional(),
    amr: ReporterGnomadPopulationDataSchema.optional(),
    asj: ReporterGnomadPopulationDataSchema.optional(),
    eas: ReporterGnomadPopulationDataSchema.optional(),
    fin: ReporterGnomadPopulationDataSchema.optional(),
    nfe: ReporterGnomadPopulationDataSchema.optional(),
    oth: ReporterGnomadPopulationDataSchema.optional(),
    sas: ReporterGnomadPopulationDataSchema.optional(),
  }),
  dataset: z.string().optional(),
  vepRecords: z.array(ReporterGnomadVepRecordsDataSchema).optional(),
});

export const EvidenceViewerRecordsSchema = z
  .object({
    record: z
      .object({
        acmg_call: z.string(),
        data_portal_project_release_id: z.number(),
        data_portal_project_variant_id: z.number(),
        disease: z.object({
          clingen: z.any(),
          display_name: z.string(),
          resources: z.array(DiseaseResourceSchema).optional(),
          tags: z.any(),
          treatments: DiseaseTreatmentSchema.optional(),
        }),
        gene_symbol: z.string(),
        gnomad_json: z.array(ReporterGnomadDataSchema.optional()).optional(),
        json_record: z.record(z.string(), ACMGCriteriaSchema),
        pmid_curated_data: z
          .record(z.string(), CuratedPMIDSchema)
          .optional()
          .nullable(),
        project_type: z.string(),
        review_date: z.string().optional(),
        status: z.string(),
        variant_id: z.string(),
      })
      .optional(),
    transcript: z.string().optional(),
  })
  .nullable();
