import { SvgIcon } from "@mui/material";
import React from "react";

export function BenignIcon({
  height,
  width,
}: {
  height?: string;
  width?: string;
}) {
  return (
    <SvgIcon style={{ height: height, width: width }}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 10 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.5782 2.44116C8.61992 2.34521 8.72905 2.30346 8.825 2.34375C9.23516 2.52187 9.5 2.92734 9.5 3.375V10.875C9.5 11.4954 8.99539 12 8.375 12H1.625C1.00461 12 0.5 11.4961 0.5 10.875V3.375C0.5 2.90859 0.765078 2.52422 1.17453 2.34609C1.27203 2.30203 1.38031 2.34609 1.42142 2.44125C1.46316 2.53643 1.41995 2.64703 1.32547 2.68805C1.05151 2.80805 0.875 3.07758 0.875 3.375V10.875C0.875 11.2889 1.21109 11.625 1.625 11.625H8.375C8.78891 11.625 9.125 11.2889 9.125 10.875V3.375C9.125 3.07734 8.94922 2.80781 8.67561 2.68795C8.5804 2.64623 8.53719 2.53563 8.5782 2.44116ZM5.375 1.875C5.375 2.08209 5.20709 2.25 5 2.25C4.79375 2.25 4.625 2.08219 4.625 1.875C4.625 1.66781 4.79375 1.5 5 1.5C5.20625 1.5 5.375 1.66781 5.375 1.875ZM7.25 3.75H2.75C2.33586 3.75 2 3.41484 2 3V2.6625C2 2.02055 2.52055 1.5 3.1625 1.5C3.33594 0.644063 4.09297 0 5 0C5.90703 0 6.64297 0.644063 6.8375 1.5C7.47969 1.5 8 2.02055 8 2.6625V3C8 3.41414 7.66414 3.75 7.25 3.75ZM3.1625 1.875C2.72773 1.875 2.375 2.22773 2.375 2.6625V3C2.375 3.20625 2.54281 3.375 2.75 3.375H7.25C7.45712 3.375 7.625 3.20712 7.625 3V2.6625C7.625 2.22773 7.25234 1.875 6.83703 1.875H6.53047L6.46944 1.57467C6.30233 0.752016 5.46725 0.196313 4.6017 0.427875C4.03428 0.57975 3.62919 1.08623 3.51233 1.66186L3.46906 1.875H3.1625ZM7.74487 6.35345C7.94013 6.15819 7.94013 5.8416 7.74487 5.64634L7.60345 5.50492C7.40819 5.30966 7.0916 5.30966 6.89634 5.50492L4.2499 8.15137L3.10345 7.00492C2.90819 6.80966 2.5916 6.80966 2.39634 7.00492L2.25492 7.14634C2.05966 7.3416 2.05966 7.65819 2.25492 7.85345L3.82563 9.42416C4.05995 9.65847 4.43985 9.65847 4.67416 9.42416L7.74487 6.35345Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
}
