import { primaryPalette } from "./theme";

export const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "15px",
  boxShadow: 24,
  p: 4,
  gap: "10px",
  bgcolor: "#DDDDDD",
  overflowY: "auto",
  minHeight: "65%",
  maxHeight: "85%",
  minWidth: "90%",
  padding: 0,
};

export const borderColor = primaryPalette.teal.variant_05;
export const hoverColor = primaryPalette.teal.variant_08;

export const keywordLabel = {
  fontSize: "16px",
  letterSpacing: "0.15008px",
  cursor: "pointer",
  verticalAlign: "middle",
  paddingLeft: "15px",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
};

export const keywordCheckbox = {
  height: "18px",
  width: "18px",
  marginRight: "20px",
  accentColor: primaryPalette.teal.neutral_teal_t6,
  border: `1px solid ${primaryPalette.teal.neutral_teal_t6}`,
  borderRadius: "4px",
  outline: "none",
  cursor: "pointer",
  color: primaryPalette.teal.neutral_teal_t6,
  outlineColor: primaryPalette.teal.neutral_teal_t6,
  textDecorationColor: primaryPalette.teal.neutral_teal_t6,
  verticalAlign: "middle",
};
