import React from "react";
import { Typography, Button, Menu, MenuItem } from "@mui/material";
import { primaryPalette } from "../../../../../libs/styles/theme";
import { GeneIcon } from "../../../../common/icons/GeneIcon";
import {
  GeneMatchElement,
  updateSelectedGeneMatch,
} from "../../../../../store/slices/featuredArticleSlice";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { selectGeneCount } from "../../../../../store/selectors/featuredArticleSelectors";
import { SearchBarTerms } from "../../../../../store/slices/searchSlice";
import { selectUrlTermIds } from "../../../../../store/selectors/urlSelectors";

const GeneMatch = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useAppDispatch();
  const urlTermIds = useAppSelector((state) => selectUrlTermIds(state));
  const geneCount = useAppSelector((state) => selectGeneCount(state));
  const geneMatch = useAppSelector(
    (state) => state.featuredArticle.selectedGeneMatch
  );
  const geneMatchElements: GeneMatchElement[] = [
    ...urlTermIds[SearchBarTerms.gene].map<GeneMatchElement>((gene) => {
      return {
        id: gene.toLowerCase(),
        label: gene.toUpperCase(),
      };
    }),
    {
      id: "all",
      label: "All Genes",
    },
  ];

  // set selected gene match value on page load to fetch count
  React.useEffect(() => {
    dispatch(updateSelectedGeneMatch(geneMatchElements[0]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <GeneIcon height={"14px"} width={"14px"} />
      &nbsp;
      <Typography
        variant="h6"
        color={primaryPalette.teal.neutral_teal_t4}
        data-testid="gene-match-label"
      >
        Gene Matches:
      </Typography>
      <Button
        onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
          handleClick(event)
        }
      >
        <Typography
          data-testid="gene-match-count"
          variant="text12"
          color={primaryPalette.grey.neutral_grey_g7}
        >
          {geneMatch?.label}: {geneCount}
        </Typography>
      </Button>
      <Menu
        id="gene-match-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {geneMatchElements.map((element) => {
          return (
            <MenuItem
              key={element.id}
              onClick={() => {
                dispatch(updateSelectedGeneMatch(element));
                handleClose();
              }}
            >
              <Typography
                variant="text12"
                color={primaryPalette.grey.neutral_grey_g7}
              >
                {element.label}
              </Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default GeneMatch;
