import React from "react";
import { Box } from "@mui/material";
import { ArticleViewerText } from "../../StyledViewerComponents";
import { primaryPalette } from "../../../../../libs/styles/theme";
import DOMPurify from "dompurify";
import ArticleHeader from "../ArticleHeader";

type ArticleParagraphProps = {
  label?: string;
  paragraph: string;
};

const ArticleParagraph = ({ label, paragraph }: ArticleParagraphProps) => {
  return (
    <Box>
      {label ? (
        <ArticleHeader customStyle={{ margin: "5px 0" }}>{label}</ArticleHeader>
      ) : null}

      <Box
        sx={{
          ".abstract highlight": {
            backgroundColor: primaryPalette.dataVis.oldMM,
          },
        }}
      >
        <ArticleViewerText
          className="abstract"
          sx={{ fontSize: "14px", fontWeight: "300", lineHeight: "22px" }}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(paragraph, {
              ALLOWED_TAGS: ["highlight"],
            }),
          }}
        />
      </Box>
    </Box>
  );
};

export default ArticleParagraph;
