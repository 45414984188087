import React from "react";
import styled from "styled-components";
import { BooleanSearchOptions } from "../../../../network/suggestions";
import { primaryPalette } from "../../../../libs/styles/theme";
import { Typography } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import DisplayLabel from "../../../common/DisplayLabel";

export const SearchBump = styled.div`
  box-shadow: 2px 4px 4px 0px rgba(1, 11, 10, 0.1),
    -2px -4px 4px 0px rgba(255, 252, 240, 0.1);
  background-color: inherit;
  color: ${primaryPalette.gray.variant_01};
  min-width: 40px;
  padding: 12px 16px;
  border-radius: 6px;
  max-width: 300px;
  margin-bottom: 12px;
  overflow-x: auto;
`;

const BooleanSearchSymbol = {
  [BooleanSearchOptions.AND]: "and",
  [BooleanSearchOptions.OR]: "or",
};

type SearchBumpProps = {
  type: string;
  text: string;
  id: string;
  isLast: boolean;
  boolConnector: BooleanSearchOptions;
  onClear: () => void;
  onEdit?: () => void;
  onSwapBool?: () => void;
};

const SearchBumpContents = ({
  type,
  text,
  id,
  isLast,
  boolConnector,
  onClear,
  onEdit,
  onSwapBool,
}: SearchBumpProps) => (
  <React.Fragment key={`${type}-${text}`}>
    <Typography
      data-testid={`search-tag-${type}-${id}`}
      style={{
        minWidth: "fit-content",
        cursor: onEdit !== undefined ? "pointer" : "default",
      }}
      variant={"text14"}
      color={primaryPalette.grey.neutral_grey_g1}
      onClick={onEdit}
    >
      <DisplayLabel value={text} />
    </Typography>
    <ClearIcon
      data-testid={`ClearIcon-${type}-${id}`}
      style={{ cursor: "pointer" }}
      fontSize={"small"}
      onClick={onClear}
    />
    {isLast ? (
      <div
        style={{
          cursor: onSwapBool !== undefined ? "pointer" : "default",
          display: "flex",
          alignItems: "center",
        }}
        onClick={onSwapBool}
        data-testid={`boolean-operator-${type}`}
      >
        <Typography variant={"text11"} style={{ textTransform: "uppercase" }}>
          {BooleanSearchSymbol[boolConnector]}
        </Typography>
      </div>
    ) : (
      <></>
    )}
  </React.Fragment>
);

export default SearchBumpContents;
