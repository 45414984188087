import { VariantResult } from "../../libs/models/variant-result";
import { api } from "../api";
import { GetReporterCuratedData } from "../reporter/types";
import {
  MergedRelatedVariant,
  RelatedVariantArguments,
  VariantResponse,
  RelatedVariant,
  ClinVarVariantsResponse,
  CuratedVariantsResponse,
  URLSearchParamsReq,
  GenomicPositionResponse,
} from "./types";
import { generateMutationBody, getReporterURL } from "./util";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getRelatedVariants: builder.query<
      Array<MergedRelatedVariant>,
      RelatedVariantArguments
    >({
      // TODO Related Variants Follow Up: Duplicate endpoints for reporter/related; let's consolidate
      async queryFn(_args, _queryApi, _extraOptions, fetchWithBaseUrl) {
        // Fetch the variants via /mutations
        const mutationArgs = generateMutationBody(
          _args.urlTermIds,
          _args.urlBooleanParams,
          _args.urlCats,
          _args.urlSigTerms,
          {
            gene: _args.gene,
            mutation: _args.variant,
          }
        );
        const mutResponse = await fetchWithBaseUrl({
          url: "/mutations",
          method: "POST",
          body: mutationArgs,
        });

        if (mutResponse.error) {
          return { error: mutResponse.error };
        }

        const mutData = mutResponse.data as VariantResponse;
        const variants = mutData.variants;

        // Extract the relatedVariants from the /mutations response
        let mutRelatedVariants: RelatedVariant[] = [];
        if (
          mutData.relatedVariants &&
          Object.keys(mutData.relatedVariants).length > 0
        ) {
          Object.keys(mutData.relatedVariants).forEach((key) => {
            if (mutData.relatedVariants?.[key]) {
              mutRelatedVariants = mutRelatedVariants.concat(
                mutData.relatedVariants[key]
              );
            }
          });
        }

        // combine, sort, and filter out duplicate relatedVariants
        const combinedVariants = mutRelatedVariants
          .sort((a: RelatedVariant, b: RelatedVariant) => a.Score - b.Score)
          .reduce(
            (uniqueVariants: MergedRelatedVariant[], v: RelatedVariant) => {
              const existingVariant = uniqueVariants.find(
                (variant) => variant.variant === v.Variant
              );
              if (!existingVariant) {
                const otherVariantInfo = variants.find(
                  (variant) => variant.name === v.Variant
                );
                const gene = VariantResult.getGene(v.Variant);
                uniqueVariants.push({
                  variant: v.Variant,
                  gene: gene,
                  score: v.Score,
                  totalHits: otherVariantInfo?.total_hits ?? 0,
                  acmgCall: "",
                  type: otherVariantInfo?.type ?? "",
                });
              }
              return uniqueVariants;
            },
            []
          )
          .slice(0, 15);
        if (combinedVariants.length === 0) {
          return { data: [] };
        }
        // Fetch the reporter data
        const combinedVariantGenes = combinedVariants.map((v) => v.gene);
        const geneParam =
          _args.gene.length > 0 ? _args.gene : combinedVariantGenes;
        const repResponse = await fetchWithBaseUrl({
          url: getReporterURL(
            geneParam,
            combinedVariants.map((v) => v.variant)
          ),
          method: "GET",
        });

        if (repResponse.error) {
          return { error: repResponse.error };
        }

        const reporterData = repResponse.data as GetReporterCuratedData;
        const reporterVariants = reporterData.curationRecords.variants;

        // Merge the reporter data with the combinedVariants
        const results: Array<MergedRelatedVariant> = [];
        combinedVariants.forEach((rv) => {
          const reporterVariant = reporterVariants.find(
            (v) => v.id === rv.variant
          );
          if (reporterVariant) {
            results.push({
              ...rv,
              acmgCall: reporterVariant.acmg_call,
            });
          } else {
            results.push({
              ...rv,
            });
          }
        });

        return { data: results };
      },
    }),
    getGeneVariantClinvarData: builder.query<ClinVarVariantsResponse, string>({
      query: (geneSymbol) => ({
        url: `/variants_with_clinvar/${geneSymbol}`,
        method: "GET",
      }),
    }),
    getGeneCuratedData: builder.query<CuratedVariantsResponse, string>({
      query: (geneSymbol) => ({
        url: `/curated_variants/${geneSymbol}`,
        method: "GET",
      }),
    }),
    getVariants: builder.query<VariantResponse, URLSearchParamsReq>({
      query: (args) => ({
        url: "/mutations",
        method: "POST",
        body: generateMutationBody(
          args.urlTermIds,
          args.urlBooleanParams,
          args.urlCats,
          args.urlSigTerms
        ),
      }),
      transformResponse: (response: VariantResponse) => {
        let relatedVariantResults = null;
        if (response.relatedVariants) {
          relatedVariantResults = response.relatedVariants;
        }
        if (!response.variants) {
          return {
            variants: [],
            relatedVariants: relatedVariantResults,
          };
        }
        const variantResults = response.variants.map((v) => {
          const nameParts = v.name.split(":");
          const geneName = nameParts[0];
          const mutation = nameParts[1];

          return {
            ...v,
            gene: geneName,
            mutation: mutation,
          };
        });
        return {
          variants: variantResults,
          relatedVariants: relatedVariantResults,
        };
      },
    }),
    getGenomicPosition: builder.query<GenomicPositionResponse, VariantResult>({
      query: (variant) => {
        const params = new URLSearchParams([["variant", variant.getName()]]);
        return {
          url: `/genomic_position?${params.toString()}`,
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useGetRelatedVariantsQuery,
  useGetGeneVariantClinvarDataQuery,
  useGetGeneCuratedDataQuery,
  useGetVariantsQuery,
  useLazyGetGenomicPositionQuery,
} = extendedApi;
