import { Menu, MenuItem } from "@mui/material";
import React from "react";

export interface VariantListFilterMenuProps {
  filterAnchorEl: null | HTMLElement;
  filterOpen: boolean;
  handleFilterChange: (filterStr: string) => void;
  handleClose: () => void;
}

export function VariantListFilterMenu(props: VariantListFilterMenuProps) {
  const { filterAnchorEl, filterOpen, handleFilterChange, handleClose } = props;

  return (
    <Menu
      data-testid="variant-list-filter-menu"
      anchorEl={filterAnchorEl}
      open={filterOpen}
      onClose={handleClose}
    >
      <MenuItem
        data-testid={`filter-menu-item-all`}
        key="all"
        onClick={() => handleFilterChange("All Records")}
      >
        All Records
      </MenuItem>
      <MenuItem
        data-testid={`filter-menu-item-curated`}
        key="curated"
        onClick={() => handleFilterChange("Curated Evidence")}
      >
        Curated Evidence
      </MenuItem>
      <MenuItem
        data-testid={`filter-menu-item-clinvar`}
        key="clinvar"
        onClick={() => handleFilterChange("ClinVar Evidence")}
      >
        ClinVar Evidence
      </MenuItem>
    </Menu>
  );
}
