import React from "react";
import ModalFrame from "../ModalFrame/ModalFrame";
import { useAppSelector } from "../../../store/hooks";
import { Button, Link, Typography } from "@mui/material";
import { FlexColumnDiv } from "../../common/Flex";
import { useLocation } from "react-router-dom";

type AlertModalProps = {
  shouldShowLogin: boolean;
};

const AlertModal = ({ shouldShowLogin }: AlertModalProps) => {
  const { error } = useAppSelector((state) => state.modal);
  const location = useLocation();
  const redirectParams = encodeURIComponent(location.search);

  if (!error) {
    return <></>;
  }

  return (
    <ModalFrame>
      <FlexColumnDiv
        style={{
          padding: 4,
          justifyContent: "center",
          height: "100%",
          gap: 15,
        }}
      >
        <Typography variant={"h3"} align={"center"}>
          {error.message}
        </Typography>
        {shouldShowLogin && (
          <>
            <Link
              data-testid={"sign-up-button"}
              href={`/users/sign_up?limit=feature&last_path=${location.pathname}${redirectParams}`}
            >
              <Button variant="bump" color="secondary">
                Sign Up for free to view
              </Button>
            </Link>
            <div>
              Already have an account?{" "}
              <Link
                href={`/users/sign_in?limit=feature&last_path=${location.pathname}${redirectParams}`}
              >
                Log in here
              </Link>
            </div>
          </>
        )}
        {error.redirectText && error.redirect && (
          <Link href={error.redirect} data-testid={"error-redirect-link"}>
            {error.redirectText}
          </Link>
        )}
      </FlexColumnDiv>
    </ModalFrame>
  );
};

export default AlertModal;
