import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export const TargetSvg = (props: SvgIconProps) => (
  <SvgIcon {...props} data-testid={"targetIcon"}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <g clipPath="url(#clip0_5033_168359)">
        <path
          d="M7.64766 0.229688C7.125 0.0796875 6.57188 0 6 0C2.68594 0 0 2.68594 0 6C0 9.31406 2.68594 12 6 12C9.31406 12 12 9.31406 12 6C12 5.42812 11.9203 4.875 11.7703 4.35234L11.2359 4.94766C11.2102 4.97578 11.1867 5.00156 11.1586 5.02734C11.2172 5.34375 11.2477 5.66719 11.2477 6C11.2477 8.89922 8.89687 11.25 5.99766 11.25C3.09844 11.25 0.75 8.89922 0.75 6C0.75 3.10078 3.10078 0.75 6 0.75C6.33281 0.75 6.65859 0.780469 6.97266 0.839062C6.99844 0.813281 7.02422 0.7875 7.05234 0.761719L7.64766 0.229688ZM6.43594 2.27578C6.29297 2.25937 6.14766 2.25 6 2.25C3.92812 2.25 2.25 3.92812 2.25 6C2.25 8.07187 3.92812 9.75 6 9.75C8.07187 9.75 9.75 8.07187 9.75 6C9.75 5.85234 9.74062 5.70703 9.72422 5.56406C9.66094 5.55937 9.59766 5.55234 9.53437 5.54297L8.94844 5.44453C8.98125 5.625 9 5.81016 9 6C9 7.65703 7.65703 9 6 9C4.34297 9 3 7.65703 3 6C3 4.34297 4.34297 3 6 3C6.18984 3 6.375 3.01875 6.55547 3.05156L6.45703 2.46562C6.44531 2.40234 6.43828 2.33906 6.43594 2.27578ZM8.00391 4.52812L9.65391 4.80234C10.0359 4.86562 10.4227 4.72969 10.6805 4.43906L11.7094 3.28125C11.9836 2.97422 11.8453 2.48672 11.4516 2.36953L10.0523 1.95L9.63047 0.548437C9.51328 0.154688 9.02578 0.0164062 8.71875 0.290625L7.56094 1.31953C7.27266 1.57734 7.13438 1.96406 7.19766 2.34609L7.47187 3.99609L5.73281 5.73516C5.5875 5.88047 5.5875 6.11953 5.73281 6.26484C5.87813 6.41016 6.11719 6.41016 6.2625 6.26484L8.00156 4.52578L8.00391 4.52812ZM8.65547 3.87656L9.85781 2.67422L10.9547 3.00234L10.1203 3.93984C10.0336 4.03594 9.90469 4.08281 9.77813 4.06172L8.65547 3.87422V3.87656ZM9.32578 2.14453L8.12344 3.34688L7.93594 2.22422C7.91484 2.09766 7.95937 1.96875 8.05781 1.88203L8.99531 1.04766L9.32344 2.14453H9.32578Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_5033_168359">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);

const TargetIcon = React.forwardRef<
  HTMLDivElement,
  React.HTMLProps<HTMLDivElement>
>(function TargetIcon(props, ref) {
  return (
    <div {...props} ref={ref} style={{ display: "flex" }}>
      <TargetSvg fontSize="inherit" />
    </div>
  );
});

export default TargetIcon;
