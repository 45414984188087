import { Stack, Typography } from "@mui/material";
import React from "react";
import { primaryPalette } from "../../../../../../../libs/styles/theme";
import { CuratedDataObjectKeys } from "../../../../../../../network/reporter/types";
import { discreteArticleDataLabelMap } from "../../../../../constants";
import { EvidenceViewerArticleCardFooterDiscreteDataItemProps } from "../types";

const DISCRETE_DATA_KEYS_TO_EXCLUDE = ["na_type"];

const DiscreteArticleData = ({
  discreteData,
}: EvidenceViewerArticleCardFooterDiscreteDataItemProps) => {
  if (!discreteData.data) {
    return null;
  }
  return (
    <Stack>
      {Object.entries(discreteData.data).map(([key, value]) => {
        if (DISCRETE_DATA_KEYS_TO_EXCLUDE.includes(key)) return null;

        return (
          <span key={key} data-testid={`article-data-item-${key}`}>
            <Typography
              variant="text11Emphasis"
              color={primaryPalette.teal.neutral_teal_t4}
            >
              {discreteArticleDataLabelMap[key as CuratedDataObjectKeys]}:{" "}
            </Typography>
            <Typography
              variant="text11"
              color={primaryPalette.grey.neutral_grey_g8}
            >
              {value}
            </Typography>
          </span>
        );
      })}
    </Stack>
  );
};

export default DiscreteArticleData;
