import React, { useCallback, useEffect, useRef } from "react";
import { Box, Button, Grid, Stack, useMediaQuery } from "@mui/material";
import { useSearchParams } from "react-router-dom";

import { elementHeights, theme } from "../../../libs/styles/theme";
import { ArticlePageViews } from "../../../libs/types/articles";
import { UrlParams } from "../../../libs/types/url-params";
import { useAppSelector } from "../../../store/hooks";
import { selectUrlTermIds } from "../../../store/selectors/urlSelectors";
import { SearchBarTerms } from "../../../store/slices/searchSlice";
import { IconBack } from "../../common/icons/Generic";
import Paywall from "../../common/paywalls";
import AdditionalDetailsDrawer from "../../common/additional-details-drawer";
import ArticleViewerLoadingState from "../article-viewer/ArticleViewerLoadingState";

type LeftColumnProps = {
  showCuratedEvidence: boolean;
  showExactMatch: boolean;
  GeneVariantCardComponent: React.ReactNode;
  ExactMatchComponent?: React.ReactNode;
  ArticleListComponent: React.ReactNode;
};
type RightColumnProps = {
  showCuratedEvidence: boolean;
  onArticleViewChange: () => void;
  ArticleViewerComponent: React.ReactNode;
  EvidenceViewerComponent: React.ReactNode;
  TreatmentTrialComponent: React.ReactNode;
  RelatedVariantsComponent: React.ReactNode;
};

type ArticlePageChildren = {
  LeftColumn: React.FC<LeftColumnProps>;
  RightColumn: React.FC<RightColumnProps>;
};

type ArticlePageProps = {
  children?: React.ReactNode;
};

const RightColumnSection = ({
  showCuratedEvidence,
  onArticleViewChange,
  ArticleViewerComponent,
  EvidenceViewerComponent,
  TreatmentTrialComponent,
  RelatedVariantsComponent,
}: RightColumnProps) => {
  const { isLoggedIn } = useAppSelector((state) => state.user);
  const urlTermIds = useAppSelector((state) => selectUrlTermIds(state));
  const hasRelatedVariants = urlTermIds[SearchBarTerms.variant].length > 0;

  const [searchParams] = useSearchParams();
  const currentArticlePageView = searchParams.get(UrlParams.VIEW);
  const isArticleViewerMode =
    currentArticlePageView === ArticlePageViews.FEATURED_ARTICLE;
  const isArticleListMode =
    (!currentArticlePageView && !showCuratedEvidence) ||
    currentArticlePageView === ArticlePageViews.ARTICLE_LIST;

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isLgDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const detailsDrawerLabel = showCuratedEvidence
    ? "Treatments & Related Variants"
    : "Related Variants";

  const scrollContainerRef = useRef<HTMLDivElement>(null);

  // ensures article and evidence viewers are scrolled
  // to top as users toggle between the two views
  const scrollToTop = useCallback(() => {
    if (isMobile) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = 0;
    }
  }, [isMobile]);

  useEffect(() => {
    scrollToTop();
  }, [currentArticlePageView, isArticleListMode, scrollToTop]);

  return (
    <Grid
      item
      xs={12}
      md={7}
      lg={8}
      xl={9}
      ref={scrollContainerRef}
      sx={
        !isMobile
          ? {
              maxHeight: `calc(100vh - ${elementHeights.fixedHeaderContent})`,
              overflowY: "scroll",
              overscrollBehavior: "contain",
              "::-webkit-scrollbar": {
                display: "none",
              },
            }
          : null
      }
    >
      <Grid container spacing={1} pt={1}>
        {!isMobile || (isMobile && !isArticleListMode) ? (
          <Grid item xs={12} md={12} lg={9} xl={10} pr={!isLgDesktop ? 2 : 1}>
            {isMobile && isArticleViewerMode ? (
              <Button
                data-testid="back-to-article-list-button"
                variant="text"
                onClick={() => onArticleViewChange()}
                sx={{
                  fontSize: "18px",
                  textTransform: "none",
                  marginBottom: 2,
                }}
              >
                <IconBack width="12px" />
                &nbsp;Back to article list
              </Button>
            ) : null}

            {!isLoggedIn ? (
              <Stack spacing={2}>
                <Paywall
                  titleText="basic edition preview"
                  bodyText="Take a look inside for full article details."
                  id="article-viewer-anon-paywall"
                  styles={{
                    borderRadius: "6px",
                  }}
                />
                <ArticleViewerLoadingState
                  animation={false}
                  testId="article-anonymous-pane"
                />
              </Stack>
            ) : (
              <>
                {showCuratedEvidence && !isArticleViewerMode ? (
                  <>{EvidenceViewerComponent}</>
                ) : (
                  <>{ArticleViewerComponent}</>
                )}
              </>
            )}
          </Grid>
        ) : null}
        <>
          {isLgDesktop ? (
            <Grid item lg={3} xl={2}>
              {showCuratedEvidence ? TreatmentTrialComponent : null}
              {hasRelatedVariants ? RelatedVariantsComponent : null}
            </Grid>
          ) : (
            <>
              {hasRelatedVariants || showCuratedEvidence ? (
                <AdditionalDetailsDrawer buttonLabel={detailsDrawerLabel}>
                  {showCuratedEvidence ? TreatmentTrialComponent : null}
                  {hasRelatedVariants ? RelatedVariantsComponent : null}
                </AdditionalDetailsDrawer>
              ) : null}
            </>
          )}
        </>
      </Grid>
    </Grid>
  );
};

const LeftColumnSection = ({
  showCuratedEvidence,
  showExactMatch,
  GeneVariantCardComponent,
  ExactMatchComponent,
  ArticleListComponent,
}: LeftColumnProps) => {
  const [searchParams] = useSearchParams();
  const currentArticlePageView = searchParams.get(UrlParams.VIEW);
  const isArticleListMode =
    (!currentArticlePageView && !showCuratedEvidence) ||
    currentArticlePageView === ArticlePageViews.ARTICLE_LIST;
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid item xs={12} md={5} lg={4} xl={3} pt={0}>
      <Stack
        spacing={2}
        padding="8px 0 16px 8px"
        sx={{
          maxHeight: `calc(100vh - ${elementHeights.fixedHeaderContent})`,
          overflowY: "scroll",
          overscrollBehavior: "contain",
          "::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <Box pr={isMobile ? 2 : 1}>{GeneVariantCardComponent}</Box>

        {!isMobile || (isMobile && isArticleListMode) ? (
          <>
            {showExactMatch ? (
              <Box pr={isMobile ? 2 : 1}>{ExactMatchComponent}</Box>
            ) : null}

            {ArticleListComponent}
          </>
        ) : null}
      </Stack>
    </Grid>
  );
};

const ArticlePage: React.FC<ArticlePageProps> & ArticlePageChildren = ({
  children,
}) => (
  <Grid container id="article-page" spacing={2}>
    {children}
  </Grid>
);

ArticlePage.LeftColumn = (props: LeftColumnProps) => (
  <LeftColumnSection {...props} />
);

ArticlePage.RightColumn = (props: RightColumnProps) => (
  <RightColumnSection {...props} />
);

export default ArticlePage;
