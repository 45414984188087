// Create the middleware instance and methods
import {
  createListenerMiddleware,
  isAnyOf,
  isRejected,
  PayloadAction,
  TypedStartListening,
} from "@reduxjs/toolkit";
import {
  addTag,
  AddTagPayload,
  deleteCategory,
  deleteSignificantTerm,
  executeSearch,
  initialSearchState,
  removeTag,
  SearchBarKeywordTerms,
  SearchBarKeywordType,
  SearchBarTerms,
  SearchBarTermsType,
  setCategories,
  setSignificantTerms,
  swapTermBool,
  updatedSearchChanged,
  updateEditTagValue,
} from "./slices/searchSlice";
import type { AppDispatch, RootState } from "./store";
import {
  setUserSelectedCategories,
  setUserSelectedSigTerms,
} from "./slices/filterSlice";
import { loadUser, loginUser, resetUser } from "./slices/userSlice";
import { resetVariantFilter } from "./slices/variantSlice";
import { api } from "../network/api";
import { ERROR_TYPES } from "../libs/hooks/errorHandlerHook";
import {
  Modals,
  openModal,
  setCurrentModal,
  setError,
} from "./slices/modalSlice";
import { determineBasicURL } from "../libs/utils/search";
import { isProUser } from "../libs/utils/user";
import {
  defaultSortNonPro,
  defaultSortPro,
  sortItems,
  updateArticleSearchSortItem,
} from "./slices/articleSlice";
import { identify } from "react-fullstory";

export const listenerMiddleware = createListenerMiddleware();

export type AppStartListening = TypedStartListening<RootState, AppDispatch>;

type ErrorPayload = {
  data: {
    error: string;
    limit_type: string;
    message: string;
  };
  status: number;
};

export const startAppListening =
  listenerMiddleware.startListening as AppStartListening;

startAppListening({
  matcher: isRejected,
  effect: (action, listenerApi) => {
    const errorPayload = action.payload as ErrorPayload;
    if (
      errorPayload &&
      Object.values(ERROR_TYPES).includes(errorPayload.data?.error)
    ) {
      const searchParams = new URLSearchParams(window.location.search);
      let redirect = {
          redirectUrl: "",
          redirectText: "",
        },
        canClose = false;
      if (errorPayload.data?.error === ERROR_TYPES.feature) {
        redirect = determineBasicURL(searchParams);
        canClose = true;
      }
      const modalError = {
        message: errorPayload.data?.message,
        redirect: redirect.redirectUrl,
        redirectText: redirect.redirectText,
      };

      listenerApi.dispatch(setCurrentModal(Modals.alertWithLogin));
      listenerApi.dispatch(openModal(canClose));
      listenerApi.dispatch(setError(modalError));
      // eslint-disable-next-line
      console.error(`There was an error: ${errorPayload.data?.message}`);
    }
  },
});

startAppListening({
  // if we have updated a tag or bool, we must check if search has changed (versus url) and update state accordingly
  matcher: isAnyOf(
    addTag,
    removeTag,
    swapTermBool,
    updateEditTagValue,
    deleteSignificantTerm,
    deleteCategory,
    setCategories,
    setSignificantTerms
  ),
  effect: (_, listenerApi) => {
    let searchChanged = false;
    const searchState = listenerApi.getState().search;

    const queryParams = new URLSearchParams(window.location.search);

    for (const term of Object.keys(SearchBarTerms) as Array<
      keyof SearchBarTermsType
    >) {
      if (searchChanged) {
        break;
      }
      const queryTerm = term === "variant" ? "mutation" : term;
      const termQueryParams = queryParams.getAll(queryTerm);
      const stateTerms = searchState[term];

      if (stateTerms.length !== termQueryParams.length) {
        searchChanged = true;
      }
      for (const term of stateTerms) {
        if (!termQueryParams.includes(term.id)) {
          searchChanged = true;
        }
      }

      const boolQueryParam = queryParams.get(`${queryTerm}_op`);
      const stateBool = searchState.booleans[term];
      // if bool query param is present, check that it is equal to the search state
      if (boolQueryParam && boolQueryParam !== stateBool) {
        searchChanged = true;
      }
      // if bool query param is not present, check that the stateBool is equal to our "default" bool for that term
      if (!boolQueryParam && stateBool !== initialSearchState.booleans[term])
        searchChanged = true;
    }

    if (searchChanged) {
      listenerApi.dispatch(resetVariantFilter());
      listenerApi.dispatch(updatedSearchChanged(searchChanged));
      return;
    }

    // check cat and sig terms
    for (const term of Object.keys(SearchBarKeywordTerms)) {
      const termQueryParams = queryParams.getAll(term);
      const stateTerms = searchState[term as keyof SearchBarKeywordType];

      if (Object.keys(stateTerms).length !== termQueryParams.length) {
        searchChanged = true;
      }
      for (const term of Object.keys(stateTerms)) {
        const keyword = stateTerms[term];
        if (!termQueryParams.includes(keyword.key)) {
          searchChanged = true;
        }
      }
    }

    listenerApi.dispatch(updatedSearchChanged(searchChanged));
  },
});

startAppListening({
  // if we add a variant suggestion, we should also add the related gene
  matcher: isAnyOf(addTag),
  effect: (action: PayloadAction<AddTagPayload>, listenerApi) => {
    if (
      action.payload.suggestion.type === SearchBarTerms.variant &&
      action.payload.suggestion.gene
    ) {
      const nameParts = action.payload.suggestion.id.split(":");

      if (nameParts.length === 2) {
        const geneID = nameParts[0];
        const geneSugg = {
          type: SearchBarTerms.gene,
          id: geneID,
          text: action.payload.suggestion.gene,
          synonym: "",
        };
        listenerApi.dispatch(
          addTag({
            shouldReplace: false,
            suggestion: geneSugg,
          })
        );
      }
    }
  },
});

startAppListening({
  // if we delete a category or sig_term to the search bar, we must also delete from the filter slice
  // so it properly displays as not selected in category dropdown
  matcher: isAnyOf(deleteCategory, deleteSignificantTerm),
  effect: (_, listenerApi) => {
    const searchState = listenerApi.getState().search;
    listenerApi.dispatch(setUserSelectedCategories(searchState.cats));
    listenerApi.dispatch(setUserSelectedSigTerms(searchState.sig_terms));
  },
});

startAppListening({
  // When user logs in or out, we need to refresh all data on page so we
  // reexecute the search. This ensures we are showing the appropriate data
  // based off of the user type and subscription
  matcher: isAnyOf(loginUser, resetUser),
  effect: (_, listenerApi) => {
    listenerApi.dispatch(executeSearch()); // should be able to remove once everything is moved to rtk query
    listenerApi.dispatch(api.util.resetApiState()); // reset rtk query cache for whole api
  },
});

startAppListening({
  // if a user logs in (or is loaded), we need to update the default sort to "relevance" if they are pro
  // otherwise we default to "publication date"
  // We should also call our identify function on user login/logout to send to fullstory
  matcher: isAnyOf(loadUser, resetUser, loginUser),
  effect: (_, listenerApi) => {
    const userState = listenerApi.getState().user;
    const fallback = sortItems[0];
    if (userState.isLoggedIn && isProUser(userState.user)) {
      listenerApi.dispatch(
        updateArticleSearchSortItem(defaultSortPro ?? fallback)
      );
    } else {
      listenerApi.dispatch(
        updateArticleSearchSortItem(defaultSortNonPro ?? fallback)
      );
    }

    if (userState.isLoggedIn) {
      identify(userState.user.id.toString(), {
        roles_str: userState.user.user_role_names.join(),
      });
    } else {
      identify(false);
    }
  },
});
