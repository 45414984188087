import styled from "styled-components";

export const FlexColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FlexRowDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const FlexRowReverseDiv = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
`;
