import { SvgIcon } from "@mui/material";
import React from "react";

export function CaretUpIcon() {
  return (
    <SvgIcon sx={{ width: "0.5em", height: "0.5em", marginRight: "0.5em" }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.99086 7.43099C1.49857 7.97798 1.88676 8.84961 2.62266 8.84961H9.3773C10.1132 8.84961 10.5014 7.97798 10.0091 7.43099L6.63178 3.67841C6.29413 3.30324 5.70584 3.30324 5.36818 3.67841L1.99086 7.43099ZM2.62266 8.14961C2.4928 8.14961 2.42429 7.99579 2.51117 7.89926L5.88849 4.14669C5.94807 4.08048 6.05189 4.08048 6.11148 4.14669L9.4888 7.89926C9.57567 7.99579 9.50717 8.14961 9.3773 8.14961H2.62266Z"
          fill="#678E95"
        />
      </svg>
    </SvgIcon>
  );
}
