import { SvgIcon } from "@mui/material";
import React from "react";

export function CheckIcon() {
  return (
    <SvgIcon
      sx={{
        background: "#FFFFFF",
        height: "12px",
        width: "12px",
        borderRadius: "3px",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
      >
        <path
          d="M11.0648 2.41418C11.3039 2.63449 11.3039 2.99074 11.0648 3.18996L4.87734 9.37746C4.67813 9.61652 4.32188 9.61652 4.10156 9.37746L0.914742 6.18996C0.695086 5.99074 0.695086 5.63449 0.914742 5.41418C1.13438 5.19621 1.49063 5.19621 1.71023 5.41418L4.5 8.20559L10.2891 2.41418C10.5094 2.19527 10.8656 2.19527 11.0648 2.41418Z"
          fill="#262626"
        />
      </svg>
    </SvgIcon>
  );
}
