import { Chip, Tooltip, Typography } from "@mui/material";
import React from "react";
import { AcmgDescriptionType } from "../../../network/acmg-descriptions";
import { getBackgroundColor } from "../../../libs/utils/acmg";
import { useAppSelector } from "../../../store/hooks";

export default function AcmgChip(props: { acmgClass: string }) {
  const { acmgClass } = props;
  const { acmgClasses } = useAppSelector((state) => state.acmgClasses);

  const acmgClassTooltipElement = (acmgDescription: AcmgDescriptionType) => {
    return (
      <React.Fragment>
        <Typography
          variant={"body2"}
        >{`${acmgDescription.nickname} : ${acmgDescription.description}`}</Typography>
      </React.Fragment>
    );
  };

  if (acmgClasses) {
    return (
      <Tooltip
        disableFocusListener
        title={acmgClassTooltipElement(acmgClasses[acmgClass])}
        placement={"right"}
        data-testid={"acmg-class-tooltip"}
      >
        <Chip
          style={{
            marginRight: "1px",
            background: `${getBackgroundColor(acmgClasses[acmgClass])}`,
            color: "white",
          }}
          label={acmgClass}
        />
      </Tooltip>
    );
  }

  return <></>;
}
