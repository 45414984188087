import { httpClient } from "./http-client";
import {
  BooleanSearch,
  initialSearchState,
  SearchBarTerms,
  SearchedTermIds,
} from "../store/slices/searchSlice";

export type SignificantCountResponse = Array<SignificantCount>;

export type SignificantCount = {
  term: string;
  hits: number;
};

export const emptySigCounts: SignificantCountResponse = [];

export async function getSignificantCounts(
  urlSearchParams: SearchedTermIds,
  urlBooleanParams: BooleanSearch
): Promise<SignificantCountResponse> {
  // TODO: This is similar to the payload we send for mutations.  We might want to extract this and that somehow
  const json = JSON.stringify({
    article_list_filter: "",
    article_sort: "relevance",
    article_sort_asc: false,
    boolean: true,
    cnv_match_op: "intersects",
    mutation_source: "supplemental",
    offset: 0,
    gene: urlSearchParams[SearchBarTerms.gene],
    mutation: urlSearchParams[SearchBarTerms.variant],
    disease: urlSearchParams[SearchBarTerms.disease],
    keyword: urlSearchParams[SearchBarTerms.keyword],
    hpo: urlSearchParams[SearchBarTerms.hpo],
    unii: urlSearchParams[SearchBarTerms.unii],
    cnv: urlSearchParams[SearchBarTerms.cnv],
    gene_op:
      urlBooleanParams[SearchBarTerms.gene] ??
      initialSearchState.booleans[SearchBarTerms.gene],
    disease_op:
      urlBooleanParams[SearchBarTerms.disease] ??
      initialSearchState.booleans[SearchBarTerms.disease],
    mutation_op:
      urlBooleanParams[SearchBarTerms.variant] ??
      initialSearchState.booleans[SearchBarTerms.variant],
    keyword_op:
      urlBooleanParams[SearchBarTerms.keyword] ??
      initialSearchState.booleans[SearchBarTerms.keyword],
    hpo_op:
      urlBooleanParams[SearchBarTerms.hpo] ??
      initialSearchState.booleans[SearchBarTerms.hpo],
    unii_op:
      urlBooleanParams[SearchBarTerms.unii] ??
      initialSearchState.booleans[SearchBarTerms.unii],
    cnv_op:
      urlBooleanParams[SearchBarTerms.cnv] ??
      initialSearchState.booleans[SearchBarTerms.cnv],
  });

  const { data } = await httpClient.post<SignificantCountResponse>(
    "/category/significantTerms",
    json
  );
  return data;
}
