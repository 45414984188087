import React, { ReactNode } from "react";
import { Box, IconButton } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { closeModal } from "../../store/slices/modalSlice";
import CloseIcon from "@mui/icons-material/Close";
import { primaryPalette } from "../../libs/styles/theme";

type ModalBoxProps = {
  children: ReactNode;
  style?: object;
};

const ModalBox = ({
  children,
  style = {
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
  },
}: ModalBoxProps) => {
  const { canCloseModal } = useAppSelector((state) => state.modal);
  const dispatch = useAppDispatch();

  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        ...style,
      }}
    >
      {canCloseModal && (
        <IconButton
          onClick={() => dispatch(closeModal())}
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            color: primaryPalette.gray.variant_06,
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
      {children}
    </Box>
  );
};

export default ModalBox;
