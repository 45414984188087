import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getCategoryCounts } from "../../network/mm-category-counts";
import {
  addKeywordCount,
  KeywordCountState,
} from "../../store/slices/categoryKeywordSlice";
import { useHandleError } from "./errorHandlerHook";
import { selectUrlTermIds } from "../../store/selectors/urlSelectors";

export const useGetCategoryCounts = (selectedTab: string) => {
  const { urlTermBooleans } = useAppSelector((state) => state.url);
  const urlTermIds = useAppSelector((state) => selectUrlTermIds(state));
  const { keywordCounts } = useAppSelector((state) => state.keywordCount);
  const dispatch = useAppDispatch();
  const handleError = useHandleError();

  useEffect(() => {
    if (
      !keywordCounts[selectedTab] ||
      Object.keys(keywordCounts[selectedTab].length === 0)
    ) {
      const fetchKeywords = async () => {
        try {
          const keywordsRes = await getCategoryCounts(
            urlTermIds,
            urlTermBooleans,
            selectedTab
          );
          const counts: KeywordCountState = {};
          keywordsRes.forEach((count) => {
            counts[count.term] = count.hits;
          });
          dispatch(addKeywordCount({ category: selectedTab, counts: counts }));
        } catch (error) {
          handleError(error);
        }
      };
      void fetchKeywords();
    }
  }, [urlTermIds, urlTermBooleans]);
};
