import React, { ComponentPropsWithoutRef, forwardRef } from "react";
import { primaryPalette } from "../../libs/styles/theme";
import { Card, styled } from "@mui/material";

export const StyledCard = styled(Card)`
  borderradius: 5px;
  margin: 5px 0px;
  boxshadow: unset;
  padding: 2px;
  &.selected {
    border: 2px solid ${primaryPalette.teal.neutral_teal_t3};
    padding: 0px;
  }
  &.selected:focus-visible {
    outline-color: ${primaryPalette.teal.neutral_teal_t6};
  }
`;

export const StyledCommonCard = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<"div">
>(({ children, ...props }, ref) => {
  return (
    <StyledCard {...props} ref={ref}>
      {children}
    </StyledCard>
  );
});
