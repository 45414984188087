import SvgIcon from "@mui/material/SvgIcon";
import React from "react";

const ClinVarIcon = React.forwardRef<
  HTMLDivElement,
  React.HTMLProps<HTMLDivElement>
>(function ClinVarIcon(props, ref) {
  return (
    <div {...props} ref={ref}>
      <SvgIcon fontSize="inherit" data-testid={"clinVarIcon"}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="9"
          viewBox="0 0 15 9"
          fill="none"
        >
          <path
            d="M10.7646 8.99993L13.2643 4.49524L10.7742 0H11.3967C11.7415 0 12.1533 0.245538 12.3161 0.538296L14.4997 4.49524L12.3066 8.45219C12.1438 8.75439 11.7224 8.99048 11.3872 8.99048H10.7646V8.99993Z"
            fill="#616265"
          />
          <path
            d="M0.965951 0C0.506247 0 0.132812 0.368381 0.132812 0.831127V8.16887C0.132812 8.62217 0.506247 9 0.965951 9H10.1409L12.6405 4.4952L10.1409 0H0.965951ZM1.53104 2.4932H2.27813L4.11687 5.45858H4.12641V2.4932H4.79682V6.51621H4.04991L2.22071 3.56023H2.20144V6.51621H1.53104V2.4932ZM5.49602 2.4932H6.20477V6.51621H6.19523H5.49602V2.4932ZM6.90379 2.4932H7.61254V4.08923H9.45147V2.4932H10.1505V6.51621H9.44174V4.70308H7.61254V6.51621H6.90379V2.4932Z"
            fill="#616265"
          />
        </svg>
      </SvgIcon>
    </div>
  );
});

export default ClinVarIcon;
