import React from "react";
import { Card, Typography } from "@mui/material";
import { primaryPalette } from "../../../../../libs/styles/theme";
import { ACMGCategories } from "../../../../../libs/types/variant";
import { IconEmptyState } from "../../../../common/icons/EmptyStateIcon";

type ArticleListSectionEmptyStateProps = {
  label: ACMGCategories;
};

const ArticleListSectionEmptyState = ({
  label,
}: ArticleListSectionEmptyStateProps) => {
  return (
    <Card
      data-testid={`${label}-section-empty-state`}
      sx={{
        borderRadius: "5px",
        padding: 1.5,
        my: "5px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <IconEmptyState
        sx={{ color: primaryPalette.grey.neutral_grey_g7, mr: 1 }}
      />
      <Typography
        variant="text12"
        color={primaryPalette.grey.neutral_grey_g7}
        fontWeight="bold"
      >
        No {label} evidence found.
      </Typography>
    </Card>
  );
};

export default ArticleListSectionEmptyState;
