import { SvgIcon } from "@mui/material";
import React from "react";

export function GeneIcon({
  height,
  width,
}: {
  height?: string;
  width?: string;
}) {
  return (
    <SvgIcon
      fontSize="small"
      data-testid="gene-icon"
      style={{ height: height, width: width }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.16172 6.55703L7.70195 6.22188C7.54752 6.10975 7.33264 6.14399 7.22199 6.29774C7.11021 6.45116 7.14411 6.66641 7.29785 6.7777L7.75805 7.11242C7.94473 7.24883 8.1123 7.40137 8.27344 7.5625H3.95723C4.11778 7.40163 4.28658 7.24818 4.47285 7.11262L8.14238 4.44211C9.47012 3.49336 10.25 1.96002 10.25 0.34375C10.25 0.153742 10.0953 0 9.90625 0C9.71719 0 9.5625 0.153742 9.5625 0.34375C9.5625 0.459229 9.551 0.573203 9.5419 0.6875H2.70812C2.6991 0.573203 2.6875 0.459336 2.6875 0.34375C2.6875 0.153742 2.53367 0 2.34375 0C2.15383 0 2 0.153742 2 0.34375C2 1.96002 2.78053 3.49336 4.08764 4.44297L5.54063 5.5L4.08828 6.55703C2.78053 7.50664 2 9.04062 2 10.6562C2 10.8463 2.15634 10.9807 2.34633 10.9807C2.53631 10.9807 2.6875 10.8453 2.6875 10.6562C2.6875 10.5408 2.699 10.4268 2.7081 10.3125H9.54228C9.55176 10.4264 9.5625 10.5402 9.5625 10.6562C9.5625 10.8463 9.71624 11 9.90625 11C10.0963 11 10.25 10.8463 10.25 10.6562C10.25 9.04062 9.47012 7.50664 8.16172 6.55703ZM9.43789 1.375C9.31859 1.86506 9.11455 2.32998 8.83891 2.75H3.41066C3.13502 2.33105 2.93092 1.86506 2.81168 1.375H9.43789ZM4.47285 3.88652C4.30527 3.75117 4.1377 3.59863 3.95723 3.4375H8.2541C8.09355 3.59838 7.92475 3.75182 7.73848 3.88738L6.125 5.07461L4.47285 3.88652ZM2.81168 9.625C2.93098 9.13494 3.13502 8.67002 3.41066 8.25H8.83977C9.11541 8.67002 9.31951 9.13494 9.43875 9.625H2.81168Z"
          fill="#678E95"
        />
      </svg>
    </SvgIcon>
  );
}
