import { SvgIcon } from "@mui/material";
import React from "react";

export function AssociationStrength() {
  return (
    <SvgIcon fontSize="small">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="13"
        height="13"
        viewBox="0 0 13 13"
        fill="none"
      >
        <g clipPath="url(#clip0_1235_37310)">
          <path
            d="M12.3898 6.76474L9.76478 9.38974C9.69213 9.46239 9.59604 9.49989 9.49994 9.49989C9.40385 9.49989 9.30803 9.46329 9.23486 9.39002C9.08838 9.24354 9.08838 9.00611 9.23486 8.85986L11.2203 6.87489H1.78033L3.76525 8.85982C3.91174 9.0063 3.91174 9.24372 3.76525 9.38997C3.69213 9.46239 3.59604 9.49989 3.49994 9.49989C3.40385 9.49989 3.30804 9.46329 3.23486 9.39002L0.609863 6.76502C0.463379 6.61854 0.463379 6.38111 0.609863 6.23486L3.23486 3.60986C3.38135 3.46338 3.61877 3.46338 3.76502 3.60986C3.91127 3.75635 3.9115 3.99377 3.76502 4.14002L1.78033 6.12489H11.221L9.23603 4.13997C9.08955 3.99349 9.08955 3.75607 9.23603 3.60982C9.38252 3.46357 9.61994 3.46333 9.76619 3.60982L12.3912 6.23482C12.5374 6.38036 12.5374 6.61943 12.3898 6.76474Z"
            fill="#678E95"
          />
        </g>
        <defs>
          <clipPath id="clip0_1235_37310">
            <rect
              width="12"
              height="12"
              fill="white"
              transform="translate(0.5 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}
