import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { primaryPalette } from "../../../../libs/styles/theme";
import { VariantSubTypesDisplay } from "../../../../libs/types/card";
import { VariantSubTypes } from "../../../../libs/types/variant";
import {
  getVariantColor,
  getVariantTypeString,
} from "../../../../libs/utils/variant";
import { LogoGenomenonSmallNoBackground } from "../../../common/icons/SmallGLogo";
import { pathogenictyIconMap } from "../../constants";
import { CurationRecordVariant } from "../../../../libs/types/articles";
import { GetEvidenceViewerRecordsResponse } from "../../../../network/reporter/types";
import ACMGCriteriaGenomenonLink from "./ACMGCriteriaGenomenonLink";

type EvidenceHeaderProps = {
  curatedVariantData: CurationRecordVariant | undefined;
  evidenceViewerData: GetEvidenceViewerRecordsResponse | undefined;
  selectedVariant: CurationRecordVariant | undefined;
};

const EvidenceHeader = ({
  selectedVariant,
  curatedVariantData,
  evidenceViewerData,
}: EvidenceHeaderProps) => {
  const pathogenicity = curatedVariantData?.acmg_call as VariantSubTypes;
  const projectType = evidenceViewerData?.record?.project_type || "";

  return (
    <Box
      data-testid="assessment-bar-large"
      py={2}
      px={2.5}
      sx={{
        bgcolor: getVariantColor(pathogenicity),
        borderRadius: "16px 16px 0 0",
      }}
    >
      <Stack direction="column" spacing={0.5}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <LogoGenomenonSmallNoBackground sx={{ fontSize: "1rem" }} />
            <Typography
              color={primaryPalette.gray.variant_01}
              variant="h4"
              fontWeight="fontWeightBold"
              data-testid="variant-type"
            >
              {selectedVariant
                ? getVariantTypeString(selectedVariant.type)
                : ""}
            </Typography>
          </Stack>
          {evidenceViewerData?.record?.review_date && (
            <Typography
              variant="text14"
              color={primaryPalette.gray.variant_01}
              style={{
                display: "flex",
                alignItems: "center",
              }}
              fontWeight="fontWeightBold"
            >
              Last Updated{" "}
              {new Date(
                evidenceViewerData?.record?.review_date
              ).toLocaleDateString(undefined, {
                year: "2-digit",
                month: "numeric",
                day: "numeric",
              })}
            </Typography>
          )}
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <Typography
              data-testid="variant-sub-type"
              variant="h2"
              color={primaryPalette.gray.variant_01}
            >
              {VariantSubTypesDisplay[pathogenicity] ?? pathogenicity}
            </Typography>
            {pathogenictyIconMap[pathogenicity]}
          </Stack>

          <Typography
            variant="text14"
            fontWeight="fontWeightLight"
            color={primaryPalette.gray.variant_01}
          >
            {evidenceViewerData?.record?.disease?.display_name}
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="flex-start">
          <ACMGCriteriaGenomenonLink projectType={projectType} />
        </Stack>
      </Stack>
    </Box>
  );
};

export default EvidenceHeader;
