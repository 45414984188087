import React from "react";
import { Typography } from "@mui/material";
import { primaryPalette } from "../../../../../../libs/styles/theme";
import { ACMGCategories } from "../../../../../../libs/types/variant";

type ACMGCategorySectionEmptyStateProps = {
  label: ACMGCategories;
};

const ACMGCategorySectionEmptyState = ({
  label,
}: ACMGCategorySectionEmptyStateProps) => {
  return (
    <Typography
      variant="text12"
      color={primaryPalette.grey.neutral_grey_g7}
      fontWeight="bold"
    >
      No {label} evidence found.
    </Typography>
  );
};

export default ACMGCategorySectionEmptyState;
