import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { VariantResult } from "../../libs/models/variant-result";
import {
  ACMGCategories,
  ACMGAndUnclassifiedCategories,
  UnclassifiedCategories,
} from "../../libs/types/variant";
import {
  EvidenceViewerArticleDataByPMID,
  EvidenceViewerGnomadData,
} from "../../network/reporter/types";
import { ACMGCategoryDataType } from "../../components/article/evidence-viewer/EvidenceViewerACMGCriteria/ACMGCriteriaBody/types";
import { UNWEIGHTED_CRITERIA_EXCLUDE_LIST } from "../../components/article/constants";

type EvidenceViewerArticleListData = {
  [key in ACMGAndUnclassifiedCategories]: EvidenceViewerArticleDataByPMID;
};

type EvidenceViewerACMGCriteriaData = {
  [key in ACMGCategories]: ACMGCategoryDataType[];
};

type ProcessedGnomadData = {
  label: string;
  populations: FormattedPopulation[];
};

type GnomadDataPopulations =
  | "afr"
  | "amr"
  | "asj"
  | "eas"
  | "fin"
  | "nfe"
  | "oth"
  | "sas";

type FormattedPopulation = {
  name: string;
  value: string;
};

const populationNames: { [key in GnomadDataPopulations]: string } = {
  afr: "African",
  amr: "Latino",
  asj: "Ashkenazi Jewish",
  eas: "East Asian",
  fin: "European (Finn)",
  nfe: "European (non-Finn)",
  oth: "Other",
  sas: "South Asian",
};

// simple selectors
// build up simple selectors for use in complex selectos
const selectReporterCuratedData = (state: RootState) =>
  state.curatedEvidence?.reporterCuratedData;
const selectReporterEvidenceViewerData = (state: RootState) =>
  state.curatedEvidence?.reporterEvidenceViewerData;
const selectSelectedVariant = (state: RootState) =>
  state.curatedEvidence?.selectedVariant;

// complex selectors
export const selectCuratedVariantData = createSelector(
  selectReporterCuratedData,
  selectSelectedVariant,
  (curatedData, selectedVariant) => {
    if (curatedData && selectedVariant) {
      return curatedData?.curationRecords.variants.find(
        (v) =>
          VariantResult.variantCasing(v.id) ===
          VariantResult.variantCasing(selectedVariant.id)
      );
    }
    return undefined;
  }
);

export const selectEvidenceViewerArticleListData = createSelector(
  selectReporterEvidenceViewerData,
  (evidenceViewerData) => {
    const { json_record, pmid_curated_data, project_type } =
      evidenceViewerData?.record || {};
    if (!json_record || !pmid_curated_data) return undefined;

    const acmgKeys = Object.values(ACMGCategories);
    const accumulator: EvidenceViewerArticleListData = {
      [ACMGCategories.POPULATION]: {},
      [ACMGCategories.COMPUTATIONAL]: {},
      [ACMGCategories.INTRINSIC]: {},
      [ACMGCategories.CLINICAL]: {},
      [ACMGCategories.FUNCTIONAL]: {},
      [UnclassifiedCategories.UNCLASSIFIED]: {},
    };

    Object.entries(pmid_curated_data).forEach(([pmid, value]) => {
      value.discrete_data.forEach((data) => {
        const category = acmgKeys.includes(data.type as ACMGCategories)
          ? (data.type as ACMGCategories)
          : (UnclassifiedCategories.UNCLASSIFIED as keyof EvidenceViewerArticleListData);

        accumulator[category][pmid] = {
          ...value,
          projectType: project_type,
          discrete_data: value.discrete_data.filter((discrete_data) =>
            category !== UnclassifiedCategories.UNCLASSIFIED
              ? discrete_data.type === category
              : !discrete_data.type
          ),
        };
      });
    });
    return accumulator;
  }
);

// Reorganizes the data into ACMG categories and places the acmg criteria
// and data in each category
// Ex:
// {
//   "population": [
//     {
//       acmg_call: "pp3",
//       short_desc: "this is my description",
//       lit_items: [],
//       strength: "strong",
//       ...
//     }
//   ],
//   ...
// }
export const selectEvidenceViewerACMGBodyData = createSelector(
  selectReporterEvidenceViewerData,
  (evidenceViewerData) => {
    const { json_record } = evidenceViewerData?.record || {};
    if (!json_record) return undefined;

    const acmgKeys = Object.values(ACMGCategories);
    const initialAccumulator: EvidenceViewerACMGCriteriaData = {
      [ACMGCategories.POPULATION]: [],
      [ACMGCategories.COMPUTATIONAL]: [],
      [ACMGCategories.INTRINSIC]: [],
      [ACMGCategories.CLINICAL]: [],
      [ACMGCategories.FUNCTIONAL]: [],
    };

    return Object.entries(json_record).reduce<EvidenceViewerACMGCriteriaData>(
      (acc, [key, value]) => {
        // unweighted cases are less useful for classification purposes
        // therefore exclude it from the Evidence Viewer ACMG Section
        if (!value || UNWEIGHTED_CRITERIA_EXCLUDE_LIST.includes(key))
          return acc;

        const category = acmgKeys.includes(value.type as ACMGCategories)
          ? (value.type as ACMGCategories)
          : "";

        if (!category) return acc;

        const newACMGItem = {
          ...value,
          acmg_call: key,
        };

        return {
          ...acc,
          [category]: [...acc[category], newACMGItem],
        };
      },
      initialAccumulator
    );
  }
);

const formatVariantLabel = (data: EvidenceViewerGnomadData): string => {
  return `chr${data.chrom}:${data.pos} ref:${data.ref} alt:${data.alt}`;
};

const formatPopulationData = (
  populations: NonNullable<EvidenceViewerGnomadData["populations"]>
): FormattedPopulation[] => {
  let totalAc = 0;
  let totalAn = 0;

  const formattedPopulations = Object.entries(populations).map(
    ([code, data]) => {
      const ac = data.ac ?? 0;
      const an = data.an ?? 0;
      const af = data.af ?? 0;

      totalAc += data.ac || 0;
      totalAn += data.an || 0;
      return {
        name:
          populationNames[code as GnomadDataPopulations] || code.toUpperCase(),
        value: `${ac}/${an} = ${af.toFixed(5)}`,
      };
    }
  );

  const overallAf = totalAn > 0 ? totalAc / totalAn : 0;

  return [
    {
      name: "OVERALL",
      value: `${totalAc}/${totalAn} = ${overallAf.toFixed(5)}`,
    },
    ...formattedPopulations,
  ];
};

export const selectEvidenceViewerGnomadData = createSelector(
  selectReporterEvidenceViewerData,
  (evidenceViewerData) => {
    const gnomadData = evidenceViewerData?.record?.gnomad_json;
    if (!gnomadData || gnomadData.length === 0) return [];

    return gnomadData.reduce<ProcessedGnomadData[]>((acc, data) => {
      if (data && data.populations) {
        acc.push({
          label: formatVariantLabel(data),
          populations: formatPopulationData(data.populations),
        });
      }
      return acc;
    }, []);
  }
);
