import React from "react";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { primaryPalette } from "../../../../../libs/styles/theme";
import { ACMGAndUnclassifiedCategories } from "../../../../../libs/types/variant";

type ArticleListSectionProps = {
  label: ACMGAndUnclassifiedCategories;
  icon: React.JSX.Element;
  children: React.ReactNode;
};

const ArticleListSection = ({
  label,
  icon,
  children,
}: ArticleListSectionProps) => {
  return (
    <Box data-testid={`evidence-viewer-article-list-${label}-section`} mb={3}>
      <Box display="flex" alignItems="center">
        {icon}
        <Typography
          variant="h5"
          color={primaryPalette.grey.neutral_grey_g7}
          lineHeight="normal"
        >
          {label} evidence
        </Typography>
      </Box>
      <Divider aria-hidden="true" sx={{ my: "4px" }} />

      <Stack px={1}>{children}</Stack>
    </Box>
  );
};

export default ArticleListSection;
