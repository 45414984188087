import * as React from "react";
import Typography from "@mui/material/Typography";
import DOMPurify from "dompurify";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { Divider, Slide, IconButton, Paper, Stack } from "@mui/material";
import { boxShadowStyles, primaryPalette } from "../../libs/styles/theme";
import CloseIcon from "@mui/icons-material/Close";
import { dismissNotification } from "../../store/slices/notificationSlice";
import { MMNotification, dismissMessage } from "../../network/notifications";

const MastermindBanner = () => {
  const { bannerMessages } = useAppSelector((state) => state.notifications);
  const dispatch = useAppDispatch();

  const handleCloseBannerMessage = (message: MMNotification) => {
    void dismissMessage(message.id);
    dispatch(dismissNotification(message));
  };

  if (bannerMessages.length === 0) return null;

  return (
    <Slide direction="up" in mountOnEnter unmountOnExit>
      <Paper
        role="dialog"
        aria-modal="false"
        aria-label="Mastermind Banner"
        data-testid="mastermind-banner-paper"
        square
        variant="outlined"
        tabIndex={-1}
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          m: 0,
          p: 2,
          borderWidth: 0,
          borderTopWidth: 1,
          zIndex: 3,
          background: primaryPalette.teal.neutral_teal_t2,
          boxShadow: boxShadowStyles.mastermindBanner,
        }}
      >
        <Stack
          direction="column"
          gap={2}
          divider={<Divider orientation="horizontal" variant="middle" />}
        >
          {bannerMessages.map((message) => (
            <Stack
              direction="column"
              gap={2}
              key={`mastermind-banner-${message.id}`}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h3">{message.message}</Typography>
                <IconButton
                  data-testid="article-list-search-close"
                  onClick={() => handleCloseBannerMessage(message)}
                >
                  <CloseIcon
                    fontSize="small"
                    data-testid={`dismiss-banner-${message.id}`}
                  />
                </IconButton>
              </Stack>
              <Typography
                variant="text16"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(message.body),
                }}
              />
            </Stack>
          ))}
        </Stack>
      </Paper>
    </Slide>
  );
};

export default MastermindBanner;
