"use client";

import * as React from "react";
import { styled } from "@mui/material/styles";
import { primaryPalette } from "../../../../../libs/styles/theme";

import { Instance } from "@popperjs/core";

import { useAnchorEl } from "../../../../../libs/hooks/useAnchorEl";
import { AssessmentClinvarProps } from "../../../../../libs/types/card";
import { IconDrop, IconInfo } from "../../../../common/icons/Generic";
import {
  Box,
  Button,
  Menu,
  Typography,
  MenuItem,
  Grid,
  Stack,
  Link,
} from "@mui/material";
import { ClinVarRecord } from "../../../../../libs/types/articles";
import ClinVarIcon from "../../../../common/icons/SmallClinVarIcon";
import TooltipComponent from "../../../../common/tooltip/TooltipComponent";

const CustomBox = styled(Box)({
  fontSize: "0.75rem",
  margin: "0",
  padding: "0.375rem 0.625rem 0.25rem 1rem",
  backgroundColor: primaryPalette.teal.neutral_teal_t2,
  position: "relative",
  "& .MuiBox-root": {
    flex: "none",
    position: "relative",
    minWidth: "23px",
  },
  "& .info": {
    position: "absolute",
    right: 0,
    cursor: "pointer",
  },
  "& .clinvarVariant": {
    color: primaryPalette.grey.neutral_grey_g8,
  },
  "& .pathogenicity": {
    fontSize: "0.75rem",
    fontFamily: "Rubik Medium",
    fontWeight: 500,
  },
  "& svg": {
    maxWidth: "0.75rem",
    height: "auto",
  },
  "& .ClinVarIcon": {
    maxWidth: "1.125rem",
    position: "absolute",
    top: "0.188rem",
  },
  "& .IconDrop": {
    marginLeft: "0.5rem",
  },
  "& .IconInfo": {
    margin: "0.1875rem 0.5rem 0 0.25rem",
  },
});

const VariantButton = styled(Button)({
  padding: 0,
  textTransform: "none",
  "&:hover, &:focus": {
    background: "none",
  },
});

const VariantMenu = styled(Menu)({
  "& .MuiPaper-root": {
    padding: "0 0.125rem 0 1rem",
    "& .MuiMenuItem-root": {
      padding: "0.25rem 0",
    },
  },
});

const VariantMenuSubhead = styled(Typography)({
  color: primaryPalette.teal.variant_07,
  textTransform: "uppercase",
});

const AssessmentClinvar: React.FC<AssessmentClinvarProps> = ({
  clinvarRecords,
}) => {
  const [clinvarIndex, setClinvarIndex] = React.useState<number>(0);
  const clinvarItem = clinvarRecords[clinvarIndex];
  const { anchorEl, handleClick, handleClose } = useAnchorEl();
  const open = Boolean(anchorEl);

  const renderVariantHeader = () => {
    if (clinvarRecords.length > 1) {
      const handleNav = (selectedClinvarItem: ClinVarRecord) => {
        setClinvarIndex(
          clinvarRecords.findIndex((cr) => cr.id === selectedClinvarItem.id)
        );
        handleClose();
      };

      return (
        <>
          <VariantButton
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            className="clinvarVariant"
          >
            <Typography variant={"text12"}>
              {clinvarItem.displayName}
            </Typography>
            <IconDrop />
          </VariantButton>
          <VariantMenu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              horizontal: 60,
              vertical: 18,
            }}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <VariantMenuSubhead>variants</VariantMenuSubhead>
            {clinvarRecords.map((item) => (
              <MenuItem
                key={item.id}
                onClick={() => handleNav(item)}
                disabled={item.id === clinvarItem.id}
              >
                {item.displayName}
              </MenuItem>
            ))}
          </VariantMenu>
        </>
      );
    } else {
      return (
        <Typography variant="text12" className="clinvarVariant">
          {clinvarItem.displayName}
        </Typography>
      );
    }
  };

  const popperRef = React.useRef<Instance>(null);
  const popperContent = (
    <Grid container spacing={0}>
      <Grid item xs={4}>
        Description:
      </Grid>
      <Grid item xs={8}>
        {clinvarRecords[clinvarIndex].description}
      </Grid>
      <Grid item xs={4}>
        Review Status:
      </Grid>
      <Grid item xs={8}>
        {clinvarRecords[clinvarIndex].reviewStatus}
      </Grid>
      <Grid item xs={4}>
        Submissions:
      </Grid>
      <Grid item xs={8}>
        {clinvarRecords[clinvarIndex].submissions}
      </Grid>
      <Grid item xs={4}>
        Most Recent:
      </Grid>
      <Grid item xs={8}>
        {clinvarRecords[clinvarIndex].mostRecent}
      </Grid>
    </Grid>
  );

  return (
    <CustomBox>
      <Stack direction="row">
        <Box>
          <ClinVarIcon />
        </Box>
        <Stack>
          <Stack direction="row">{renderVariantHeader()}</Stack>
          <Stack direction="row">
            <Typography
              variant="text12"
              fontWeight={"fontWeightBold"}
              style={{ maxWidth: "200px" }}
            >
              {clinvarRecords[clinvarIndex].interpretation}
            </Typography>
            <Stack direction="row" className="info">
              <Link href={clinvarRecords[clinvarIndex].url} target="_blank">
                ClinVar Record
              </Link>
              {popperContent && (
                <TooltipComponent
                  content={popperContent}
                  popperProps={{ popperRef }}
                  slotProps={{
                    popper: {
                      modifiers: [
                        {
                          name: "offset",
                          options: {
                            offset: [0, -15],
                          },
                        },
                      ],
                    },
                  }}
                  textTransform="capitalize"
                  placement="right"
                >
                  <Box data-testid="clinvar-info-icon">
                    <IconInfo />
                  </Box>
                </TooltipComponent>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </CustomBox>
  );
};

export default AssessmentClinvar;
