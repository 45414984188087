import { httpClient } from "./http-client";
import { User } from "../libs/types/user";
import { AxiosError, AxiosResponse } from "axios";

type SignInRequest = {
  user: UserSignIn | OTPSignIn;
};

type UserSignIn = {
  email: string;
  password: string;
};

type OTPSignIn = {
  otp_attempt: string;
};

export const getCurrentUser = async () =>
  await httpClient.get<any, AxiosResponse<User>, any>("/users/current");

export const getUserSignInForm = async () =>
  await httpClient.get("users/sign_in");

export const userSignIn = (credentials: SignInRequest) =>
  httpClient.post<AxiosError, AxiosResponse<User>, SignInRequest>(
    "users/sign_in",
    credentials
  );

export const userSignOut = async () =>
  await httpClient.delete("users/sign_out");
