import React from "react";
import { SvgIcon, SvgIconOwnProps } from "@mui/material";

export const IconEmptyState = (props: SvgIconOwnProps) => (
  <SvgIcon {...props}>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.35355 20.3536L20.3536 4.35355L19.6464 3.64645L3.64645 19.6464L4.35355 20.3536ZM23.5 12C23.5 18.3513 18.3513 23.5 12 23.5C5.64873 23.5 0.5 18.3513 0.5 12C0.5 5.64873 5.64873 0.5 12 0.5C18.3513 0.5 23.5 5.64873 23.5 12Z"
        stroke="#4A4A49"
      />
      <path
        d="M18 14.8571V6.28571C18 5.57562 17.4241 5 16.7143 5H8.14286C6.95946 5 6 5.95946 6 7.14286V17C6 17.9469 6.76741 18.7143 7.71429 18.7143H17.5714C17.8083 18.7143 18 18.5226 18 18.2857C18 18.0488 17.8071 17.8571 17.5714 17.8571H17.1429V16.0638C17.6411 15.8857 18 15.4143 18 14.8571ZM6.85714 7.14286C6.85714 6.43277 7.43277 5.85714 8.14286 5.85714H8.57143V15.2857H7.71429C7.40062 15.2857 7.11027 15.3765 6.85714 15.5241V7.14286ZM16.2857 17.8571H7.71429C7.24179 17.8571 6.85714 17.4725 6.85714 17C6.85714 16.5275 7.24179 16.1429 7.71429 16.1429H16.2857V17.8571ZM16.7143 15.2857H9.42857V5.85714H16.7143C16.95 5.85714 17.1429 6.0492 17.1429 6.28571V14.8571C17.1429 15.0929 16.95 15.2857 16.7143 15.2857ZM15.4286 8.42857H11.1429C10.9071 8.42857 10.7143 8.62143 10.7143 8.85714C10.7143 9.09286 10.9071 9.28571 11.1429 9.28571H15.4286C15.6655 9.28571 15.8571 9.09404 15.8571 8.85714C15.8571 8.62025 15.6643 8.42857 15.4286 8.42857ZM15.4286 11H11.1429C10.9071 11 10.7143 11.1929 10.7143 11.4286C10.7143 11.6643 10.9071 11.8571 11.1429 11.8571H15.4286C15.6655 11.8571 15.8571 11.6655 15.8571 11.4286C15.8571 11.1917 15.6643 11 15.4286 11Z"
        fill="#4A4A49"
      />
    </svg>
  </SvgIcon>
);
