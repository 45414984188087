import React, { useMemo, useRef } from "react";
import { BaseType, ScaleLinear, Transition } from "d3";
import { BAR_WIDTH } from "./VariantHistogram";

export interface BottomAxisProps {
  scale: ScaleLinear<number, number, never>;
  transition: Transition<BaseType, any, any, any>;
  tickCount: number;
}

const BottomAxis = ({ scale, tickCount }: BottomAxisProps) => {
  const ref = useRef<SVGGElement>(null);

  const ticks = useMemo(() => {
    return scale.ticks(tickCount).map((value) => ({
      value,
      xOffset: scale(value),
    }));
  }, [scale]);

  return (
    <>
      <path
        data-testid={"variant-histogram-x-axis"}
        d={`M ${scale.range()[0]} 0.5 H ${scale.range()[1]}`}
        stroke="currentColor"
      />
      {ticks.map(({ value, xOffset }) => (
        <g
          ref={ref}
          transform={`translate(${xOffset + BAR_WIDTH / 2 + 1}, 0)`}
          key={`tick-${value}`}
        >
          <line y2="6" stroke="currentColor" />
          <text
            key={value}
            style={{
              fontSize: "10px",
              textAnchor: "middle",
              transform: "translateY(20px)",
            }}
          >
            {value}
          </text>
        </g>
      ))}
    </>
  );
};

export default BottomAxis;
