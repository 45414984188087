import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export const HeadWithPlus = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5168_29761)">
        <path
          d="M11.9346 6.44531C11.4424 5.3407 10.7979 2.8875 10.2208 2.06719C9.31896 0.788203 7.84005 0 6.23692 0H4.69005C2.24083 0 0.0986431 1.87805 0.00489311 4.32422C-0.049271 5.72813 0.542315 6.98672 1.50021 7.84922L1.47911 11.625C1.47911 11.8323 1.64683 12 1.85411 12C2.06135 12 2.20802 11.8323 2.20802 11.625L2.20825 7.51172L2.0013 7.28906C1.16646 6.53906 0.709424 5.46961 0.753487 4.35234C0.829659 2.36719 2.59239 0.75 4.66661 0.75H6.2163C7.55505 0.75 8.81552 1.40344 9.58895 2.49914C9.88192 2.9182 10.2475 4.06359 10.582 4.98586C10.8307 5.66016 11.0463 6.29297 11.2291 6.75H10.1252C9.91811 6.75 9.75021 6.91786 9.75021 7.125V9C9.75021 9.4125 9.41271 9.75 9.00021 9.75H7.12544C6.91835 9.75 6.75044 9.91681 6.75044 10.1238L6.72911 11.625C6.72911 11.8323 6.89683 12 7.08302 12C7.2903 12 7.45802 11.8323 7.45802 11.625L7.45849 10.5H8.95826C9.78654 10.5 10.4372 9.82828 10.4372 9.02109V7.5H11.1661C11.7916 7.5 12.1549 6.93984 11.9346 6.44531ZM7.87521 5.25V4.5C7.87521 4.29286 7.7073 4.125 7.50021 4.125H6.37521V3C6.37521 2.79286 6.2073 2.625 6.00021 2.625H5.25021C5.04396 2.625 4.87521 2.79375 4.87521 3V4.125H3.75021C3.54396 4.125 3.37521 4.29375 3.37521 4.5V5.25C3.37521 5.45709 3.54311 5.625 3.75021 5.625H4.87521V6.75C4.87521 6.95709 5.04311 7.125 5.25021 7.125H6.00021C6.2073 7.125 6.37521 6.95709 6.37521 6.75V5.625H7.50021C7.70646 5.625 7.87521 5.45625 7.87521 5.25Z"
          fill="#678E95"
        />
      </g>
      <defs>
        <clipPath id="clip0_5168_29761">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);
