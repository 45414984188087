import { User } from "../../libs/types/user";

export function hasRole(user: User | Record<string, never>, role: string) {
  const userRoles = Object.values(user.user_role_names).map((role) =>
    String(role)
  );
  return userRoles.includes(role);
}

function isLoggedOut(user: User | Record<string, never>) {
  if (user === undefined) {
    return true;
  }
  if (Object.keys(user).length === 0) {
    return true;
  }
  return false;
}

export function timeRemainingText(user: User) {
  if (hasRole(user, "intro")) {
    return `${user.searches_left || ""} search${
      user.searches_left === 1 ? "" : "es"
    } remaining`;
  } else if (hasRole(user, "trial")) {
    return `${trialDaysLeft(user)} day${
      trialDaysLeft(user) === 1 ? "" : "s"
    } remaining`;
  } else {
    return "";
  }
}

export function trialDaysLeft(user: User) {
  return Math.floor((Date.parse(user.trial_end || "") - Date.now()) / 86400000);
}

export function daysPastDue(user: User) {
  return Math.floor(
    ((Date.parse(user.license_expiration || "") - Date.now() - 1) * -1) /
      86400000
  );
}

export function isIntro(user: User | Record<string, never>) {
  return isLoggedOut(user) ? false : hasRole(user, "intro");
}

export function isConcurrent(user: User | Record<string, never>) {
  return isLoggedOut(user) ? false : hasRole(user, "concurrent");
}

export function isTrial(user: User | Record<string, never>) {
  return isLoggedOut(user)
    ? false
    : hasRole(user, "trial") ||
        hasRole(user, "timed_trial") ||
        hasRole(user, "pilot") ||
        hasRole(user, "reseller_pilot");
}

export function isPastDue(user: User) {
  return isLoggedOut(user) ? false : hasRole(user, "delinquent");
}

export const isProUser = (user: User | Record<string, never>) => {
  return isLoggedOut(user) ? false : user.is_pro;
};
