import { AxiosRequestConfig, AxiosResponse } from "axios";
import { SearchState } from "../store/slices/searchSlice";
import { httpClient } from "./http-client";

export type SuggestionResponse = {
  suggestions: Array<Suggestion>;
};

export type Suggestion = {
  type: keyof SuggestionTermTypes;
  id: string;
  text: string;
  synonym?: string;
  gene?: string;
  keys?: string[];
  variant_kind?: string;
};

export type SuggestionTermTypes = Pick<
  SearchState,
  "gene" | "variant" | "disease" | "hpo" | "unii" | "cnv" | "keyword"
>;

export const SuggestionTerms: Record<
  keyof SuggestionTermTypes,
  keyof SuggestionTermTypes
> = {
  gene: "gene",
  variant: "variant",
  disease: "disease",
  keyword: "keyword",
  hpo: "hpo",
  unii: "unii",
  cnv: "cnv",
};

export enum BooleanSearchOptions {
  AND = "and",
  OR = "or",
}

export const getSuggestions = async (
  searchText: string,
  geneIds: string[],
  type?: string
) =>
  await httpClient.get<any, AxiosResponse<Suggestion[]>, AxiosRequestConfig>(
    "/suggest",
    {
      params: { term: searchText, gene: geneIds, term_type: type },
    }
  );
