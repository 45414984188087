import { Stack, Typography } from "@mui/material";
import React from "react";
import { ACMGCategoryDataType } from "../types";
import ACMGChip from "../../../../../common/chips/ACMGChip";
import { getBackgroundColor } from "../../../../../../libs/utils/acmg";
import { primaryPalette } from "../../../../../../libs/styles/theme";
import { keys, get } from "lodash";
import { summarizedCountsLabels } from "./constants";
import PmidList from "../PmidList";
import { useAppSelector } from "../../../../../../store/hooks";

type ACMGCriteriaItemProps = {
  criteria: ACMGCategoryDataType;
};

const ACMGCriteriaItem = ({ criteria }: ACMGCriteriaItemProps) => {
  const { acmgClasses } = useAppSelector((state) => state.acmgClasses);

  return (
    <Stack p={"2px"}>
      {/* ACMG Chip and Description */}
      <Stack direction="row" gap={0.5}>
        {acmgClasses ? (
          <ACMGChip
            data-testid="evidence-viewer-acmg-chip"
            bgcolor={getBackgroundColor(
              acmgClasses[criteria.acmg_call.toUpperCase()]
            )}
            category={criteria.acmg_call}
            strength={criteria.strength ?? ""}
          />
        ) : null}
        <Typography variant="text12Emphasis">{criteria.short_desc}</Typography>
      </Stack>

      {/* Explanation */}
      <Typography variant="text12" color={primaryPalette.grey.neutral_grey_g7}>
        {criteria.explanation}
      </Typography>

      {/* Summarized Counts */}
      {criteria.summarized_counts &&
        keys(criteria.summarized_counts).length > 0 && (
          <Stack direction="row" data-testid="summarized-count-section" gap={1}>
            {keys(criteria.summarized_counts).map((key) => {
              const label =
                summarizedCountsLabels[
                  key as keyof typeof summarizedCountsLabels
                ];
              if (!label) return null;
              return (
                <Typography variant="text11" key={`summarized_data-${key}`}>
                  {label}: {get(criteria.summarized_counts, key)}
                </Typography>
              );
            })}
          </Stack>
        )}

      {/* PMID List */}
      {criteria.lit_items && criteria.lit_items.length > 0 ? (
        <Stack direction="row" gap={0.5} maxWidth="100%">
          <PmidList
            pmidList={criteria.lit_items.map((lit_item) => lit_item.pmid)}
          />
        </Stack>
      ) : null}
    </Stack>
  );
};

export default ACMGCriteriaItem;
