import { httpClient } from "../../network/http-client";
import { AxiosResponse } from "axios";

type FrontendErrorBody = {
  name: string;
  message: string;
  stack: string;
  url: string;
};

const logError = async (e: Error) => {
  const data = {
    frontend_error_body: {
      name: e.name,
      message: e.message,
      stack: e.stack,
      url: window.location.href,
    } as FrontendErrorBody,
  };
  await httpClient.post<any, AxiosResponse, any>("frontend_error", data);
};

export default logError;
