import { primaryPalette } from "../styles/theme";
import { variantBucketColors } from "../types/variant";
import { getVariantTypeString } from "../utils/variant";

export class VariantResult {
  public gene: string;
  public mutation: string;
  public variantName: string;
  public mutationLC: string;
  public position: number | null;
  public totalHits: number;
  public withoutMutHits: number;
  public withMutHits: number;
  public type: string;
  public cdnaPosition: string | null;
  public content: any;
  public totalArticles: number;
  public relevantArticles: number;
  public articleFocusViewUrl: string;
  public bucketColor: string;
  public articleFeature: boolean;
  public maxAmino: number;

  constructor(
    gene: string,
    mutation: string,
    variantName: string,
    totalHits: number,
    withoutMutHits: number,
    withMutHits: number,
    type: string,
    articleFeature: boolean,
    maxAmino: number
  ) {
    this.gene = gene;
    this.mutation = mutation;
    this.variantName = variantName;
    this.mutationLC = mutation.toLowerCase();
    this.maxAmino = maxAmino;
    this.position = this.getPosition();
    this.totalHits = totalHits;
    this.withMutHits = withMutHits;
    this.withoutMutHits = withoutMutHits;
    this.type = getVariantTypeString(type);
    this.articleFeature = articleFeature;
    this.cdnaPosition = this.getCDNAPosition();
    this.totalArticles = this.totalHits;
    this.relevantArticles = this.withoutMutHits;
    this.articleFocusViewUrl = this.generateArticleFocusViewUrl(articleFeature);
    this.bucketColor = this.getColor(type);
  }

  public generateArticleFocusViewUrl(articleFeature: boolean) {
    if (articleFeature) {
      return `/articles?gene=${this.gene}&mutation=${this.mutation}`;
    } else {
      return `/focus?gene=${this.gene}&mutation=${this.mutation}`;
    }
  }

  public getAminoAcidPosition(): number | string {
    const positionMatch = this.mutation.match(/[A-Z]+([0-9]+)[A-Z]+/i);
    if (positionMatch) {
      return parseInt(positionMatch[1]);
    } else {
      return "N/A";
    }
  }

  public getCDNAPositionWithoutPrefix(cDNAPosition: string): string | null {
    const cDNA = cDNAPosition || this.getCDNAPosition();
    return cDNA ? cDNA.replace(/c\./g, "") : null;
  }

  public getCDNAPosition() {
    if (this.mutationLC.includes("5utr")) {
      return "c.-1, c.-2, c.-3, ...";
    }
    if (this.mutationLC.includes("3utr")) {
      return "c.*1, c.*2, c.*3, ...";
    }
    if (this.mutationLC.match(/int|sr?[ad]|[ud]gv|ext/)) {
      // intronic, splice site, splice region, ugv/dgv, ext
      return "N/A";
    }
    return this.position
      ? `c.${this.position * 3 - 2}, c.${this.position * 3 - 1}, c.${
          this.position * 3
        }`
      : null;
  }

  public getId() {
    return (this.gene + "__" + this.mutation).toLowerCase();
  }

  public getName() {
    return this.gene + ":" + this.mutation;
  }

  public isStandard() {
    return !this.mutationLC.match(/dup|del|fs|ext|utr|[ud]gv|int|sr?[ad]/i);
  }

  public isIntergenic() {
    return this.mutation.match(/^(ugv|dgv)$/i) != null;
  }

  public isUTR() {
    return this.mutation.match(/^[53]UTR/i) != null;
  }

  public static geneCasing(name: string) {
    if (!name) {
      return "";
    }
    name = name.toUpperCase();
    let corfgeneGroups = name.match(/(C[0-9XY]+)(ORF)([0-9]+)/i);
    if (corfgeneGroups) {
      corfgeneGroups = corfgeneGroups.slice(
        1,
        corfgeneGroups.length
      ) as RegExpMatchArray;
      name = "";
      for (const g in corfgeneGroups) {
        if (g == "1") {
          name += corfgeneGroups[g].toLowerCase();
        } else {
          name += corfgeneGroups[g];
        }
      }
    }
    return name;
  }

  public static variantCasing(name: string, prependPNotation = true) {
    const SEQ_RE = /^((?:.._|ENS)[A-Z0-9_.]+):([cpgrn]\..+)/,
      GENE_PREFIX_COLON_RE = /([A-Za-z][A-Za-z0-9_]*):(.+)/,
      HGVS_P_SUB_I =
        /^([ACDEFGHIKLMNPQRSTUVWXY])([1-9][0-9]*)([ACDEFGHIKLMNPQRSTUVWXY])$/i,
      HGVS_P_KEY_I =
        /^([ACDEFGHIKLMNPQRSTUVWXY])([1-9][0-9]*)(ins|del|fs|delins|dup|ext|inv)$/i,
      INT_P_KEY_I =
        /^([ACDEFGHIKLMNPQRSTUVWXY])([1-9][0-9]*)(int|inta|intd|sa|sd|sra|srd)$/i,
      UTR_KEY_I = /^([53])[\u2032']?UTR(int|inta|intd|sa|sd|sra|srd)?$/i,
      OTHER_KEY_I = /^(ugv|dgv)$/i;
    if (!name) return "";
    // Do not case for rsIds, c., and variants with sequence prefixes
    if (name.startsWith("rs") || SEQ_RE.test(name)) {
      return name;
    }

    if (name.indexOf("c.") >= 0) {
      // cDNA
      const m = name.match(GENE_PREFIX_COLON_RE);
      if (m) {
        // gene prefix
        return `${VariantResult.geneCasing(m[1])}:${m[2]}`;
      } else {
        return name;
      }
    }

    let genePart = "";
    let keyPart = name;
    let toUse = name;
    if (name.indexOf(":") >= 0) {
      const parts = name.split(":");
      genePart = parts[0];
      keyPart = parts[1];
      toUse = keyPart;
    }

    const pSubM = keyPart.match(HGVS_P_SUB_I);
    const pKeyM = keyPart.match(HGVS_P_KEY_I);
    const intpKeyM = keyPart.match(INT_P_KEY_I);
    const utrKeyM = keyPart.match(UTR_KEY_I);
    const otherKeyM = keyPart.match(OTHER_KEY_I);
    const maybeP = function (val: string) {
      return prependPNotation ? "p." + val : val;
    };
    if (pSubM) {
      // V600E
      toUse = maybeP(
        pSubM[1].toUpperCase() + pSubM[2] + pSubM[3].toUpperCase()
      );
    } else if (pKeyM) {
      // HGVS p. notation, e.g. R123del
      toUse = maybeP(
        pKeyM[1].toUpperCase() + pKeyM[2] + pKeyM[3].toLowerCase()
      );
    } else if (intpKeyM) {
      // non-HGVS p.-based notation, e.g. E46int
      toUse =
        intpKeyM[1].toUpperCase() + intpKeyM[2] + intpKeyM[3].toLowerCase();
    } else if (utrKeyM) {
      // non-HGVS 5UTR notation
      toUse =
        utrKeyM[1] + "\u2032UTR" + (utrKeyM[2] ? utrKeyM[2].toLowerCase() : "");
    } else if (otherKeyM) {
      // ugv/dgv
      toUse = keyPart.toLowerCase();
    }

    if (genePart) {
      return VariantResult.geneCasing(genePart) + ":" + toUse;
    } else {
      return toUse;
    }
  }

  public static getGene(name: string) {
    let genePart = "";
    if (name.indexOf(":") >= 0) {
      const parts = name.split(":");
      genePart = parts[0];
    }
    return genePart;
  }

  public getColor(type: string) {
    return (
      variantBucketColors[type].backgroundColor ||
      primaryPalette.gray.variant_01
    );
  }

  private getPosition() {
    if (this.mutation.includes("ugv")) {
      return Number.MIN_SAFE_INTEGER;
    }
    if (this.mutation.includes("5UTR") || this.mutation.includes("5'UTR")) {
      return 0;
    }
    if (this.mutation.includes("3UTR") || this.mutation.includes("3'UTR")) {
      return this.maxAmino;
    }
    if (this.mutation.includes("dgv")) {
      return Number.MAX_SAFE_INTEGER;
    }
    const positionMatch = this.mutation.match(/[A-Z]+([0-9]+)[A-Z]+/i);
    if (positionMatch) {
      return parseInt(positionMatch[1]);
    } else {
      return null;
    }
  }
}
