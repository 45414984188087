import * as d3 from "d3";
import { PieArcDatum } from "d3";

export type DonutPoint = {
  count: number;
  color: string;
};

export type DonutData = DonutPoint[];

export type DonutProps = {
  sideLength: number;
  margin: number;
  arcWidth: number;
  containerId: string;
};

const drawDonutChart = (donutProps: DonutProps, donutData: DonutData) => {
  const { sideLength, margin, arcWidth, containerId } = donutProps;
  const radius = sideLength / 2 - margin;

  // Remove old svg
  d3.select(`#${containerId}`).select("svg").remove();

  // Create new svg
  const svg = d3
    .select(`#${containerId}`)
    .append("svg")
    .attr("preserveAspectRatio", "xMidYMid meet")
    .attr("height", sideLength)
    .attr("width", sideLength)
    .append("g")
    .attr("transform", `translate(${sideLength / 2}, ${sideLength / 2})`);

  const arcGenerator = d3
    .arc<PieArcDatum<DonutPoint>>()
    .innerRadius(radius - arcWidth)
    .outerRadius(radius);

  const pieGenerator = d3.pie<DonutPoint>().value((d) => d.count);

  const arcs = svg.selectAll().data(pieGenerator(donutData)).enter();
  arcs
    .append("path")
    .attr("d", arcGenerator)
    .style("fill", (d) => d.data.color);
};

export default drawDonutChart;
