import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { SnackbarKey, useSnackbar } from "notistack";
import * as React from "react";

function SnackbarCloseButton({ snackbarKey }: { snackbarKey: SnackbarKey }) {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton onClick={() => closeSnackbar(snackbarKey)}>
      <Close />
    </IconButton>
  );
}

export default SnackbarCloseButton;
