import React from "react";
import { Grid } from "@mui/material";
import GeneOverview from "../../common/GeneOverview/GeneOverview";

export function GeneStatistics() {
  return (
    <Grid
      item
      data-testid={"gene-statistics"}
      xs={12}
      alignItems={"center"}
      sx={{ textAlign: "center", marginBottom: "2rem" }}
    >
      <GeneOverview />
    </Grid>
  );
}
