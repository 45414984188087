import React from "react";
import { Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../store/hooks";
import { isProUser } from "../../../libs/utils/user";
import { Modals } from "../../../store/slices/modalSlice";
import { useOpenSelectedModal } from "../../modals/modalHooks";

interface HomePageLinkProps {
  href: string;
  searchType: string;
  children: React.ReactNode;
}

const HomePageLink: React.FC<HomePageLinkProps> = ({
  searchType,
  href,
  children,
}) => {
  const { user } = useAppSelector((state) => state.user);
  const openSelectedModal = useOpenSelectedModal();
  const navigate = useNavigate();

  const redirect = () => {
    // TODO: remove this check when these pages are build in react app
    if (href.includes("/detail") || href.includes("/interpretation")) {
      window.location.href = href;
    } else {
      navigate(href);
    }
  };

  const handleLinkClick = () => {
    if (searchType !== "all" && !isProUser(user)) {
      openSelectedModal(
        Modals.alertWithLogin,
        {
          message: `${searchType} search available in Professional Edition. Log in or sign up for a trial of Professional Edition.`,
        },
        true
      );
    } else {
      redirect();
    }
  };

  return (
    <Link
      data-testid="home-page-link"
      sx={{
        color: "inherit",
        fontSize: "14px",
        lineHeight: 1.8,
        textDecoration: "underline",
        cursor: "pointer",
      }}
      onClick={handleLinkClick}
    >
      {children}
    </Link>
  );
};

export default HomePageLink;
