import { AcmgDescriptionType } from "../../network/acmg-descriptions";
import { primaryPalette } from "../styles/theme";

export const getBackgroundColor = (acmgDescription: AcmgDescriptionType) => {
  if (acmgDescription?.pathogenic) {
    switch (acmgDescription?.level) {
      case 0:
        return primaryPalette.pathogenic.level_0;
      case 1:
        return primaryPalette.pathogenic.level_1;
      case 2:
        return primaryPalette.pathogenic.level_2;
      case 3:
        return primaryPalette.pathogenic.level_3;
      default:
        // don't know?  Then grey.
        return "#AAAAAA";
    }
  } else {
    switch (acmgDescription?.level) {
      case 0:
        return primaryPalette.benign.level_0;
      case 1:
        return primaryPalette.benign.level_1;
      case 2:
        return primaryPalette.benign.level_2;
      case 3:
        return primaryPalette.benign.level_3;
      default:
        // don't know?  Then grey.
        return "#AAAAAA";
    }
  }
};

export const acmgModifier = {
  supporting: "p",
  moderate: "m",
  strong: "s",
  very_strong: "vs",
};
