import { SvgIcon } from "@mui/material";
import React from "react";

export function FunctionalEvidenceIcon({
  height,
  width,
}: {
  height?: string;
  width?: string;
}) {
  return (
    <SvgIcon style={{ height: height, width: width }}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 12" fill="none">
        <g clipPath="url(#clip0_5927_11933)">
          <path
            d="M10.1729 4.66875L8.83926 6H4.13066L7.81973 2.31563C7.96635 2.16923 7.9664 1.93172 7.81991 1.78523C7.67352 1.63884 7.43624 1.63875 7.28975 1.78505L1.271 7.79443C0.3628 8.70263 0.213268 10.1851 1.05128 11.1577C1.53332 11.7188 2.20551 12 2.8791 12H2.88959C3.50787 12 4.12591 11.7656 4.59771 11.2941L10.7032 5.20031C10.8499 5.05392 10.85 4.81641 10.7035 4.66969C10.5572 4.50234 10.2994 4.50234 10.1729 4.66875ZM4.06738 10.7625C3.75332 11.0766 3.31504 11.25 2.8791 11.25C2.39652 11.25 1.93809 11.0387 1.62098 10.6697C1.06012 10.0186 1.1398 8.98922 1.80248 8.32664L3.38066 6.75H8.08926L4.06738 10.7625ZM12.39 4.23516L8.26504 0.110156C8.19238 0.0366094 8.09629 0 8.0002 0C7.9041 0 7.80829 0.0366094 7.73512 0.109875C7.58863 0.256359 7.58863 0.493781 7.73512 0.640031L11.8601 4.76503C11.933 4.8375 12.0291 4.875 12.1252 4.875C12.2213 4.875 12.3171 4.83839 12.3903 4.76512C12.5377 4.61953 12.5377 4.38047 12.39 4.23516Z"
            fill="#437179"
          />
        </g>
        <defs>
          <clipPath id="clip0_5927_11933">
            <rect
              width={width}
              height={height}
              fill="white"
              transform="translate(0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}
