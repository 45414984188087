import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { isLoggedIn } from "./userSelectors";

const articleListData = (state: RootState) => state.articles.articleListData;

export const getArticleListItems = createSelector(
  isLoggedIn,
  articleListData,
  (loggedIn, articleList) => {
    if (!loggedIn) {
      return (articleList?.articles ?? []).slice(0, 5);
    }

    return articleList?.articles ?? [];
  }
);
