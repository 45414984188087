import React from "react";
import {
  Box,
  ClickAwayListener,
  Divider,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import DOMPurify from "dompurify";
import { Instance } from "@popperjs/core";

import {
  boxShadowStyles,
  primaryPalette,
} from "../../../../../../libs/styles/theme";
import { CondensedArticleSentence } from "../../../../../../network/articles";
import { ArticleViewerText } from "../../../StyledViewerComponents";
import TextMatchTooltip from "./TextMatchTooltip";

type ArticleSentenceProps = {
  sentence: CondensedArticleSentence;
};
const ArticleSentence = ({ sentence }: ArticleSentenceProps) => {
  const popperRef = React.useRef<Instance>(null);
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const popupContent = () => {
    return (
      <React.Fragment>
        <Typography
          variant="h5"
          color={primaryPalette.teal.neutral_teal_t4}
          marginBottom="5px"
        >
          Matches
        </Typography>
        {sentence.contentMatches.map((contentMatch, index) => {
          return (
            <React.Fragment key={index}>
              <TextMatchTooltip sentence={contentMatch} />
              {index !== sentence.contentMatches.length - 1 && <Divider />}
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
  };

  return (
    <Grid item sm={6} md={12} xl={6}>
      <Box>
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <div>
            <Tooltip
              title={popupContent()}
              placement="bottom"
              PopperProps={{
                popperRef,
              }}
              slotProps={{
                tooltip: {
                  sx: {
                    fontSize: "0.75rem",
                    minWidth: 300,
                    padding: "0.625rem 1.25rem",
                    color: primaryPalette.grey.neutral_grey_g8,
                    backgroundColor: primaryPalette.grey.neutral_grey_g1,
                    boxShadow: boxShadowStyles.default,
                    textTransform: "none",
                    "& .MuiTooltip-arrow": {
                      "&::before": {
                        backgroundColor: primaryPalette.grey.neutral_grey_g1,
                      },
                    },
                  },
                },
              }}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              open={open}
              arrow
            >
              <ArticleViewerText
                onClick={handleTooltipOpen}
                sx={{
                  fontSize: "13px",
                  lineHeight: "18px",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(sentence.content, {
                    ALLOWED_TAGS: ["highlight"],
                  }),
                }}
              />
            </Tooltip>
          </div>
        </ClickAwayListener>
      </Box>
    </Grid>
  );
};

export default ArticleSentence;
