export const rsidRegex = new RegExp(/^rs\d/i);
export const cNomenclatureRegex = new RegExp(/^[a-zA-Z\d]*[\s:]?c\./i);
export const genomicReferenceNomenclature = new RegExp(/^(g\.|NC_)/i);

export const isRSID = (params: string[]) =>
  params.some((p) => rsidRegex.test(p));
export const isCNomenclature = (params: string[]) =>
  params.some((p) => cNomenclatureRegex.test(p));
export const isGenomicReference = (params: string[]) =>
  params.some((p) => genomicReferenceNomenclature.test(p));
