import { Typography } from "@mui/material";
import React from "react";
import { primaryPalette } from "../../../../../../../libs/styles/theme";
import { EvidenceViewerArticleCardFooterDiscreteDataItemProps } from "../types";

type AdditionalCuratorContextProps =
  EvidenceViewerArticleCardFooterDiscreteDataItemProps & {
    projectType: string;
  };

const AdditionalCuratorContext = ({
  discreteData,
  projectType,
}: AdditionalCuratorContextProps) => {
  const isACMGProject = projectType === "acmg";
  const additionalText = isACMGProject
    ? discreteData.evidence
    : discreteData.context;

  if (!additionalText) {
    return null;
  }

  return (
    <span>
      <Typography
        variant="text11Emphasis"
        color={primaryPalette.teal.neutral_teal_t4}
      >
        Additional Context:{" "}
      </Typography>
      <Typography variant="text11" color={primaryPalette.grey.neutral_grey_g8}>
        {additionalText}
      </Typography>
    </span>
  );
};

export default AdditionalCuratorContext;
