import React from "react";
import { Article, ArticleCardBodyData } from "../../../../../network/articles";
import { Grid, Link, Typography } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import DOMPurify from "dompurify";
import { primaryPalette } from "../../../../../libs/styles/theme";
import { pubmedLink } from "../../../../../libs/utils/links";
import ClinVarIcon from "../../../icons/SmallClinVarIcon";
import TooltipComponent from "../../../tooltip/TooltipComponent";
import { Instance } from "@popperjs/core";
import CopyToClipboardButton from "../../../buttons/CopyToClipboardButton";

export const clinvarTooltipText =
  "Article included in ClinVar evidence for your variant";

export const ArticleCardBody = ({
  article,
  showClinVarBadge,
  onClick,
}: {
  article: Article | ArticleCardBodyData;
  showClinVarBadge: boolean;
  onClick?: (e: React.MouseEvent) => void;
}) => {
  const { title, publication_date, authors, journal, pmid } = article;
  const popperRef = React.useRef<Instance>(null);

  return (
    <Grid container direction="column" gap={1}>
      <Grid item>
        <Typography
          variant="text12"
          color={primaryPalette.grey.neutral_grey_g9}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(title || ""),
          }}
        />{" "}
        <CopyToClipboardButton
          copyText={`${DOMPurify.sanitize(title || "")} ${pmid}`}
          fontSize={12}
          successMessage="Copied!"
          tooltipText="Copy article title and PMID to clipboard"
        />
      </Grid>
      <Grid container direction="row">
        <Grid container item direction="column" xs={8} pl={1}>
          <Grid item>
            <Typography
              variant="text11"
              color={primaryPalette.grey.neutral_grey_g7}
              style={{
                fontWeight: 700,
              }}
            >
              {publication_date}
            </Typography>
          </Grid>
          <Grid container item gap={1}>
            {(authors ?? []).length > 0 && (
              <Grid item>
                <Typography
                  variant="text11"
                  color={primaryPalette.gray.variant_07}
                >
                  {authors[0]}
                </Typography>
              </Grid>
            )}
            <Grid item>
              <Typography
                variant="text11"
                color={primaryPalette.gray.variant_07}
              >
                {journal}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={4}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item display="flex" gap={0.5}>
            {showClinVarBadge && (
              <Grid item alignItems="center">
                <TooltipComponent
                  content={clinvarTooltipText}
                  placement="right"
                  popperProps={{
                    popperRef,
                  }}
                  testId="clinvar-tooltip"
                >
                  <ClinVarIcon width=".6em" height=".6em" />
                </TooltipComponent>
              </Grid>
            )}

            <Typography
              variant="text11"
              color={primaryPalette.gray.variant_07}
              display="flex"
              alignItems="center"
              gap={0.5}
            >
              PMID{" "}
              <Link
                underline="always"
                target="_blank"
                rel="noopener"
                href={pubmedLink(pmid)}
                onClick={onClick}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {pmid}
                <OpenInNewIcon fontSize="inherit" style={{ marginLeft: 4 }} />
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
