import { SvgIcon } from "@mui/material";
import React from "react";

export function CaretDownIcon() {
  return (
    <SvgIcon sx={{ width: "0.5em", height: "0.5em", marginRight: "0.5em" }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.99086 4.56901C1.49857 4.02202 1.88676 3.15039 2.62266 3.15039H9.3773C10.1132 3.15039 10.5014 4.02202 10.0091 4.56901L6.63178 8.32159C6.29413 8.69676 5.70584 8.69676 5.36818 8.32159L1.99086 4.56901ZM2.62266 3.85039C2.4928 3.85039 2.42429 4.00421 2.51117 4.10074L5.88849 7.85331C5.94807 7.91952 6.05189 7.91952 6.11148 7.85331L9.4888 4.10074C9.57567 4.00421 9.50717 3.85039 9.3773 3.85039H2.62266Z"
          fill="#678E95"
        />
      </svg>
    </SvgIcon>
  );
}
