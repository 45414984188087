import React, { useEffect, useState } from "react";
import { Box, Grid, Link, Skeleton, Typography } from "@mui/material";
import pluralize from "pluralize";
import {
  DiseaseRelationshipOrder,
  DiseaseResponse,
  useLazyGetDiseaseRelationshipsQuery,
} from "../../../../network/disease-relationships";
import { isProUser } from "../../../../libs/utils/user";
import { useAppSelector } from "../../../../store/hooks";
import Paywall from "../../../common/paywalls";
import { formatCompactNumber } from "../../../../libs/utils/variant";
import { useHandleError } from "../../../../libs/hooks/errorHandlerHook";
import { VariantResult } from "../../../../libs/models/variant-result";
import DiseaseCard from "../DiseaseCard";
import { SearchBarTerms } from "../../../../store/slices/searchSlice";
import { selectUrlTermIds } from "../../../../store/selectors/urlSelectors";
import { HelpOutline } from "@mui/icons-material";

const GDRAssessmentStandardsDocURL =
  "https://go.genomenon.com/l/962963/2024-07-08/5xrdk/962963/1720464801waETDJSW/Genomenon_Gene_Disease_Relationship_Assessment_Standards.pdf";

function GeneDiseaseRelationship() {
  const urlTermIds = useAppSelector((state) => selectUrlTermIds(state));
  const [geneRelationships, setGeneRelationships] = useState<DiseaseResponse[]>(
    []
  );
  const [geneRelationshipCount, setGeneRelationshipCounts] =
    useState<number>(0);
  const { user } = useAppSelector((state) => state.user);
  const searchedGene = urlTermIds[SearchBarTerms.gene][0];

  const [
    triggerDiseaseRelationshipQuery,
    { data: diseaseRelationshipsData, isLoading },
  ] = useLazyGetDiseaseRelationshipsQuery();
  const handleError = useHandleError();

  useEffect(() => {
    if (urlTermIds[SearchBarTerms.gene][0]) {
      void triggerDiseaseRelationshipQuery(
        urlTermIds[SearchBarTerms.gene],
        true
      ).catch((err) => handleError(err));
    }
  }, [urlTermIds[SearchBarTerms.gene]]);

  useEffect(() => {
    const casedGene = VariantResult.geneCasing(searchedGene);
    if (diseaseRelationshipsData && diseaseRelationshipsData[casedGene]) {
      const orderedGeneRelationships = [
        ...diseaseRelationshipsData[casedGene].diseases,
      ];
      orderedGeneRelationships.sort((a, b) => {
        const orderA = DiseaseRelationshipOrder[a.classification];
        const orderB = DiseaseRelationshipOrder[b.classification];

        return orderA - orderB;
      });

      setGeneRelationships([...orderedGeneRelationships]);
      setGeneRelationshipCounts(diseaseRelationshipsData[casedGene].total);
    }
  }, [diseaseRelationshipsData]);

  return (
    <Grid container rowSpacing={1} sx={{ paddingTop: "15px" }}>
      <Grid
        container
        item
        sx={{
          height: "50px",
          alignItems: "center",
          paddingTop: 0,
          marginBottom: "10px",
        }}
      >
        {isLoading ? (
          <Skeleton variant="rectangular" />
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="text16" data-testid="disease-count">
              {formatCompactNumber(geneRelationshipCount)}{" "}
              {`Gene-Disease ${pluralize(
                "Relationships",
                geneRelationshipCount
              )}`}
            </Typography>
            <Link
              href={GDRAssessmentStandardsDocURL}
              underline="none"
              target="_blank"
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <HelpOutline sx={{ fontSize: "11px" }} />
                <Typography variant="text11" fontStyle="italic">
                  Genomenon Gene Disease Relationship Assessment Standards
                </Typography>
              </Box>
            </Link>
          </Box>
        )}
      </Grid>
      <Grid item xs={12} sx={{ paddingTop: "0px!important" }}>
        {!isProUser(user) ? (
          <Paywall
            titleText="pro edition feature"
            bodyText="Genomenon has curated over 6,000 Gene-Disease Relationships"
          />
        ) : null}
        {isLoading ? (
          <Skeleton variant="rectangular" />
        ) : (
          <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            {geneRelationships.map((gdr, _i) => (
              <DiseaseCard props={gdr} key={_i} id={_i} />
            ))}
          </Box>
        )}
      </Grid>
    </Grid>
  );
}

export default GeneDiseaseRelationship;
