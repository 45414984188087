import React from "react";
import VariantCard from "./VariantCard/VariantCard";
import GeneCard from "./GeneCard/GeneCard";
import { useAppSelector } from "../../../store/hooks";
import { SearchBarTerms } from "../../../store/slices/searchSlice";
import { selectUrlTermIds } from "../../../store/selectors/urlSelectors";

export default function GeneVariantCard() {
  const urlTermIds = useAppSelector((state) => selectUrlTermIds(state));
  if (urlTermIds[SearchBarTerms.variant].length > 0) {
    return <VariantCard />;
  }
  if (urlTermIds[SearchBarTerms.gene].length > 0) {
    return <GeneCard />;
  }
  return <></>;
}
