import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import React from "react";
import { VariantResult } from "../../../../libs/models/variant-result";
import { Order } from "../VariantList/filterList";
import { primaryPalette } from "../../../../libs/styles/theme";
import { sortPropertyForColumn } from "../VariantList/VariantList";

interface IHeadCell {
  disablePadding: boolean;
  id: keyof VariantResult;
  label: string;
  numeric: boolean;
}

export const headCells: readonly IHeadCell[] = [
  {
    id: "mutation",
    numeric: false,
    label: "VARIANT",
    disablePadding: true,
  },
  {
    id: "type",
    numeric: false,
    label: "TYPE",
    disablePadding: true,
  },
  {
    id: "cdnaPosition",
    numeric: false,
    label: "cDNA POSITION",
    disablePadding: true,
  },
  {
    id: "totalArticles",
    numeric: true,
    label: "TOTAL ARTICLES",
    disablePadding: true,
  },
];

interface IEnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof VariantResult
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

export function VariantListHeader(props: IEnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof VariantResult) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };
  return (
    <TableHead
      sx={{
        background: primaryPalette.teal.neutral_teal_t2,
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.06)",
      }}
    >
      <TableRow>
        {headCells.map((headCell) => {
          const sortProp = sortPropertyForColumn(headCell.id);
          return (
            <TableCell
              key={headCell.id}
              data-testid={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              sortDirection={orderBy === sortProp ? order : false}
            >
              <TableSortLabel
                active={orderBy === sortProp}
                direction={orderBy === sortProp ? order : Order.ASC}
                onClick={createSortHandler(headCell.id)}
                data-testid={`${headCell.id}-arrow`}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === Order.DESC
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}
